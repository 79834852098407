/** @enum {string} */
export const OS = {
  MAC: 'Mac OS',
  IOS: 'iOS',
  WINDOWS: 'Windows',
  ANDROID: 'Android',
  LINUX: 'Linux',
};

export class PlatformHelper {
  /** @returns {boolean} */
  static isWindows = () => PlatformHelper.getOS() === OS.WINDOWS;
  /** @returns {boolean} */
  static isMacOs = () => PlatformHelper.getOS() === OS.MAC;
  /** @returns {boolean} */
  static isLinux = () => PlatformHelper.getOS() === OS.LINUX;

  /**
   * @private
   * @returns {OS | null}
   */
  static getOS = () => {
    const { userAgent, platform } = window.navigator;
    const macOsPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    switch (true) {
      case macOsPlatforms.includes(platform): return OS.MAC;
      case iosPlatforms.includes(platform): return OS.IOS;
      case windowsPlatforms.includes(platform): return OS.WINDOWS;
      case /Android/.test(userAgent): return OS.ANDROID;
      case /Linux/.test(platform): return OS.LINUX;
      default: return null;
    }
  };
}
