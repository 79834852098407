import apiClient from '@/services/api-client';

/**
 * @typedef {import('@/models/report-comments.interface').ReportComment} ReportComment
 */

/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ReportsReportCommentsState
 * @property {ReportComment[]} comments
 */

/** @returns {ReportsReportCommentsState} */
const getInitialReportsReportCommentsState = () => ({
  comments: [],
});

/** @type {ActionTree<ReportsReportCommentsState, RootState>} */
const actions = {
  getComments: async ({ commit }, reportId) => {
    const { data } = await apiClient.reportCommentsApi.getReportComments(reportId);

    commit('setComments', data);
  },
  clearComments: ({ commit }) => commit('setComments', []),
  createComment: async ({ dispatch }, { reportId, message }) => {
    await apiClient.reportCommentsApi.addReportComment(reportId, message);

    dispatch('getComments', reportId);
  },
};

/** @type {MutationTree<ReportsReportCommentsState>} */
const mutations = {
  setComments: (state, data) => (state.comments = data),
  addComment: (state, data) => (state.comments = state.comments.concat(data)),
  resetModuleState: (state) => Object.assign(state, getInitialReportsReportCommentsState()),
};

/** @type {Module<ReportsReportCommentsState, RootState>} */
export const reportComments = {
  namespaced: true,
  state: getInitialReportsReportCommentsState(),
  actions,
  mutations,
};
