import Vue from 'vue';
import VueRouter from 'vue-router';

import { action, resource } from '@/constants/permissions';

Vue.use(VueRouter);

const EmployeeDetails = () => import(
  /* webpackChunkName: "EmployeeDetails" */
  '@/components/employees/EmployeeDetails.vue'
);
const EmployeesPage = () => import(
  /* webpackChunkName: "EmployeesPage" */
  '@/components/employees/EmployeesPage.vue'
);
const ProjectDetails = () => import(
  /* webpackChunkName: "ProjectDetails" */
  '@/components/projects/ProjectDetails.vue'
);
const ProjectPage = () => import(
  /* webpackChunkName: "ProjectPage" */
  '@/components/projects/ProjectPage.vue'
);

const Login = () => import(
  /* webpackChunkName: "Login" */
  '@/components/auth/Login.vue'
);

const Dashboard = () => import(
  /* webpackChunkName: "Dashboard" */
  '@/components/bi/Dashboard.vue'
);

const Logout = () => import(
  /* webpackChunkName: "Logout" */
  '@/components/auth/Logout.vue'
);

const ReportDetails = () => import(
  /* webpackChunkName: "ReportDetails" */
  '@/components/reports/ReportDetails.vue'
);
const ReportPage = () => import(
  /* webpackChunkName: "ReportPage" */
  '@/components/reports/ReportPage.vue'
);

const TimesheetPage = () => import(
  /* webpackChunkName: "TimesheetPage" */
  '@/components/timesheet/TimesheetPage.vue'
);

const UserSettings = () => import(
  /* webpackChunkName: "UserSettings" */
  '@/components/user/UserSettings.vue'
);

const MatrixReportPage = () => import(
  /* webpackChunkName: "MatrixReportPage" */
  '@/components/matrix-report/MatrixReportPage.vue'
);
const MatrixByCostCenterReportPage = () => import(
  /* webpackChunkName: "MatrixByCostCenterReportPage" */
  '@/components/matrix-by-cost-center-report/MatrixByCostCenterReportPage.vue'
);

const HolidaysPage = () => import(
  /* webpackChunkName: "HolidaysPage" */
  '@/components/holidays/HolidaysPage.vue'
);

const NotFound = {
  template: '<div>Page not found</div>',
};

const rootTitle = 'Time Tracking System';

export const routes = [
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/',
    component: TimesheetPage,
    name: 'Timesheets',
    isMenuItem: true,
    icon: 'access_time',
    outlined: true,
    meta: {
      title: `Timesheet | ${rootTitle}`,
      requiresAuth: true,
    },
    permissions: {
      action: action.READ,
      resource: resource.timeUnits,
    },
  },
  {
    path: '/projects',
    component: ProjectPage,
    name: 'Projects',
    isMenuItem: true,
    icon: 'folder_special',
    outlined: true,
    meta: {
      title: `Projects | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Projects',
    },
    permissions: {
      action: action.READ,
      resource: resource.projectList,
    },
  },
  {
    path: '/reports',
    component: ReportPage,
    name: 'Time Reports',
    isMenuItem: true,
    icon: 'assignment',
    outlined: true,
    meta: {
      title: `Reports | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Time Reports',
    },
    permissions: {
      action: action.READ,
      resource: resource.reportsList,
    },
  },
  {
    path: '/projects/:id',
    component: ProjectDetails,
    name: 'Project Details',
    isMenuItem: false,
    meta: {
      title: `Project details | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Projects',
    },
  },
  {
    path: '/reports/:id',
    component: ReportDetails,
    name: 'Report Details',
    isMenuItem: false,
    meta: {
      title: `Report details | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Time Reports',
    },
  },
  {
    path: '/matrix-report',
    component: MatrixReportPage,
    name: 'Matrix Report',
    isMenuItem: true,
    icon: 'assignment_ind',
    outlined: true,
    meta: {
      title: `Matrix report | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Matrix Report',
    },
    permissions: {
      action: action.CREATE,
      resource: resource.matrixReport,
    },
  },
  {
    path: '/matrix-by-cost-center-report',
    component: MatrixByCostCenterReportPage,
    name: 'Cost Center Report',
    isMenuItem: true,
    icon: 'list_alt',
    outlined: true,
    meta: {
      title: `Cost Center Report | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Cost Center Report',
    },
    permissions: {
      action: action.CREATE,
      resource: resource.costCenterReport,
    },
  },
  {
    path: '/employees',
    component: EmployeesPage,
    name: 'Employees',
    isMenuItem: true,
    icon: 'people',
    outlined: false,
    meta: {
      title: `Employees | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Employees',
    },
    permissions: {
      action: action.READ,
      resource: resource.employeeList,
    },
  },
  {
    path: '/employees/:id',
    component: EmployeeDetails,
    name: 'Employee Details',
    isEmployeeDetails: true,
    isMenuItem: false,
    meta: {
      title: `Employee details | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Employees',
    },
  },
  {
    path: '/user/settings',
    component: UserSettings,
    name: 'UserSettings',
    isMenuItem: false,
    meta: {
      title: `Settings | ${rootTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: `Login | ${rootTitle}`,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: `Logout | ${rootTitle}`,
    },
  },
  {
    path: '/holydays',
    component: HolidaysPage,
    name: 'Holidays',
    isMenuItem: true,
    icon: 'weekend',
    outlined: false,
    meta: {
      title: `Holidays | ${rootTitle}`,
      requiresAuth: true,
      permissionType: 'Holidays',
    },
    permissions: {
      action: action.READ,
      resource: resource.holidayList,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    isMenuItem: false,
    props: true,
    meta: {
      title: `Dashboard | ${rootTitle}`,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
