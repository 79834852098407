import { CollectionsHelper } from '@/helpers/collections.helper';

import { getInitialSharedRolesState } from './shared-roles.state';

/**
 * @template T, C
 * @typedef {import('@/store/types/with-context').WithContext<T, C>} WithContext<T, C>
 */

/**
 * @typedef {import('./shared-roles.state').SharedRolesState} SharedRolesState
 * @typedef {import('@/services/api-client/types/role.api.types').ProjectRole} ProjectRole
 * @typedef {import('@/services/api-client/types/role.api.types').SystemRole} SystemRole
 */

/**
 * @typedef {Object} SharedRolesMutations
 * @property {(projectRoles: ProjectRole[]) => void} setProjectRoles
 * @property {(systemRoles: SystemRole[]) => void} setSystemRoles
 * @property {(systemRolesICanSet: SystemRole[]) => void} setSystemRolesICanSet
 * @property {() => void} resetModuleState
 */

/** @type {WithContext<SharedRolesMutations, SharedRolesState>} */
export const sharedRolesMutations = {
  setProjectRoles: (state, data) => (state.projectRoles = CollectionsHelper.sortObjectsByProperty(data, 'title')),
  setSystemRoles: (state, data) => (state.systemRoles = CollectionsHelper.sortObjectsByProperty(data, 'title')),
  setSystemRolesICanSet: (state, data) => (state.systemRolesICanSet = CollectionsHelper.sortObjectsByProperty(data, 'title')),
  resetModuleState: (state) => Object.assign(state, getInitialSharedRolesState()),
};
