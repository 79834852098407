/** @enum {string} */
export const reportStatuses = {
  DRAFT: 'Draft',
  READY_FOR_REVIEW: 'Ready for Review',
  SENT_TO_INVOICING: 'Sent to Invoicing',
  REJECTED: 'Rejected',
};

// TODO: remove this export, it can be calculated based on report statuses
export default [
  { id: 'Draft', title: 'Draft' },
  { id: 'Ready for Review', title: 'Ready for Review' },
  { id: 'Sent to Invoicing', title: 'Sent to Invoicing' },
  { id: 'Rejected', title: 'Rejected' },
];
