<template>
  <v-app>
    <NotificationsPool />
    <MainContent v-if="this.$route.path !== '/login'" />
    <Login v-if="this.$route.path === '/login'" />
  </v-app>
</template>

<script>

import { Login } from '@/components/auth';
import { MainContent, NotificationsPool } from '@/components/layout';

export default {
  name: 'App',
  components: {
    Login,
    MainContent,
    NotificationsPool,
  },
  watch: {
    $route: {
      handler(to) {
        document.title = to.meta.title || 'Time Tracking System';
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less">
@import "~variables";
@import "./styles/utils";
@import "./styles/v-data-table";

html {
  overflow: auto !important;
}

#app {
  background-color: @grey-light;
  font-size: 14px;

  thead tr th {
    font-size: 0.85rem;
  }

  .v-btn {
    letter-spacing: normal;
  }

  .v-btn--contained:not(.v-btn--disabled),
  .v-card {
    box-shadow: @default-shadow;
  }

  .tabs-bar-shadow div[role="tablist"] {
    box-shadow: @bar-shadow;
  }

  .select-item-checkbox,
  .select-item-checkbox .v-input--selection-controls__input {
    margin: 0;
    padding: 0; // for report units checkboxes alignment
  }

  .v-input--selection-controls__ripple:before {
    opacity: 0; // to hide ripple hover effect by design
  }

  th.sticky {
    position: sticky;

    &.sticky_top {
      top: 0;

      &.sticky_left {
        z-index: 4;
      }
    }
    &.sticky_left {
      left: 0;
      z-index: 3;
    }
    &.sticky_bottom {
      bottom: 0;
      background-color: @grey-350;

      &.sticky_left {
        z-index: 4;
      }
    }
  }

  .col {
    padding: 0;
  }

  .row {
    margin: 0;
  }

  .flex-column-with-tabs {
    display: flex;
    flex-direction: column;
    height: calc(100vh - @header-height - @progress-bar-height);

    .row:last-child {
      position: relative;
      flex: 1;

      .v-tabs {
        display: flex;
        flex-direction: column;

        .v-tabs-items {
          flex: 1;

          .v-window__container {
            position: relative;
            height: 100% !important;
            background-color: @white-smoke;
            margin: 0 !important;

            .v-window-item {
              padding: 16px;
            }
          }
        }

        .v-tabs-bar {
          position: sticky;
          top: 0;
          z-index: 3;
          background-color: @white;
        }
      }
    }
  }

  .v-date-picker-table {
    height: unset;
    padding: 0 12px 12px 12px;
  }

  .table-footer {
    padding: 16px;

    .footer-text {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      color: grey;
      height: 36px;
      line-height: 36px;

      span {
        white-space: pre;
      }
    }
  }

  .v-card {
    flex: 1;
    width: calc(100% - 8px);

    .small-title {
      padding: 0;
      margin-bottom: 8px;
    }
  }

  .v-dialog,
  .v-menu__content {
    .v-card {
      margin: unset;
      width: unset;
    }
  }

  .chips-input .v-select__selections {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > input {
      margin-left: 12px;
    }

    .v-chip {
      &:first-child {
        margin-top: 12px;
      }
    }
  }

  .inputs-column {
    > *:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .inputs-row {
    > *:not(:last-child) {
      margin-right: 12px;
    }
    .col {
      max-width: 240px;
    }
  }

  .v-menu__content:not([role="menu"]) {
    border-radius: 4px;

    .v-list-item__title {
      white-space: normal;
    }

    &.theme--light.menuable__content__active {
      width: min-content;
    }
  }

  // Overrides error message style to include linebreaks
  .flash__wrapper {
    white-space: pre-line;
  }

  .v-list-item__action {
    margin-right: 16px;
  }
}
</style>
