import apiClient from '@/services/api-client';

/**
 * @template T, C
 * @typedef {import('@/store/types/with-context').WithContext<T, C>} WithContext<T, C>
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionContext<S, R>} ActionContext<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./shared-roles.state').SharedRolesState} SharedRolesState
 */

/**
 * @typedef {Object} SharedRolesActions
 * @property {() => Promise<void>} getProjectRoles
 * @property {() => Promise<void>} getSystemRoles
 * @property {() => Promise<void>} getSystemRolesICanSet
 */

/** @type {WithContext<SharedRolesActions, ActionContext<SharedRolesState, RootState>>} */
export const sharedRolesActions = {
  getProjectRoles: async ({ commit, getters }) => {
    if (!getters.projectRoles.length) {
      const { data } = await apiClient.roleApi.getProjectRoles();

      commit('setProjectRoles', data);
    }
  },
  getSystemRoles: async ({ commit }) => {
    const { data } = await apiClient.roleApi.getSystemRoles();

    commit('setSystemRoles', data);
  },
  getSystemRolesICanSet: async ({ commit }) => {
    const { data } = await apiClient.roleApi.getSystemRolesICanSet();

    commit('setSystemRolesICanSet', data);
  },
};
