import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

/**
 * @typedef {import('@/models/project.interface').Project} Project
 */

export class UserApi extends ApiBase {
  /** @returns {Promise<AxiosResponse<Project[]>>} */
  getProjects = () => this.apiClient.sendRequest({
    method: 'GET',
    url: 'user/projects/',
  });
}
