import get from 'lodash/get';

export class CollectionsHelper {
  /**
   * Sorts collection of objects by particular property value of string type.
   * comparison is locale-aware case-insensitive
   * Returns: sorted collection
   * Note: sorting happens in-place
   *
   * @template T
   * @param {T[]} collection
   * @param {string} propertyPath
   * @returns {T[]}
   */
  static sortObjectsByProperty(collection, propertyPath) {
    return collection.sort((a, b) => {
      const titleA = get(a, propertyPath).toLocaleUpperCase();
      const titleB = get(b, propertyPath).toLocaleUpperCase();

      if (titleA < titleB) {
        return -1;
      }

      return titleA > titleB ? 1 : 0;
    });
  }

  /**
   * Filters out all objects in collection that aren't contain particular property/value pair
   * property value comparison is locale-aware case-insensitive
   * Returns: filtered collection
   *
   * @template T
   * @param {T[]} objectsCollection
   * @param {keyof T} propertyName
   * @param {string} propertyValue
   * @returns {T[]}
   */
  static filterObjectsByStringProperty(
    objectsCollection,
    propertyName,
    propertyValue,
  ) {
    if (propertyValue != null) {
      const caseInsensitivePropertyValue = propertyValue.toLocaleUpperCase();

      return objectsCollection.filter((element) => {
        const property = element[propertyName];

        if (typeof property === 'string') {
          return property.toLocaleUpperCase().includes(caseInsensitivePropertyValue);
        }

        if (property instanceof Array) {
          if (property.length > 0 && typeof property[0] !== 'string') {
            throw new Error('Property should be string or array of strings');
          }

          return property
            .map((item) => item.toLocaleUpperCase())
            .includes(caseInsensitivePropertyValue);
        }

        throw new Error('Property should be string or array of strings');
      });
    }

    return objectsCollection;
  }

  /**
   * Filters out all objects in collection that aren't intersect with particular property/value array pair
   * property value comparison is locale-aware case-insensitive
   * Returns: filtered collection
   *
   * @template T
   * @param {T[]} objectsCollection
   * @param {keyof T} propertyName
   * @param {string[]} propertyValueArray
   * @returns {T[]}
   */
  static filterObjectsByStringPropertyArray(
    objectsCollection,
    propertyName,
    propertyValueArray,
  ) {
    if (propertyValueArray.length) {
      if (propertyValueArray.some((item) => item === '')) {
        return objectsCollection;
      }

      const caseInsensitivePropertyValueArray = propertyValueArray.map((e) => e.toLocaleUpperCase());

      return objectsCollection.filter((element) => {
        const property = element[propertyName];

        if (typeof property === 'string') {
          return caseInsensitivePropertyValueArray.includes(property.toLocaleUpperCase());
        }

        if (property instanceof Array) {
          if (property.length > 0 && typeof property[0] !== 'string') {
            throw new Error('Property should be string or array of strings');
          }

          return property
            .map((item) => item.toLocaleUpperCase())
            .some((item) => caseInsensitivePropertyValueArray.includes(item));
        }

        throw new Error('Property should be string or array of strings');
      });
    }

    return objectsCollection;
  }

  /**
   * Returns collection of elements that are not in both collections.
   *
   * @template T
   * @param {T[]} firstCollection
   * @param {T[]} secondCollection
   * @returns {T[]}
   */
  static difference(firstCollection, secondCollection) {
    const firstMissingElements = firstCollection.filter((x) => !secondCollection.includes(x));
    const secondMissingElements = secondCollection.filter((x) => !firstCollection.includes(x));

    return firstMissingElements.concat(secondMissingElements);
  }
}
