import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('@/models/holiday.interface').Holiday} Holiday
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 * @typedef {import('../types/holiday.api.types').CreateHolidayParams} CreateHolidayParams
 * @typedef {import('../types/holiday.api.types').CreateHolidaytData} CreateHolidaytData
 * @typedef {import('../types/holiday.api.types').UpdateHolidayParams} UpdateHolidayParams
 */

export class HolidayApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  HOLIDAYS_API_ROOT = 'holidays';

  /**
   * Get all holidays
   * @returns {Promise<AxiosResponse<Holiday[]>>}
   */
  getHolidays = () => this.apiClient.sendRequest(
    {
      method: 'GET',
      url: `${this.HOLIDAYS_API_ROOT}/`,
    },
    { loadingType: loadingTypes.none }
  );

  /**
  Get multiple holidays by location id?
  */

  /**
   * Update holiday information
   * @param {string} id holiday id
   * @param {UpdateHolidayParams} holiday payload with new holiday data
   * @returns {Promise<AxiosResponse<void>>}
   */
  updateHoliday = (id, holiday) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.HOLIDAYS_API_ROOT}/${id}`,
    data: holiday,
  });

  /**
   * Create holiday
   * @param {CreateHolidayParams} holiday payload with new holiday data
   * @returns {Promise<AxiosResponse<CreateHolidaytData>>}
   */
  createHoliday = (holiday) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.HOLIDAYS_API_ROOT}/`,
    data: holiday,
  });

  /**
   * Delete holiday
   * @param {string} id holiday id
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteHoliday = (id) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.HOLIDAYS_API_ROOT}/${id}`,
  });
}
