import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

/**
  * @typedef {import('@/models/summary-report-unit.interface').SummaryReportUnit} SummaryReportUnits
  * @typedef {import('@/models/summary-unit.interface').SummaryUnit} SummaryUnit
  */

export class ReportSummaryApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  REPORTS_API_ROOT = 'reports'

  /**
   * @private
   * @readonly
   */
  SUMMARY_REPORTS_UNITS_API_ROOT = 'summary-report-units'

  /**
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<SummaryUnit[]>>}
   */
  getSummary = (reportId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/summary/`,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} summaryReportUnitId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteSummaryReportUnit = (summaryReportUnitId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.SUMMARY_REPORTS_UNITS_API_ROOT}/${summaryReportUnitId}`,
  });

  /**
   * @param {string} summaryReportUnitId
   * @param {SummaryReportUnits} summaryReportUnit
   * @returns {Promise<AxiosResponse<void>>}
   */
  updateSummaryReportUnit = (summaryReportUnitId, summaryReportUnit) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.SUMMARY_REPORTS_UNITS_API_ROOT}/${summaryReportUnitId}`,
    data: summaryReportUnit,
  });
}
