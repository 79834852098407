/**
 * @typedef {import('@/models/time-unit.interface').TimeUnit} TimeUnit
 */

/**
 * Creates by-date map of time units array.
 *
 * @param {TimeUnit[]} timeUnits Time units array
 * @returns {Map<string, TimeUnit[]>} Time units map
 */
// TODO: move to time units actions? (not used anywhere except time unit actions)
export const makeTimeUnitsMap = (timeUnits) => timeUnits.reduce(
  (acc, timeUnit) => {
    const key = timeUnit.date;

    if (acc.has(key)) {
      const value = acc.get(key);

      if (value) {
        acc.set(key, [...value, timeUnit]);
      }

      return acc;
    }

    acc.set(key, [timeUnit]);

    return acc;
  }, new Map()
);
