import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';

import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/office.interface').Office} Office
 * @typedef {import('@/models/task-type.interface').TaskType} TaskType
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} SharedTaskTypeState
 * @property {TaskType[]} allTaskTypes
 * @property {TaskType[]} projectsTaskTypes
 */

/** @returns {SharedTaskTypeState} */
const getInitialState = () => ({
  allTaskTypes: [],
  projectsTaskTypes: [],
});

/** @type {SharedTaskTypeState} */
const state = getInitialState();

/** @type {GetterTree<SharedTaskTypeState, RootState>} */
const getters = {
  allTaskTypes: (state) => state.allTaskTypes,
  projectsTaskTypes: (state) => state.projectsTaskTypes,
};

/** @type {ActionTree<SharedTaskTypeState, RootState>} */
const actions = {
  getAllTaskTypes: async ({ commit }) => {
    const { data: { task_types } } = await apiClient.taskTypeApi.getProjectsTaskTypes([]);

    commit('setAllTaskTypes', task_types);
  },

  getProjectsTaskTypes: async ({ commit }, { projectIds }) => {
    const { data } = await apiClient.taskTypeApi.getProjectsTaskTypes(projectIds);
    const projectsMap = keyBy(data, 'project_id');
    const projectsTaskTypesMap = mapValues(projectsMap, ({ task_types }) => sortBy(task_types, 'title'));

    commit('setProjectsTaskTypes', projectsTaskTypesMap);
  },

  addTaskType: async (context, { taskTypeTitle, projectId }) => {
    const { data: { id } } = await apiClient.taskTypeApi.createTaskType({ title: taskTypeTitle });

    await apiClient.taskTypeApi.addTaskTypeToProject(projectId, id);
  },

  deleteTaskType: async (context, { projectId, taskTypeId }) => {
    await apiClient.taskTypeApi.deleteProjectsTaskTypes(projectId, taskTypeId);
  },
};

/** @type {MutationTree<SharedTaskTypeState>} */
const mutations = {
  setAllTaskTypes: (state, taskTypes) => (state.allTaskTypes = sortBy(taskTypes, 'title')),
  setProjectsTaskTypes: (state, projectsTaskTypesMap) => (state.projectsTaskTypes = projectsTaskTypesMap),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<SharedTaskTypeState, RootState>} */
export const taskTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
