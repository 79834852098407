<template>
  <div class="date-range">
    <v-row
      align="center"
      justify="center"
      class="nowrap-row"
    >
      <v-btn
        v-if="isTimeRangeMoveVisible"
        text
        icon
        x-small
        :disabled="disabled"
        @click="timeRangeMove(false)"
      >
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <div class="v-date-range">
        <v-menu
          v-model="isDateRangeVisible"
          :close-on-content-click="false"
          offset-y
        >
          <template #activator="{ on }">
            <v-text-field
              class="v-date-range__input-field"
              readonly
              hide-details
              :value="inputValue"
              :disabled="disabled"
              v-on="on"
            />
          </template>
          <v-row
            v-if="isDateRangeVisible"
            v-click-outside="datePickerButtons ? discardChanges : applyRange"
            class="v-date-range__menu-content"
          >
            <v-col
              :data-days="highlightDates.length"
              :class="{
                'v-date-range__pickers': true,
                'v-date-range--highlighted': highlightDates.length
              }"
            >
              <v-row @keyup="handleEnterToSubmit">
                <v-col class="v-date-range__content">
                  <v-row class="d-flex">
                    <v-date-picker
                      v-model="startDate"
                      class="v-date-range__picker--start v-date-range__picker"
                      :first-day-of-week="firstDayOfWeek || settingsFirstDayOfWeek"
                      :min="minDateIso"
                      :max="maxDateIso"
                      :no-title="noTitle"
                      :events="highlightDates"
                      :event-color="highlightClasses"
                      color="primary"
                    />
                    <v-divider vertical />
                    <v-date-picker
                      v-model="endDate"
                      class="v-date-range__picker--end v-date-range__picker"
                      :first-day-of-week="firstDayOfWeek || settingsFirstDayOfWeek"
                      :min="startDate || minDateIso"
                      :max="maxEndDate || maxDateIso"
                      :no-title="noTitle"
                      :events="highlightDates"
                      :event-color="highlightClasses"
                      color="primary"
                    />
                  </v-row>
                  <template v-if="datePickerButtons">
                    <v-divider />
                    <v-row
                      class="pa-2"
                      justify="end"
                    >
                      <v-btn
                        text
                        class="mr-2"
                        @click="discardChanges"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="applyRange"
                      >
                        Apply
                      </v-btn>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-menu>
      </div>
      <v-btn
        v-if="isTimeRangeMoveVisible"
        text
        icon
        x-small
        :disabled="disabled"
        @click="timeRangeMove(true)"
      >
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { mapGetters, mapActions } from 'vuex';

import { DateHelper } from '@/helpers/date.helper';

const defaultDate = DateHelper.toIso(new Date());
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    value: {
      type: Object,
      default: () => ({ startDate: defaultDate, endDate: defaultDate }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDateIso: {
      type: String,
      default: undefined,
    },
    maxDateIso: {
      type: String,
      default: undefined,
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 0,
    },
    noTitle: {
      type: Boolean,
      default: true,
    },
    displayFormat: {
      type: String,
      required: true,
    },
    highlightColor: {
      type: String,
      default: 'primary lighten-4',
    },
    isTimeRangeMoveVisible: {
      type: Boolean,
      default: true,
    },
    isTimesheetPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDateRangeVisible: false,
      startDate: this.value.startDate,
      endDate: this.value.endDate,
      highlightDates: [],
      highlightClasses: {},
    };
  },
  computed: {
    ...mapGetters('user/settings', ['dateFormatSettings', 'datePickerButtons']),

    settingsFirstDayOfWeek() {
      return DateHelper.getFirstDayOfWeekAccordingSettings(this.dateFormatSettings);
    },
    inputValue() {
      if (this.isValueEmpty) {
        return '';
      }

      return this.displayFormat;
    },
    isValueEmpty() {
      return !this.value.startDate;
    },
    bothSelected() {
      return this.startDate && this.endDate;
    },
    maxEndDate() {
      return this.isTimesheetPage ? DateHelper.toIso(DateHelper.addYears(this.startDate)) : null;
    },
  },
  watch: {
    isDateRangeVisible(isOpen) {
      this.startDate = this.value.startDate;
      this.endDate = this.value.endDate;

      if (!isOpen) {
        this.closeMenu();
      } else {
        this.highlight();
      }

      this.setIsDateRangeOpen(isOpen);
    },
    startDate(newDate, oldDate) {
      if (!DateHelper.isDayBefore(newDate, this.endDate)) {
        const datesDifference = DateHelper.countDaysBetween(oldDate, this.endDate);

        this.endDate = DateHelper.toIso(DateHelper.addDays(newDate, datesDifference));
      }

      if (this.isTimesheetPage) {
        const datesDifference = DateHelper.countYearsBetween(this.startDate, this.endDate);

        if (datesDifference) {
          this.endDate = DateHelper.toIso(DateHelper.addYears(this.startDate));
        }
      }

      this.highlight();
    },
    endDate: 'highlight',
  },
  methods: {
    ...mapActions('shared/common', ['setIsDateRangeOpen']),

    applyRange() {
      this.isDateRangeVisible = false;
      this.emitRange();
    },
    closeMenu() {
      this.startDate = this.value.startDate;
      this.endDate = this.value.endDate;
      this.highlight();
      this.$emit('menu-closed');
    },
    discardChanges() {
      this.isDateRangeVisible = false;
    },
    highlight() {
      if (!this.bothSelected) {
        return;
      }

      const dates = [];
      const classes = {};
      const start = this.startDate;
      const end = this.endDate;

      const datesDifference = DateHelper.countDaysBetween(start, end);

      for (let i = 0; i <= datesDifference; i += 1) {
        const date = DateHelper.toIso(DateHelper.addDays(start, i));

        dates.push(date);
        const classesArr = [];

        classesArr.push('v-date-range__in-range');
        classesArr.push(this.highlightColor);

        if (i === 0) {
          classesArr.push('v-date-range__range-start');
        }

        if (i === datesDifference) {
          classesArr.push('v-date-range__range-end');
        }

        classes[date] = classesArr.join(' ');
      }
      this.highlightDates = dates;
      this.highlightClasses = classes;
    },
    emitRange() {
      this.$emit('input', {
        start: this.startDate,
        end: this.endDate,
      });
    },
    handleEnterToSubmit(e) {
      if (e.key === 'Enter') {
        this.applyRange();
      } else if (e.key === 'Escape') {
        this.isDateRangeVisible = false;
      }
    },
    handleFocusOut() {
      this.applyRange();
    },
    timeRangeMove(isForward) {
      this.$emit('timeRangeMove', isForward);
    },
  },
};
</script>

<style lang="less">
@import '~variables';

.date-range .v-input{
  margin: 0;
  padding: 0;
}
.v-date-range__menu-content {
  background-color: @white;
}
.nowrap-row {
  flex-wrap: nowrap;
}
.v-date-range__input-field input {
  text-align: center;
}
.v-date-range__content .v-date-picker-table .v-btn {
  border-radius: 0;
}
.v-date-range__pickers {
  .v-date-picker-table__events {
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
  }

  .v-date-picker-table table {
    width: auto;
    margin: auto;
    border-collapse: collapse;
    & th, & td {
      height: 32px;
      width: 32px;
    }
    & td {
      .v-btn {
        &.v-btn--outline {
          border: none;
          box-shadow: 0 0 0 1px currentColor inset;
        }
        &.v-btn--active::before {
          background-color: transparent !important;
        }
      }
    }
  }

  .v-date-range__in-range {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
.v-date-range__pickers .v-btn.v-btn--rounded.v-btn--outlined.theme--light.primary--text {
  border: none;
}
.v-date-range {
  width: 100%;
  display: contents;
}
.date-range {
  min-width: 240px;
}
</style>
