import { CollectionsHelper } from '@/helpers/collections.helper';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/office.interface').Office} Office
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} SharedOfficestate
 * @property {Office[]} offices
 */

/** @returns {SharedOfficestate} */
const getInitialState = () => ({
  offices: [],
});

/** @type {SharedOfficestate} */
const state = getInitialState();

/** @type {GetterTree<SharedOfficestate, RootState>} */
const getters = {
  offices: (state) => state.offices,
  userOffices: ({ offices }, getters, rootState, rootGetters) => {
    const {
      'auth/account/user': user,
      'auth/account/isUserDM': isUserDM,
      'auth/account/isUserAdmin': isUserAdmin,
    } = rootGetters;

    if (isUserAdmin || isUserDM) {
      return offices;
    }

    const userOffice = offices.find((office) => office.id === user.office);

    return userOffice ? [userOffice] : [];
  },
};

/** @type {ActionTree<SharedOfficestate, RootState>} */
const actions = {
  getOffices: async ({ commit }) => {
    const { data } = await apiClient.officeApi.getOffices();

    commit('setOffices', data);
  },
};

/** @type {MutationTree<SharedOfficestate>} */
const mutations = {
  setOffices: (state, offices) => (state.offices = CollectionsHelper.sortObjectsByProperty(offices, 'name')),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<SharedOfficestate, RootState>} */
export const offices = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
