import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('../types/auth.api.types').LoginData} LoginData
 * @typedef {import('../types/auth.api.types').GetTokensData} GetTokensData
 */

export class AuthApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  AUTH_API_ROOT = 'auth';
  /**
   * @param {string} username
   * @param {string} password
   * @returns {Promise<AxiosResponse<LoginData>>}
   */
  login = (username, password) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.AUTH_API_ROOT}/sign-in`,
    data: { username, password },
  });

  /** @returns {Promise<AxiosResponse<void>>} */
  logout = () => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.AUTH_API_ROOT}/sign-out`,
  });

  /**
   * @param {string | null} refreshToken
   * @returns {Promise<AxiosResponse<GetTokensData>>}
   */
  getTokens = (refreshToken) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.AUTH_API_ROOT}/refresh-token`,
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
}
