import { getUserJiraInitialState } from './user-jira.state';

/**
 * @template T, C
 * @typedef {import('@/store/types/with-context').WithContext<T, C>} WithContext<T, C>
 */

/**
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraImportTimeUnitsData} JiraImportTimeUnitsData
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraProject} JiraProject
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraCredentials} JiraCredentials
 * @typedef {import('./user-jira.state').UserJiraState} UserJiraState
 */

/**
 * @typedef {Object} UserJiraMutations
 * @property {(jiraCredentialsCollection: JiraCredentials[]) => void} setCredentials
 * @property {(importResult: JiraImportTimeUnitsData | null) => void} setImportResult
 * @property {(jiraProjects: JiraProject[]) => void} setJiraProjects
 * @property {() => void} resetModuleState
 */

/** @type {WithContext<UserJiraMutations, UserJiraState>} */
export const userJiraMutations = {
  setCredentials: (state, jiraCredentialsCollection) => state.jiraCredentials = jiraCredentialsCollection,
  setImportResult: (state, importResult) => state.importResult = importResult,
  setJiraProjects: (state, jiraProjects) => state.jiraProjects = jiraProjects,
  resetModuleState: (state) => Object.assign(state, getUserJiraInitialState()),
};
