import isEqual from 'lodash/isEqual';
import unionWith from 'lodash/unionWith';
import moment from 'moment';

import assignmentStatuses from '@/constants/assignmentStatuses';
import { projectRolesNames } from '@/constants/roles';
import { timeUnitStatuses } from '@/constants/timeUnitStatuses';
import { makeTimeUnitsMap } from '@/helpers/make-time-units-map';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/time-unit.interface').TimeUnit} TimeUnit
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} Filters
 * @property {string | null} employee_id
 * @property {string | null} start_date
 * @property {string | null} end_date
 */

/**
 * @typedef {Object} TimesheetTimeUnitsState
 * @property {Map<string, TimeUnit>} timeUnits
 * @property {Assignment.id} timeUnitAssignmentId
 * @property {boolean} isTimesheetEditing
 * @property {boolean} isFilterSelecting
 * @property {Filters} downloadedFilters
 * @property {Filters} storedFilters
 */

// #region Helpers
const addEvenHighlight = (timeUnits) => {
  let isEven = true;

  return timeUnits
    .map((unit, index, timeUnits) => {
      const previousTimeUnitDate = index > 0 ? timeUnits[index - 1].date : unit.date;

      if (previousTimeUnitDate !== unit.date) {
        isEven = !isEven;
      }

      return {
        ...unit,
        isEven,
      };
    });
};
// #endregion

/** @returns {TimesheetTimeUnitsState} */
const getInitialState = () => ({
  timeUnits: new Map(),
  timeUnitAssignmentId: null,
  isTimesheetEditing: false,
  isFilterSelecting: false,
  downloadedFilters: {
    employee_id: null,
    start_date: null,
    end_date: null,
  },
  storedFilters: {
    employee_id: null,
    start_date: null,
    end_date: null,
  },
});

/** @type {TimesheetTimeUnitsState} */
const state = getInitialState();

/** @type {GetterTree<TimesheetTimeUnitsState, RootState>} */
const getters = {
  storedFilters: (state) => state.storedFilters,
  isCurrentTimeUnitsLoading: ({ downloadedFilters }, getters, rootState, rootGetters) => {
    const {
      'timesheet/filters/dateRangeFilter': {
        startDate,
        endDate,
      },
      'timesheet/filters/employeeFilter': { id: employee_id },
    } = rootGetters;

    if (!downloadedFilters.start_date) {
      return true;
    }

    return moment(downloadedFilters.start_date).isAfter(startDate)
      || moment(downloadedFilters.end_date).isBefore(endDate)
      || downloadedFilters.employee_id !== employee_id;
  },
  persistedTimeUnits: (state, getters) => getters.filteredTimeUnits.filter((unit) => unit.id !== null),
  isTimesheetEditing: (state) => state.isTimesheetEditing,
  isFilterSelecting: (state) => state.isFilterSelecting,
  filteredTimeUnits: (state, getters, rootState, rootGetters) => {
    const {
      'timesheet/filters/dateRangeFilter': {
        startDate,
        endDate,
      },
      'timesheet/filters/projectFilter': { id: project_id },
      'timesheet/filters/statusFilter': statusFilter,
    } = rootGetters;

    let filteredTimeUnits = [];

    for (const [date, timeUnites] of state.timeUnits) {
      if (moment(date).isSameOrAfter(startDate) && moment(date).isSameOrBefore(endDate) && timeUnites) {
        filteredTimeUnits = filteredTimeUnits.concat(timeUnites);
      }
    }

    if (!filteredTimeUnits.length) {
      return [];
    }

    if (statusFilter !== 'All') {
      filteredTimeUnits = filteredTimeUnits.filter((unit) => unit.status === statusFilter || unit.id === null);
    }

    if (project_id) {
      filteredTimeUnits = filteredTimeUnits.filter((unit) => unit.project_id === project_id || unit.id === null);
    }

    filteredTimeUnits.sort((a, b) => a.date.localeCompare(b.date));

    return addEvenHighlight(filteredTimeUnits);
  },
};

/** @type {ActionTree<TimesheetTimeUnitsState, RootState>} */
const actions = {
  // #region Get time units if needed
  updateStoredTimeUnits: async ({ commit, dispatch, getters, rootGetters }, config) => {
    if (config && config.reset) {
      commit('resetModuleState');
    }

    dispatch('cancelTimeUnitEditing');

    const {
      'timesheet/filters/dateRangeFilter': {
        startDate: filterStartDate,
        endDate: filterEndDate,
      },
      'timesheet/filters/employeeFilter': { id: employee_id },
    } = rootGetters;
    const { storedFilters } = getters;

    if (employee_id) {
      const scheduleParams = {
        start_date: filterStartDate,
        end_date: filterEndDate,
        employee_ids: [employee_id],
      };

      dispatch('employees/single/getSchedule', scheduleParams, { root: true });
    }

    const isEmployeeChanged = employee_id !== storedFilters.employee_id;

    const isStartOutside = moment(storedFilters.start_date).isAfter(filterStartDate);
    const isEndOutside = moment(storedFilters.end_date).isBefore(filterEndDate);

    if (!storedFilters.start_date || isEmployeeChanged || (config && config.reset) || isStartOutside || isEndOutside) {
      const one_day_before_date = moment(filterStartDate).subtract(1, 'd').format('YYYY-MM-DD');
      const two_weeks_before_date = moment(filterStartDate).subtract(14, 'd').format('YYYY-MM-DD');
      const one_day_after_date = moment(filterEndDate).add(1, 'd').format('YYYY-MM-DD');
      const two_weeks_after_date = moment(filterEndDate).add(14, 'd').format('YYYY-MM-DD');

      const filterParams = {
        start_date: filterStartDate,
        end_date: filterEndDate,
        employee_id,
      };

      const twoWeeksBeforeFilterParams = {
        start_date: two_weeks_before_date,
        end_date: one_day_before_date,
        employee_id,
      };

      const twoWeeksAfterFilterParams = {
        start_date: one_day_after_date,
        end_date: two_weeks_after_date,
        employee_id,
      };

      if (!storedFilters.start_date || isEmployeeChanged || (config && config.reset)) {
        if (isEmployeeChanged && state.timeUnits.size) {
          commit('setTimeUnits', new Map());
        }

        const finalFilterParams = {
          start_date: two_weeks_before_date,
          end_date: two_weeks_after_date,
          employee_id,
        };

        try {
          commit('setStoredFilters', finalFilterParams);
          const { data: timeUnits } = await apiClient.timeUnitApi.getTimeUnits(filterParams);

          commit('setDownloadedFilters', filterParams);

          if (timeUnits.length) {
            commit('setTimeUnits', makeTimeUnitsMap(timeUnits));
          }

          const [{ data: twoWeeksBeforeUnits }, { data: twoWeeksAfterUnits }] = await Promise.all([
            apiClient.timeUnitApi.getTimeUnits(twoWeeksBeforeFilterParams),
            apiClient.timeUnitApi.getTimeUnits(twoWeeksAfterFilterParams),
          ]);
          const timeUnitsExtended = [...twoWeeksBeforeUnits, ...twoWeeksAfterUnits];

          commit('setDownloadedFilters', finalFilterParams);

          if (timeUnitsExtended.length) {
            commit('addTimeUnits', makeTimeUnitsMap(timeUnitsExtended));
          }
        } catch {
          dispatch('updateStoredTimeUnits', { reset: true });
        }

        return;
      }

      const requestArrayInFilter = [];
      const requestArrayWithWeeks = [];
      const finalFilterParams = {
        start_date: storedFilters.start_date,
        end_date: storedFilters.end_date,
        employee_id,
      };

      if (isStartOutside) {
        requestArrayInFilter.push(apiClient.timeUnitApi.getTimeUnits(
          {
            start_date: filterStartDate,
            end_date: moment(storedFilters.start_date).subtract(1, 'd').format('YYYY-MM-DD'),
            employee_id,
          },
        ));
        requestArrayWithWeeks.push(apiClient.timeUnitApi.getTimeUnits(twoWeeksBeforeFilterParams));
        finalFilterParams.start_date = two_weeks_before_date;
      }

      if (isEndOutside) {
        requestArrayInFilter.push(apiClient.timeUnitApi.getTimeUnits(
          {
            start_date: moment(storedFilters.end_date).add(1, 'd').format('YYYY-MM-DD'),
            end_date: filterEndDate,
            employee_id,
          },
        ));
        requestArrayWithWeeks.push(apiClient.timeUnitApi.getTimeUnits(twoWeeksAfterFilterParams));
        finalFilterParams.end_date = two_weeks_after_date;
      }

      try {
        commit('setStoredFilters', finalFilterParams);
        const [responseFirst, responseSecond] = await Promise.all(requestArrayInFilter);

        commit('setDownloadedFilters', filterParams);

        if (responseSecond) {
          const timeUnits = [...responseFirst.data, ...responseSecond.data];

          if (timeUnits.length) {
            commit('addTimeUnits', makeTimeUnitsMap(timeUnits));
          }
        } else {
          const timeUnits = responseFirst.data;

          if (timeUnits.length) {
            commit('addTimeUnits', makeTimeUnitsMap(responseFirst.data));
          }
        }

        const [responseFirstWeeks, responseSecondWeeks] = await Promise.all(requestArrayWithWeeks);

        commit('setDownloadedFilters', finalFilterParams);

        if (responseSecondWeeks) {
          const timeUnits = [...responseFirstWeeks.data, ...responseSecondWeeks.data];

          if (timeUnits.length) {
            commit('addTimeUnits', makeTimeUnitsMap(timeUnits));
          }
        } else {
          const timeUnits = responseFirstWeeks.data;

          if (timeUnits.length) {
            commit('addTimeUnits', makeTimeUnitsMap(timeUnits));
          }
        }
      } catch {
        dispatch('updateStoredTimeUnits', { reset: true });
      }
    }
  },
  // #endregion

  // #region Single time unit actions
  createTimeUnit: async ({ commit, dispatch, getters, rootGetters }, { newTimeUnit, oldTimeUnit }) => {
    dispatch('cancelTimeUnitEditing', oldTimeUnit.date);
    const { storedFilters } = getters;
    const {
      'auth/account/userProjects': userProjects,
      'shared/taskTypes/projectsTaskTypes': projectsTaskTypes,
    } = rootGetters;

    const isOutsideStoredDateRange = moment(newTimeUnit.date).isBefore(storedFilters.start_date)
    || moment(newTimeUnit.date).isAfter(storedFilters.end_date);

    if (isOutsideStoredDateRange) {
      try {
        await apiClient.timeUnitApi.createTimeUnit(newTimeUnit);
      } catch {
        dispatch('updateStoredTimeUnits', { reset: true });
      }

      return;
    }

    /** @type {TimeUnit} */
    const updatedTimeUnit = { ...newTimeUnit };

    const timeUnitProject = userProjects.find((el) => el.id === newTimeUnit.project_id);
    const timeUnitTaskType = (projectsTaskTypes[newTimeUnit.project_id] || [])
      .find((el) => el.id === newTimeUnit.task_type_id);

    if (timeUnitProject) {
      updatedTimeUnit.project_title = timeUnitProject.title;
    }

    if (timeUnitTaskType) {
      updatedTimeUnit.task_type_title = timeUnitTaskType.title;
    }

    const tempId = `temp_${moment().unix()}`;

    /** @type {TimeUnit} */
    const tempTimeUnit = {
      ...updatedTimeUnit,
      id: tempId,
    };

    commit('insertTimeUnit', tempTimeUnit);

    try {
      const { data } = await apiClient.timeUnitApi.createTimeUnit(updatedTimeUnit);

      /** @type {TimeUnit} */
      const timeUnitWithId = {
        ...updatedTimeUnit,
        id: data.time_unit_id,
      };

      dispatch('updateTimeUnit', {
        newTimeUnit: timeUnitWithId,
        oldTimeUnit: tempTimeUnit,
      });
    } catch (error) {
      dispatch('updateStoredTimeUnits', { reset: true });

      throw error;
    }
  },

  updateTimeUnit: async ({ commit, dispatch, getters, rootGetters }, { newTimeUnit, oldTimeUnit }) => {
    dispatch('cancelTimeUnitEditing');

    const { storedFilters } = getters;
    const {
      'auth/account/userProjects': userProjects,
      'shared/taskTypes/projectsTaskTypes': projectsTaskTypes,
    } = rootGetters;

    const newProject = userProjects.find((el) => el.id === newTimeUnit.project_id);
    const newTaskType = (projectsTaskTypes[newTimeUnit.project_id] || [])
      .find((el) => el.id === newTimeUnit.task_type_id);

    /** @type {TimeUnit} */
    const updatedTimeUnit = {
      ...newTimeUnit,
      project_title: newProject.title,
      task_type_title: newTaskType.title,
    };

    const isOutsideStoredDateRange = moment(newTimeUnit.date).isBefore(storedFilters.start_date)
    || moment(newTimeUnit.date).isAfter(storedFilters.end_date);

    if (newTimeUnit.date === oldTimeUnit.date) {
      const replaceConfig = {
        timeUnit: updatedTimeUnit,
        replaceID: oldTimeUnit.id,
      };

      commit('replaceTimeUnit', replaceConfig);
    } else {
      commit('deleteTimeUnit', { id: oldTimeUnit.id, date: oldTimeUnit.date });

      if (!isOutsideStoredDateRange) {
        const timeUnitOutsideRange = {
          ...updatedTimeUnit,
          next_time_unit_id: null,
        };

        commit('insertTimeUnit', timeUnitOutsideRange);
      }
    }

    try {
      await apiClient.timeUnitApi.changeTimeUnit(newTimeUnit);
    } catch (error) {
      dispatch('updateStoredTimeUnits', { reset: true });

      throw error;
    }
  },

  deleteTimeUnit: async ({ commit, dispatch, state }, timeUnitId, timeUnitDate) => {
    const { timeUnits } = state;

    if (!timeUnits.has(timeUnitDate)) {
      return;
    }

    const thisDateTimeUnits = timeUnits.get(timeUnitDate);
    const itemToDelete = thisDateTimeUnits.find((unit) => unit.id === timeUnitId);

    if (itemToDelete) {
      commit('deleteTimeUnit', { id: timeUnitId, date: timeUnitDate });

      try {
        await apiClient.timeUnitApi.deleteTimeUnit(timeUnitId);
      } catch {
        dispatch('updateStoredTimeUnits', { reset: true });
      }
    }
  },
  // #endregion

  // #region Bulk time unit actions
  updateTimeUnitsStatuses: async ({ commit, dispatch }, { status, timeUnitsToUpdate, extraData }) => {
    if (status && timeUnitsToUpdate && timeUnitsToUpdate.length > 0) {
      const timeUnitsToUpdateIDs = timeUnitsToUpdate.map((unit) => unit.id);

      commit('updateTimeUnitsStatuses', {
        status, timeUnitsToUpdate, timeUnitsToUpdateIDs, extraData,
      });

      try {
        await apiClient.timeUnitApi.updateTimeUnitsStatuses(status, timeUnitsToUpdateIDs, extraData);
      } catch {
        dispatch('updateStoredTimeUnits', { reset: true });
      }
    }
  },

  deleteBatchTimeUnits: async ({ commit, state, dispatch }, timeUnitsToDelete) => {
    const { timeUnits } = state;
    const timeUnitsToDeleteMap = makeTimeUnitsMap(timeUnitsToDelete);
    const timeUnitIds = timeUnitsToDelete.map((timeUnit) => timeUnit.id);

    for (const date of timeUnitsToDeleteMap.keys()) {
      const thisDateTimeUnits = timeUnits.get(date);
      const filteredThisDateTimeUnits = thisDateTimeUnits.filter((timeUnit) => !timeUnitIds.includes(timeUnit.id));

      timeUnits.set(date, filteredThisDateTimeUnits);
    }
    commit('setTimeUnits', new Map(timeUnits));

    try {
      await apiClient.timeUnitApi.deleteBatchTimeUnits(timeUnitIds);
    } catch {
      dispatch('updateStoredTimeUnits', { reset: true });
    }
  },
  // #endregion

  // #region Edit state management
  setTimesheetEditMode: ({ commit }, value) => commit('setTimesheetEditing', value),
  cancelTimeUnitEditing: ({ commit }, date) => {
    if (date) {
      commit('clearTimeUnits', date);
    }

    commit('setTimesheetEditing', false);
  },

  addTimeUnit: async ({ dispatch }, originTimeUnit) => {
    dispatch('createTimeUnitPlaceholder', { originTimeUnit });
  },

  duplicateTimeUnit: async ({ dispatch }, originTimeUnit) => {
    dispatch('createTimeUnitPlaceholder', { originTimeUnit, isDuplicate: true });
  },

  createTimeUnitPlaceholder: ({ dispatch, commit, state, rootGetters }, { originTimeUnit, isDuplicate = false }) => {
    dispatch('cancelTimeUnitEditing');
    commit('setTimesheetEditing', true);

    const {
      'timesheet/filters/dateRangeFilter': { startDate },
      'auth/account/userProjects': userProjects,
      'shared/roles/projectRolesMap': projectRolesMap,
      'shared/taskTypes/projectsTaskTypes': projectsTaskTypes,
    } = rootGetters;

    const { timeUnits } = state;

    let isUserOnProject = false;
    let isProjectContainsTaskType = false;

    if (originTimeUnit) {
      const teamMemberRole = projectRolesMap[projectRolesNames.teamMember];
      const activeStatusProjects = userProjects.filter((project) => project.assignments.some(
        (assignment) => assignment.role_id === teamMemberRole.id
        && (assignment.status === assignmentStatuses.active || assignment.status === assignmentStatuses.prospective)
      ));
      const removedStatusProjects = userProjects.filter((project) => project.assignments.some(
        (assignment) => assignment.role_id === teamMemberRole.id && assignment.status === assignmentStatuses.removed
      ) && project.assignments.every(
        (assignment) => !(assignment.role_id === teamMemberRole.id && assignment.status === assignmentStatuses.active)
      ));

      isUserOnProject = [...activeStatusProjects, ...removedStatusProjects]
        .some((p) => p.id === originTimeUnit.project_id);
      isProjectContainsTaskType = (projectsTaskTypes[originTimeUnit.project_id] || [])
        .some((el) => el.id === originTimeUnit.task_type_id);
    }

    const timeUnit = {
      id: null,
      project_id: isUserOnProject ? originTimeUnit.project_id : null,
      task_type_id: isProjectContainsTaskType && isUserOnProject ? originTimeUnit.task_type_id : null,
      project_title: isUserOnProject ? originTimeUnit.project_title : null,
      task_type_title: isProjectContainsTaskType && isUserOnProject ? originTimeUnit.task_type_title : null,
      minutes: isDuplicate ? originTimeUnit.minutes : 60,
      overtime: isDuplicate ? originTimeUnit.overtime : false,
      description: isDuplicate ? originTimeUnit.description : '',
      date: originTimeUnit ? originTimeUnit.date : startDate,
      status: 'Draft',
    };

    if (originTimeUnit) {
      const thisDateTimeUnits = timeUnits.get(originTimeUnit.date);
      const originTimeUnitIndex = thisDateTimeUnits.findIndex((u) => u.id === originTimeUnit.id);

      thisDateTimeUnits.splice(originTimeUnitIndex + 1, 0, timeUnit);
      commit('addTimeUnits', new Map().set(originTimeUnit.date, thisDateTimeUnits));

      return;
    }

    commit('addTimeUnits', new Map().set(startDate, [timeUnit]));
  },
  // #endregion

  setFilterSelectingMode: ({ commit }, value) => commit('setFilterSelectingMode', value),
  cancelFilterSelectingMode: ({ commit }) => {
    commit('setFilterSelectingMode', false);
  },

  getLeavAbsenceHours: async () => {
    const { data } = await apiClient.timeUnitApi.getLeavAbsenceHours();

    return data;
  },

  getVacpAbsenceHours: async () => {
    const { data } = await apiClient.timeUnitApi.getVacpAbsenceHours();

    return data;
  },
};

/** @type {MutationTree<TimesheetTimeUnitsState>} */
const mutations = {
  // #region Up-front state mutations
  setTimeUnits: (state, timeUnits) => (state.timeUnits = timeUnits),
  addTimeUnits: (state, timeUnits) => {
    const combinedTimeUnits = new Map(state.timeUnits);

    for (const [date, unitsOnDate] of timeUnits) {
      if (!combinedTimeUnits.has(date)) {
        combinedTimeUnits.set(date, unitsOnDate);
      } else {
        const timeUnitsInState = combinedTimeUnits.get(date);
        const unionOfArrays = unionWith(timeUnitsInState, unitsOnDate, isEqual);

        combinedTimeUnits.set(date, unionOfArrays);
      }
    }

    state.timeUnits = combinedTimeUnits;
  },
  setStoredFilters: (state, filters) => (state.storedFilters = filters),
  setDownloadedFilters: (state, filters) => (state.downloadedFilters = filters),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
  setTimesheetEditing: (state, newState) => (state.isTimesheetEditing = newState),
  clearTimeUnits: (state, date) => {
    const { timeUnits } = state;
    const thisDateTimeUnits = timeUnits.get(date);
    const thisDateTimeUnitsFiltered = thisDateTimeUnits.filter((unit) => unit.id !== null);

    timeUnits.set(date, thisDateTimeUnitsFiltered);
    state.timeUnits = new Map(timeUnits);
  },
  // #endregion

  // #region Single time unit mutations
  replaceTimeUnit: (state, { timeUnit, replaceID }) => {
    if (replaceID) {
      const { timeUnits } = state;
      const thisDateTimeUnits = timeUnits.get(timeUnit.date);
      const replaceIndex = thisDateTimeUnits.findIndex((unit) => unit.id === replaceID);

      if (replaceIndex > -1) {
        thisDateTimeUnits.splice(replaceIndex, 1, timeUnit);
        timeUnits.set(timeUnit.date, thisDateTimeUnits);
        state.timeUnits = new Map(timeUnits);
      }
    }
  },
  insertTimeUnit: (state, timeUnit) => {
    const { timeUnits } = state;
    let thisDateTimeUnits = timeUnits.get(timeUnit.date);

    // if we are adding unit to the start or in the middle of the day
    if (timeUnit.next_time_unit_id) {
      const insertIndex = thisDateTimeUnits.findIndex((unit) => unit.id === timeUnit.next_time_unit_id);

      thisDateTimeUnits.splice(insertIndex, 0, timeUnit);

      const previousTimeUnit = thisDateTimeUnits[insertIndex - 1];

      if (previousTimeUnit) {
        previousTimeUnit.next_time_unit_id = timeUnit.id;
      }

      timeUnits.set(timeUnit.date, thisDateTimeUnits);
      state.timeUnits = new Map(timeUnits);

      return;
    }

    if (thisDateTimeUnits) {
      thisDateTimeUnits.push(timeUnit);
    } else {
      thisDateTimeUnits = [timeUnit];
    }

    timeUnits.set(timeUnit.date, thisDateTimeUnits);
    state.timeUnits = new Map(timeUnits);
  },
  deleteTimeUnit: (state, { id, date }) => {
    const { timeUnits } = state;
    const thisDateTimeUnits = timeUnits.get(date);
    const thisDateTimeUnitsFiltered = thisDateTimeUnits.filter((unit) => unit.id !== id);

    timeUnits.set(date, thisDateTimeUnitsFiltered);
    state.timeUnits = new Map(timeUnits);
  },
  // #endregion

  // #region Bulk time unit mutations
  /**
   * In this mutation we mutate each object directly by reference
   * beacuse we need to keep time units order
   *
   * TODO: Come up with better solution and enable no-param-reassign
   */
  updateTimeUnitsStatuses: (state, { status, timeUnitsToUpdate, timeUnitsToUpdateIDs, extraData }) => {
    const { timeUnits } = state;
    const timeUnitsToUpdateMap = makeTimeUnitsMap(timeUnitsToUpdate);

    for (const date of timeUnitsToUpdateMap.keys()) {
      const thisDateTimeUnits = timeUnits.get(date);

      /* eslint-disable no-param-reassign */
      thisDateTimeUnits.forEach((timeUnit) => {
        if (timeUnitsToUpdateIDs.includes(timeUnit.id)) {
          timeUnit.is_time_unit_locked = status === timeUnitStatuses.submitted || status === timeUnitStatuses.approved;
          timeUnit.status = status;

          if (extraData) {
            if (timeUnit.overtime) {
              timeUnit.overtime_multiplier = extraData.overtime_multiplier;
              timeUnit.is_overtime_payable = extraData.is_overtime_payable;
            }

            timeUnit.is_billable = extraData.is_billable;

            if (extraData.assignment_id) {
              timeUnit.assignment_id = extraData.assignment_id;
            }
          }
        }
      });
      /* eslint-enable */

      timeUnits.set(date, thisDateTimeUnits);
    }
    state.timeUnits = new Map(timeUnits);
  },
  // #endregion

  setFilterSelectingMode: (state, newState) => (state.isFilterSelecting = newState),
};

/** @type {Module<TimesheetTimeUnitsState, RootState>} */
export const timeUnits = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
