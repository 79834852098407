import { CollectionsHelper } from '@/helpers/collections.helper';
import apiClient from '@/services/api-client';

const getInitialState = () => ({
  costCenters: [],
});

const state = getInitialState();

const getters = {
  costCenters: (state) => state.costCenters,
  costCentersForMatrixView: (state) => {
    const costCenters = state.costCenters.concat(state.costCenters);
    const sortedCostCentres = CollectionsHelper.sortObjectsByProperty(costCenters, 'title');

    return sortedCostCentres;
  },
};

const actions = {
  getCostCenters: async ({ commit }, { employeeId, officeId }) => {
    const { data } = await apiClient.costCentersApi.getCostCenters(employeeId, officeId);

    commit('setСostCenters', data);
  },
};

const mutations = {
  setСostCenters: (state, costCenters) => {
    state.costCenters = CollectionsHelper.sortObjectsByProperty(costCenters, 'title');
  },
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

export const main = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
