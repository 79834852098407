import { CollectionsHelper } from '@/helpers/collections.helper';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/employee-assignments.interface').EmployeeAssignments} EmployeeAssignments
 * @typedef {import('@/models/assignment.interface').AssignmentFull} AssignmentFull
 * @typedef {import('@/models/project.interface').ProjectFull} Project
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ProjectsMainState
 * @property {string | null} projectId
 * @property {Project | {}} project
 * @property {Project[]} projects
 * @property {AssignmentFull[]} projectAssignments
 * @property {boolean} isShowOnlyMyOffice
 * @property {boolean} isShowRemoved
 * @property {string} search
 * @property {EmployeeAssignments[]} employeeAssignments
 */

/** @returns {ProjectsMainState} */
const getInitialState = () => ({
  projectId: null,
  project: {},
  projects: [],
  projectAssignments: [],
  isShowOnlyMyOffice: true,
  isShowRemoved: false,
  search: '',
  employeeAssignments: [],
});

/** @type {ProjectsMainState} */
const state = getInitialState();

/** @type {GetterTree<ProjectsMainState, RootState>} */
const getters = {
  projectId: (state) => state.projectId,
  projects: (state) => state.projects,
  project: (state) => state.project,
  projectAssignments: (state) => state.projectAssignments,
  isShowOnlyMyOffice: (state) => state.isShowOnlyMyOffice,
  isShowRemoved: (state) => state.isShowRemoved,
  search: (state) => state.search,
  employeeAssignments: (state) => state.employeeAssignments,
};

/** @type {ActionTree<ProjectsMainState, RootState>} */
const actions = {
  getProjects: async ({ commit }, { employeeId, officeId }) => {
    const { data } = await apiClient.projectApi.getProjects(
      employeeId,
      officeId
    );

    commit('setProjects', data);
  },
  setProject: ({ commit }, project) => commit('setProject', project),
  getProjectById: async ({ commit }, { projectId }) => {
    const { data } = await apiClient.projectApi.getProjectById(projectId);

    commit('setProject', data);
  },
  createProject: async ({ commit }, data) => {
    const {
      data: { project_id },
    } = await apiClient.projectApi.createProject(data);

    commit('setProjectId', project_id);
  },
  updateProject: async ({ commit }, data) => {
    await apiClient.projectApi.changeProject(data.id, data);

    commit('setProject', data);
  },
  deleteProject: (_, projectId) => apiClient.projectApi.deleteProject(projectId),
  addProjectAssignment: (_, { projectId, assignment }) => apiClient.projectApi.addProjectAssignment(projectId, assignment),
  getProjectAssignments: async ({ commit }, { projectId, options }) => {
    const { data } = await apiClient.projectApi.getProjectAssignments(
      projectId,
      options
    );

    commit('setProjectAssignments', data);
  },
  updateProjectAssignment: async (_, { projectId, assignment }) => {
    await apiClient.projectApi.changeProjectAssignment(projectId, assignment);
  },
  setEmployeeProjectRole: (context, { projectId, employeeId, roleId }) => apiClient.projectApi.putEmployeeProjectRole(projectId, employeeId, roleId),
  removeEmployeeFromProject: (context, { projectId, employeeId, roleId }) => apiClient.projectApi.deleteProjectEmployee(projectId, employeeId, roleId),
  setIsShowOnlyMyOffice: ({ commit }, isShowOnlyMyOffice) => commit('setIsShowOnlyMyOffice', isShowOnlyMyOffice),
  setIsShowRemoved: ({ commit }, isShowRemoved) => commit('setIsShowRemoved', isShowRemoved),
  setSearch: ({ commit }, searchText) => commit('setSearch', searchText),
  clearProjectAssignments: ({ commit }) => commit('setProjectAssignments', []),
  getEmployeeAssignments: async ({ commit }, { employeeId, options }) => {
    const { data } = await apiClient.employeeApi.getEmployeeAssignments({
      employeeId,
      options,
    });

    commit('setEmployeeAssignments', data);
  },
};

/** @type {MutationTree<ProjectsMainState>} */
const mutations = {
  setProjectId: (state, data) => (state.projectId = data),
  setProject: (state, data) => (state.project = data),
  setProjects: (state, projects) => (state.projects = CollectionsHelper.sortObjectsByProperty(
    projects,
    'title'
  )),
  setProjectAssignments: (state, data) => (state.projectAssignments = data),
  setIsShowOnlyMyOffice: (
    state,
    isShowOnlyMyOffice = !state.isShowOnlyMyOffice
  ) => (state.isShowOnlyMyOffice = isShowOnlyMyOffice),
  setIsShowRemoved: (state, isShowRemoved = !state.isShowRemoved) => (state.isShowRemoved = isShowRemoved),
  setSearch: (state, searchText) => (state.search = searchText),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
  setEmployeeAssignments: (state, employeeAssignments) => (state.employeeAssignments = employeeAssignments),
};

/** @type {Module<ProjectsMainState, RootState>} */
export const main = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
