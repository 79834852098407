<template>
  <div class="message__container">
    <div class="message__inner">
      <p class="message__text">
        Available {{ messageText }}:
        <span :class="availableTime < 0 ? 'red--text' : 'green--text'">
          {{ availableTime }} hours
        </span>
      </p>
      <p class="message__text">
        Used {{ messageText }}: <span>{{ usedTime }} hours</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    availableTime: {
      type: Number,
      default: 0,
    },
    usedTime: {
      type: Number,
      default: 0,
    },
    messageText: {
      type: String,
      default: '',
    },
  },
};
</script>
<style>
  .message__container {
      position: absolute;
      top: 1px;
      left: 115px;
      z-index: 1;
      display: flex;
      justify-content: center;
      width: 245px;
      margin: 0;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 12px;
      background-color: #fff;
  }
  .message__inner {
      padding: 16px;
  }
  .message__inner .message__text {
    margin: 0;
    margin-bottom: 3px;
    font-weight: 500;
  }

</style>
