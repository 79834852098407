import { list } from './list';
import { single } from './single';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees.state').EmployeesState} EmployeesState
 */

/** @type {Module<EmployeesState, RootState>} */
export const employees = {
  namespaced: true,
  modules: {
    single,
    list,
  },
};
