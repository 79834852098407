/**
 * @typedef {import('@/services/api-client/types/role.api.types').ProjectRole} ProjectRole
 * @typedef {import('@/services/api-client/types/role.api.types').SystemRole} SystemRole
 */

/**
 * @typedef {Object} SharedRolesState
 * @property {ProjectRole[]} projectRoles
 * @property {SystemRole[]} systemRoles
 * @property {SystemRole[]} systemRolesICanSet
 */

/** @returns {SharedRolesState} */
export const getInitialSharedRolesState = () => ({
  projectRoles: [],
  systemRoles: [],
  systemRolesICanSet: [],
});
