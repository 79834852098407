/**
 * @typedef {import('../').ApiClient} ApiClient
 */

export class ApiBase {
  /**
   * @protected
   * @type {ApiClient}
   */
  apiClient;

  /** @param {ApiClient} apiClient */
  constructor(apiClient) {
    this.apiClient = apiClient;
  }
}
