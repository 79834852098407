import { list } from './list';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./holidays.state').HolidaysState} HolidaysState
 */

/** @type {Module<HolidaysState, RootState>} */
export const holidays = {
  namespaced: true,
  modules: {
    list,
  },
};
