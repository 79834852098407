/**
 * @typedef {import('node_modules/vuetify/types/index').DataTableHeader} DataTableHeader
 */

/** @type {DataTableHeader[]} */
const tableHeaders = [
  {
    text: '#',
    width: '1%',
    value: '',
    sortable: false,
  },
  {
    text: 'Task Type',
    value: 'task_type_title',
    class: 'report-unit-employee-table__header-type',
  },
  {
    text: 'Description',
    value: 'description',
  },
  {
    text: 'Time',
    value: 'minutes',
    class: 'report-unit-employee-table__header-time',
  },
  {
    text: 'Overtime',
    value: 'overtime',
    class: 'report-unit-employee-table__header-overtime',
  },
  {
    text: 'Overtime Payable',
    value: 'is_overtime_payable',
    class: 'report-unit-employee-table__header-overtime-payable',
  },
  {
    text: 'Overtime Multiplier',
    value: 'overtime_multiplier',
    class: 'report-unit-employee-table__header-overtime-multiplier',
  },
  {
    text: 'Billable',
    value: 'is_billable',
    class: 'report-unit-employee-table__header-billable',
  },
  {
    text: 'Date',
    value: 'logged_date',
    class: 'report-unit-employee-table__header-date',
  },
  {
    text: '',
    value: '',
    width: '120px',
  },
];

const tableHeadersSpecificReport = [
  'is_overtime_payable',
  'overtime_multiplier',
  'is_billable',
];

export { tableHeaders, tableHeadersSpecificReport };
