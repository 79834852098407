import { attachmentsErrors } from '@/constants/errorMessages';
import { FileSizeConverter } from '@/helpers/file-size-converter';
import { NotificationHelper } from '@/helpers/notification.helper';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/attachment.interface').Attachment} Attachment
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ReportsAttachmentsState
 * @property {Attachment[]} attachments
 */

/** @param {Attachment} file */
function FileObject(file) {
  const splitedName = file.attachment_name.split('.');

  this.id = file.id;
  [this.name] = splitedName;
  this.type = splitedName[splitedName.length - 1];
  this.size = FileSizeConverter.convertToKB(file.size);
  this.uploader = file.creator_name;
}

/** @returns {ReportsAttachmentsState} */
const getInitialState = () => ({
  attachments: [],
});

/** @type {ReportsAttachmentsState} */
const state = getInitialState();

/** @type {GetterTree<ReportsAttachmentsState, RootState>} */
const getters = {
  attachments: (state) => state.attachments,
};

/** @type {ActionTree<ReportsAttachmentsState, RootState>} */
const actions = {
  getAttachments: async ({ commit }, reportId) => {
    const { data } = await apiClient.reportApi.getAttachments(reportId);
    const attachments = data.map((el) => new FileObject(el));

    commit('setAttachments', attachments);
  },
  addAttachments: async ({ commit, dispatch }, { reportId, files, options }) => {
    const bodyFormData = new FormData();

    files.forEach((file) => {
      bodyFormData.append('files[]', file);
    });

    try {
      const {
        data: {
          attachments,
          not_uploaded,
        },
      } = await apiClient.reportApi.addAttachments(reportId, bodyFormData, options);
      const newAttachments = attachments.map((file) => new FileObject(file));

      if (not_uploaded && not_uploaded.length) {
        const notUploadedFilesList = not_uploaded.map(({ filename, reason }) => `\n${filename}: ${reason}`);

        NotificationHelper.showError(`${attachmentsErrors.NOT_UPLOADED}${notUploadedFilesList}`);
      }

      commit('addAttachment', newAttachments);
    } catch (e) {
      dispatch('getAttachments', reportId);
    }
  },
  deleteAttachments: ({ commit, getters, dispatch }, { reportId, attachmentsIds }) => {
    const { attachments } = getters;
    const undelitedAttachments = attachments.filter((at) => !attachmentsIds.includes(at.id));

    commit('setAttachments', undelitedAttachments);

    return apiClient.reportApi.deleteAttachments(reportId, attachmentsIds)
      .catch(() => dispatch('getAttachments', reportId));
  },
};

/** @type {MutationTree<ReportsAttachmentsState>} */
const mutations = {
  setAttachments: (state, data) => (state.attachments = data),
  addAttachment: (state, data) => (state.attachments = state.attachments.concat(data)),
};

/** @type {Module<ReportsAttachmentsState, RootState>} */
export const attachments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
