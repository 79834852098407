import moment from 'moment';

import { reportTypes } from '@/constants/report-types';
import { timePeriod } from '@/constants/timePeriod';
import { CollectionsHelper } from '@/helpers/collections.helper';
import { DateHelper } from '@/helpers/date.helper';
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} DateRange
 * @property {string} startDate
 * @property {string} endDate
 */

/**
 * @typedef {Object} ReportsFilters
 * @property {DateRange} dateRange
 * @property {string} projectName
 * @property {string[]} statuses
 * @property {string[]} types
 * @property {string[]} officesIds
 */

/**
 * @typedef {Object} ReportsFiltersState
 * @property {ReportsFilters} filters
 * @property {[]} reportsFiltered
 */

/** @returns {ReportsFiltersState} */
const getInitialState = () => ({
  filters: {
    dateRange: {
      startDate: DateHelper.toIso(moment().startOf(timePeriod.month).subtract(1, timePeriod.month)),
      endDate: DateHelper.toIso(moment().endOf(timePeriod.month)),
    },
    projectName: '',
    statuses: [],
    types: [],
    officesIds: [],
  },
  reportsFiltered: [],
});

/** @type {ReportsFiltersState} */
const state = getInitialState();

/** @type {GetterTree<ReportsFiltersState, RootState>} */
const getters = {
  dateRangeFilter: (state) => state.filters.dateRange,
  projectNameFilter: (state) => state.filters.projectName,
  statusFilter: (state) => state.filters.statuses,
  typeFilter: (state) => state.filters.types,
  reportsFiltered: (state) => state.reportsFiltered,
  officeFilter: (state) => state.filters.officesIds,
};

/** @type {ActionTree<ReportsFiltersState, RootState>} */
const actions = {
  getFilteredReports: async ({
    state, dispatch, commit, rootGetters,
  }, filter) => {
    const {
      statuses,
      types,
      officesIds,
      projectName,
      dateRange: {
        startDate,
        endDate,
      },
    } = state.filters;

    const isProjectFilter = filter === 'project-name-filter';
    const typesValue = types.map((type) => reportTypes.find((item) => item.text === type)?.value);
    let getReportsPromise = Promise.resolve();

    if (!isProjectFilter) {
      const reportsConfig = {
        projectId: '',
        statuses: statuses.length ? statuses : null,
        types: typesValue.length ? typesValue : null,
        officesIds,
        startDate,
        endDate,
      };

      getReportsPromise = dispatch('reports/main/getReports', reportsConfig, { root: true });
    }

    const isProjectFilterEmpty = projectName === '';

    if (!isProjectFilterEmpty || (isProjectFilterEmpty && isProjectFilter)) {
      await getReportsPromise;
      let { 'reports/main/reports': reports } = rootGetters;

      reports = CollectionsHelper.filterObjectsByStringProperty(reports, 'project_title', projectName);

      commit('setReportsFiltered', reports);

      return;
    }

    await getReportsPromise;

    commit('setReportsFiltered', rootGetters['reports/main/reports']);
  },

  changeCustomDateRange({ commit }, dateRange) {
    const newDateRange = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };

    commit('setDateRangeFilter', newDateRange);
  },
  moveDateRange({ commit }, { isForward, dateRange }) {
    /** @type {moment.DurationInputArg2} */
    let period = timePeriod.month;
    let periodAmount = 1;

    const newDateRange = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    if (newDateRange.startDate === DateHelper.toIso(moment(dateRange.startDate).startOf(period))
      && newDateRange.endDate === DateHelper.toIso(moment(dateRange.endDate).endOf(period))) {
      periodAmount = moment(dateRange.endDate).diff(moment(dateRange.startDate), period);
    } else {
      period = timePeriod.day;
      periodAmount = moment(dateRange.endDate).diff(moment(dateRange.startDate), period);
    }

    periodAmount += 1;

    // TODO: Extract following logic into helper, `moveDateRange` works almost the same in every filter module

    if (isForward) {
      newDateRange.startDate = moment(dateRange.startDate).add(periodAmount, period);
      newDateRange.endDate = period === timePeriod.month
        ? moment(newDateRange.startDate).add(periodAmount - 1, period).endOf(period)
        : moment(dateRange.endDate).add(periodAmount, period);
    } else {
      newDateRange.startDate = moment(dateRange.startDate).subtract(periodAmount, period);
      newDateRange.endDate = period === timePeriod.month
        ? moment(dateRange.endDate).subtract(periodAmount, period).endOf(period)
        : moment(dateRange.endDate).subtract(periodAmount, period);
    }

    newDateRange.startDate = DateHelper.toIso(newDateRange.startDate);
    newDateRange.endDate = DateHelper.toIso(newDateRange.endDate);

    commit('setDateRangeFilter', newDateRange);
  },
  setProjectNameFilter({ commit }, projectName) {
    commit('setProjectNameFilter', projectName);
  },
  setStatusFilter({ commit }, statuses) {
    commit('setStatusFilter', statuses);
  },
  setTypeFilter({ commit }, types) {
    commit('setTypeFilter', types);
  },
  setOfficesFilter({ commit }, officesIds) {
    commit('setOfficesFilter', officesIds);
  },
};

/** @type {MutationTree<ReportsFiltersState>} */
const mutations = {
  setDateRangeFilter: (state, data) => (state.filters.dateRange = data),
  setProjectNameFilter: (state, data) => (state.filters.projectName = data),
  setStatusFilter: (state, data) => (state.filters.statuses = data),
  setTypeFilter: (state, data) => (state.filters.types = data),
  setOfficesFilter: (state, data) => (state.filters.officesIds = data),
  setReportsFiltered: (state, data) => (state.reportsFiltered = data),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<ReportsFiltersState, RootState>} */
export const filters = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
