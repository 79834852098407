/**
 * @typedef {import("@/services/api-client/types/matrix-report.api.types").MatrixReport} MatrixReport
 */

/**
 * @typedef {Record<string, never>} EmptyMatrixReport
 */

/**
 * @typedef {Object} MatrixReportReportState
 * @property {MatrixReport | EmptyMatrixReport} matrixReport
 */

// TODO: replace `{}` with `null`
/** @returns {MatrixReportReportState} */
export const getMatrixReportReportInitialState = () => ({
  matrixReport: {},
});
