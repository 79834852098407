
import { attachments } from './attachments-module';
import { filters } from './filters-module';
import { main } from './main-module';
import { reportComments } from './report-comments-module';
import { summary } from './summary-module';
import { units } from './units-module';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./reports.state').ReportsState} ReportsState
 */

/** @type {Module<ReportsState, RootState>} */
export const reports = {
  namespaced: true,
  modules: {
    main,
    filters,
    units,
    reportComments,
    attachments,
    summary,
  },
};
