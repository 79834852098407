import { systemRolesNames, projectRoles } from '@/constants/roles';
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Getter<S, R>} Getter<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./auth-account.state').AuthAccountState} AuthAccountState
 */
/**
 * @typedef {Object} AuthAccountGetters
 * @property {(...params: Parameters<Getter<AuthAccountState, RootState>>) => boolean} isUserAdmin
 * @property {(...params: Parameters<Getter<AuthAccountState, RootState>>) => boolean} isUserOfficeDirector
 * @property {(...params: Parameters<Getter<AuthAccountState, RootState>>) => boolean} isUserOfficeViewer
 * @property {(...params: Parameters<Getter<AuthAccountState, RootState>>) => boolean} isUserEmployee
 * @property {(...params: Parameters<Getter<AuthAccountState, RootState>>) => boolean} isUserPV
 */

/** @type {AuthAccountGetters & GetterTree<AuthAccountState, RootState>} */
// TODO: Remove unnecessary getters - use mapState for `user` and `userProjects`
export const authAccountGetters = {
  user: (state) => state.user,
  userProjects: (state) => state.userProjects,
  // TODO: Replace ones below with lazy `hasSystemRole` getter
  isUserAdmin: (state) => Boolean(state.user && state.user.role === systemRolesNames.admin),
  isUserOfficeDirector: (state) => Boolean(state.user && state.user.role === systemRolesNames.officeDirector),
  isUserOfficeViewer: (state) => Boolean(state.user && state.user.role === systemRolesNames.officeViewer),
  isUserEmployee: (state) => Boolean(state.user && state.user.role === systemRolesNames.employee),
  isUserPV: (state) => {
    const pvProject = state.userProjects
      .find(({ assignments }) => assignments.find(({ role_id }) => role_id === projectRoles.projectViewer.id));

    return Boolean(pvProject);
  },
  isUserDM: (state) => {
    const dmProject = state.userProjects
      .find(({ assignments }) => assignments.find(({ role_id }) => role_id === projectRoles.deliveryManager.id));

    return Boolean(dmProject);
  },
};
