
import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 *
 * @typedef {import('../types/jira.api.types').JiraImportTimeUnitsParams} JiraImportTimeUnitsParams
 * @typedef {import('../types/jira.api.types').JiraImportTimeUnitsData} JiraImportTimeUnitsData
 * @typedef {import('../types/jira.api.types').JiraProject} JiraProject
 * @typedef {import('../types/jira.api.types').JiraCredentials} JiraCredentials
 * @typedef {import('../types/jira.api.types').AddJiraCredentialsParams} AddJiraCredentialsParams
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 */

export class JiraApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  JIRA_API_ROOT = 'jira';
  /**
   * @private
   * @readonly
   */
  JIRA_CONNECTION_TIMEOUT = 1000 * 5;

  /**
   * Get jira credentials.
   * Retrieves all credentials when server is not provided.
   * @param {string} [server] server URI
   * @returns {Promise<AxiosResponse<JiraCredentials[]>>} axios response with credentials
   */
  getCredentials = (server) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.JIRA_API_ROOT}/credentials`,
    params: {
      server,
    },
  });

  /**
   * Add jira credentials.
   * @param {AddJiraCredentialsParams} params - jira credentials
   * @returns {Promise<AxiosResponse<{ id: string }>>} axios response with id of added credential
   */
  addCredentials = (params) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.JIRA_API_ROOT}/credentials`,
    data: {
      server: params.server,
      connection_name: params.connectionName,
      login: params.login,
      api_token: params.apiToken,
    },
  });

  /**
   * Delete jira credentials.
   * @param {{ credentialsId: string }} params payload with credentials id
   * @returns {Promise<AxiosResponse<void>>} axios response
   */
  deleteCredentials = ({ credentialsId }) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.JIRA_API_ROOT}/credentials`,
    params: {
      jira_credentials_id: credentialsId,
    },
  });

  /**
   * Test new jira connection.
   * @param {AddJiraCredentialsParams} params - jira credentials
   * @returns {Promise<AxiosResponse<void>>} axios response with credentials
   */
  testNewConnection = (params) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.JIRA_API_ROOT}/test-connection`,
    timeout: this.JIRA_CONNECTION_TIMEOUT,
    data: {
      server: params.server,
      connection_name: params.connectionName,
      login: params.login,
      api_token: params.apiToken,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * Test existing jira connection.
   * @param {{ credentialsId: string }} params payload with credentials id
   * @returns {Promise<AxiosResponse<void>>} axios response with credentials
   */
  testExistingConnection = ({ credentialsId }) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.JIRA_API_ROOT}/test-connection`,
    timeout: this.JIRA_CONNECTION_TIMEOUT,
    params: {
      jira_credentials_id: credentialsId,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * Import time units from Jira work logs.
   * @param {JiraImportTimeUnitsParams} params
   * @returns {Promise<AxiosResponse<JiraImportTimeUnitsData>>} axios response with imported and not imported logs
   */
  importTimeUnits = (params) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.JIRA_API_ROOT}/import`,
    params: {
      jira_credentials_id: params.jiraCredentialsId,
      jira_project_key: params.jiraProjectKey,
      ets_project_id: params.etsProjectId,
      start_date: params.startDate,
      end_date: params.endDate,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * Get user's jira projects by connection id.
   * @param {{ jiraCredentialsId: string }} params
   * @returns {Promise<AxiosResponse<JiraProject[]>>} axios response with jira projects
   */
  getJiraProjects = (params) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.JIRA_API_ROOT}/user-projects`,
    params: {
      jira_credentials_id: params.jiraCredentialsId,
    },
  }, { loadingType: loadingTypes.none });
}
