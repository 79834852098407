/**
 * @typedef {import('@/models/project-status.interface').ProjectStatus} ProjectStatus
 */

export class ProjectStatusesHelper {
  /**
   * @param {ProjectStatus[]} statuses
   * @returns {ProjectStatus[]}
   */
  static filterDefaultStatuses(statuses) {
    return statuses.filter((status) => status.title !== 'Deleted' && status.title !== 'Finished');
  }
}
