/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./layout-menu.state').LayoutMenuState} LayoutMenuState
 */

/** @type {GetterTree<LayoutMenuState, RootState>} */
export const layoutMenuGetters = {
  showMenu: (state) => Boolean(state.allowedMenuItems && state.allowedMenuItems.length > 1),
  isMenuItemAllowedByName: (state) => (name) => {
    if (!state.allowedMenuItems) return false;

    const allowedItem = state.allowedMenuItems.find((item) => item.name === name);

    return Boolean(allowedItem);
  },
};
