import apiClient from '@/services/api-client';

/**
 * @template S, R
 * @typedef {import("vuex").ActionContext<S, R>} ActionContext<S, R>
 */
/**
 * @template T, C
 * @typedef {import("@/store/types/with-context").WithContext<T, C>} WithContext<T, C>
 */
/**
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').GetMatrixReportParams} GetMatrixReportParams
 *
 * @typedef {import("./matrix-report-report.state").MatrixReportReportState} MatrixReportReportState
 * @typedef {import("@/store/root.state").RootState} RootState
 */

/**
 * @typedef {Object} MatrixReportReportActions
 * @property {(params: GetMatrixReportParams) => Promise<void>} getMatrixReport
 */

/** @type {WithContext<MatrixReportReportActions, ActionContext<MatrixReportReportState, RootState>>} */
export const matrixReportReportActions = {
  getMatrixReport: async ({ commit }, params) => {
    const { data: matrixReport } = await apiClient.matrixReportApi.getMatrixReport(params);

    commit('setMatrixReport', matrixReport);
  },
};
