import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('@/models/time-unit.interface').TimeUnit} TimeUnit
 * @typedef {import('@/services/api-client/types/time-unit.api.types').CreateTimeUnitData} CreateTimeUnitData
 * @typedef {import('@/services/api-client/types/time-unit.api.types').CreateTimeUnitParams} CreateTimeUnitParams
 * @typedef {import('@/services/api-client/types/time-unit.api.types').ChangeTimeUnitParams} ChangeTimeUnitParams
 * @typedef {import('@/services/api-client/types/time-unit.api.types').GetTimeUnitsParams} GetTimeUnitsParams
 */

export class TimeUnitApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  TIME_UNITS_API_ROOT = 'time-units'
  ABSENCE_CALC = 'absence-calc'

  /**
   * @param {GetTimeUnitsParams} params
   * @returns {Promise<AxiosResponse<TimeUnit[]>>}
   */
  getTimeUnits = (params) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.TIME_UNITS_API_ROOT}/`,
    params,
  }, { loadingType: loadingTypes.none });

  /**
   * @returns {Promise<AxiosResponse<CreateTimeUnitData>>}
   */

  getLeavAbsenceHours = () => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.ABSENCE_CALC}/leav`,
  });

  getVacpAbsenceHours = () => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.ABSENCE_CALC}/vacp`,
  });

  /**
   * @param {CreateTimeUnitParams} timeUnit
   * @returns {Promise<AxiosResponse<CreateTimeUnitData>>}
   */
  createTimeUnit = (timeUnit) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.TIME_UNITS_API_ROOT}/`,
    data: timeUnit,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {ChangeTimeUnitParams} timeUnit
   * @returns {Promise<AxiosResponse<void>>}
   */
  changeTimeUnit = (timeUnit) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.TIME_UNITS_API_ROOT}/${timeUnit.id}`,
    data: timeUnit,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} status
   * @param {string[]} time_unit_ids
   * @param {string} extra_data
   * @returns {Promise<AxiosResponse<void>>}
   */
  updateTimeUnitsStatuses = (status, time_unit_ids, extra_data) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.TIME_UNITS_API_ROOT}/status/${status}`,
    data: {
      time_unit_ids,
      extra_data,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} timeUnitId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteTimeUnit = (timeUnitId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.TIME_UNITS_API_ROOT}/${timeUnitId}`,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string[]} timeUnitsIds
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteBatchTimeUnits = (timeUnitsIds) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.TIME_UNITS_API_ROOT}/batch`,
    data: {
      time_unit_ids: timeUnitsIds,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * @param {GetTimeUnitsParams & {include_logo: boolean}} params
   * @returns {Promise<AxiosResponse<void>>}
   */
  exportToExcel = (params) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.TIME_UNITS_API_ROOT}/toexcel`,
    params,
    responseType: 'arraybuffer',
  }, { loadingType: loadingTypes.timesheetExcelTools });

  /**
   * @param {FormData} bodyFormData
   * @returns {Promise<AxiosResponse<void>>}
   */
  importFromExcel = (bodyFormData) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.TIME_UNITS_API_ROOT}/fromexcel`,
    data: bodyFormData,
  }, { loadingType: loadingTypes.timesheetExcelTools });

  /**
   * @returns {Promise<AxiosResponse<void>>}
   */
  downloadExcel = () => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.TIME_UNITS_API_ROOT}/downloadexcel`,
    responseType: 'arraybuffer',
  }, { loadingType: loadingTypes.timesheetExcelTools });
}
