import { holidaysListActions } from './holidays-list.actions';
import { holidaysListGetters } from './holidays-list.getters';
import { holidaysListMutations } from './holidays-list.mutations';
import { getHolidaysListInitalState } from './holidays-list.state';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./holidays-list.state').HolidaysListState} HolidaysListState
 */

/** @type {Module<HolidaysListState, RootState>} */
export const list = {
  namespaced: true,
  state: getHolidaysListInitalState(),
  getters: holidaysListGetters,
  actions: holidaysListActions,
  mutations: holidaysListMutations,
};
