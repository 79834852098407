/**
 * @typedef {import('@/models/route-extension.interface').RouteExtension} RouteExtension
 * @typedef {import('@/models/user.interface').User} User
 */

/**
 * @typedef {Object} LayoutMenuState
 * @property {RouteExtension[] | null} allowedMenuItems
 */

/** @returns {LayoutMenuState} */
export const getLayoutMenuInitialState = () => ({
  allowedMenuItems: null,
});
