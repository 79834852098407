import axios from 'axios';
import jwt from 'jsonwebtoken';
import Vue from 'vue';

import { LocalStorageHelper } from '@/helpers/local-storage.helper';
import { NotificationHelper } from '@/helpers/notification.helper';
import { paramsSerializer } from '@/helpers/params-serializer';
import router from '@/router';
import Progress from '@/services/progress';

import {
  AuthApi,
  CostCentersApi,
  EmployeeApi,
  MatrixByCostCenterReportApi,
  MatrixReportApi,
  OfficeApi,
  PermissionsApi,
  ProjectApi,
  ReportApi,
  ReportCommentsApi,
  ReportSummaryApi,
  ReportUnitApi,
  RoleApi,
  SyncApi,
  TaskTypeApi,
  TimeUnitApi,
  UserApi,
  JiraApi,
  HolidayApi,
  ProxyApi,
} from './api';
/**
 * @template T
 * @typedef {import('axios').AxiosResponse<T>} AxiosResponse<T>
 */

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 * @typedef {import('axios').AxiosError} AxiosError
 *
 * @typedef {import('./types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 * @typedef {import('./types/decoded-refresh-token.interface').DecodedRefreshToken} DecodedRefreshToken
 */

export class ApiClient {
  /**
   * @private
   * @type {AxiosInstance}
   */
  axios;
  /** @type {string | null} */
  accessToken;

  constructor() {
    this.authApi = new AuthApi(this);
    this.costCentersApi = new CostCentersApi(this);
    this.employeeApi = new EmployeeApi(this);
    this.matrixByCostCenterReportApi = new MatrixByCostCenterReportApi(this);
    this.matrixReportApi = new MatrixReportApi(this);
    this.officeApi = new OfficeApi(this);
    this.permissionsApi = new PermissionsApi(this);
    this.projectApi = new ProjectApi(this);
    this.reportApi = new ReportApi(this);
    this.reportCommentsApi = new ReportCommentsApi(this);
    this.reportSummaryApi = new ReportSummaryApi(this);
    this.reportUnitApi = new ReportUnitApi(this);
    this.roleApi = new RoleApi(this);
    this.syncApi = new SyncApi(this);
    this.taskTypeApi = new TaskTypeApi(this);
    this.timeUnitApi = new TimeUnitApi(this);
    this.userApi = new UserApi(this);
    this.jiraApi = new JiraApi(this);
    this.holidayApi = new HolidayApi(this);
    this.ProxyApi = new ProxyApi(this);

    this.accessToken = null;

    this.axios = axios.create({
      paramsSerializer,
      baseURL: this.getBaseUrl(),
      headers: {
        post: {
          'Content-Type': 'application/json',
        },
      },
    });

    /**
     * Global error handler,
     * any unhandled error goes here
     * @param {Error | AxiosError} error
     */
    Vue.config.errorHandler = async (error) => {
      // Any uncaught network error
      if ('request' in error) {
        const errorMessage = await this.formatError(error);

        if (errorMessage) {
          NotificationHelper.showError(errorMessage);
        }

        return;
      }

      throw error;
    };
  }

  async silentAuthorization() {
    const refresh = LocalStorageHelper.getRefreshToken();

    this.accessToken = null;

    try {
      const {
        data: {
          refresh_token: refreshToken,
          access_token: accessToken,
        },
      } = await this.authApi.getTokens(refresh);

      LocalStorageHelper.setRefreshToken(refreshToken);

      this.accessToken = accessToken;
    } catch (error) {
      await router.push({ name: 'logout' });
    }
  }

  /**
   * @template T
   * @param {AxiosRequestConfig} requestConfig
   * @param {AdditionalRequestOptions} additionalOptions
   * @returns {Promise<AxiosResponse<T>>} axios response
   */
  async sendRequest(
    requestConfig,
    additionalOptions = { loadingType: 'bar' },
  ) {
    try {
      Progress.notify(additionalOptions.loadingType, 'pending');

      const request = requestConfig;

      if (this.accessToken) {
        const now = Date.now() / 1000;
        const { exp } = /** @type {DecodedRefreshToken} */(jwt.decode(this.accessToken));

        const isExpired = exp - now <= 0;

        if (isExpired) {
          await this.silentAuthorization();
        }

        request.headers = {
          Authorization: `Bearer ${this.accessToken}`,
          ...request.headers,
        };
      }

      const response = await this.axios.request(request);

      return response;
    } finally {
      Progress.notify(additionalOptions.loadingType, 'finish');
    }
  }

  /** @private */
  formatError = async (error) => {
    let errorMessage = 'Something went wrong.';

    const { response } = error;

    if (response) {
      let responseMessage = '';
      let receivedData = response.data;

      if (receivedData) {
        if (response.request.responseType === 'blob') {
          receivedData = await receivedData.arrayBuffer();
        }

        if (typeof receivedData === 'string') {
          responseMessage = receivedData.trim() || '';
        } else if (receivedData instanceof ArrayBuffer) {
          responseMessage = new TextDecoder().decode(receivedData);
        } else {
          responseMessage = receivedData.message;
          responseMessage = (responseMessage && typeof responseMessage === 'string') ? responseMessage.trim() : '';
        }
      }

      // if more than 200 then it is probably exception
      if (responseMessage.length > 0 && responseMessage.length < 200) {
        errorMessage = responseMessage;
      } else { // standard message
        errorMessage = `Something went wrong(${response.status})`;
      }
    }

    return errorMessage;
  }

  getBaseUrl = () => {
    switch (process.env.VUE_APP_ENV_TYPE) {
      case 'staging':
        return 'https://ets-api-dev.inyar.ru/api';
      case 'demo':
        return 'https://ets-api-demo.inyar.ru/api';
      case 'docker':
        return 'X_BASE_URL_PLACEHOLDER_X';
      default: // local api server url
        return 'https://ets-api.raftds.com/api';
    }
  }
}

export default new ApiClient();
