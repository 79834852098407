/**
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraImportTimeUnitsData} JiraImportTimeUnitsData
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraProject} JiraProject
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraCredentials} JiraCredentials
 */

/**
 * @typedef {Object} UserJiraState
 * @property {JiraCredentials[]} jiraCredentials
 * @property {JiraImportTimeUnitsData | null} importResult
 * @property {JiraProject[]} jiraProjects
 */

/**
 * @returns {UserJiraState} user jira state
 */
export const getUserJiraInitialState = () => ({
  jiraCredentials: [],
  importResult: null,
  jiraProjects: [],
});
