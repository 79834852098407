/**
 * @template S, R
 * @typedef {import("vuex").Getter<S, R>} Getter<S, R>
 */
/**
 * @typedef {import("@/services/api-client/types/matrix-report.api.types").MatrixReport} MatrixReport
 *
 * @typedef {import("./matrix-report-report.state").MatrixReportReportState} MatrixReportReportState
 * @typedef {import("@/store/root.state").RootState} RootState
 */

/* eslint-disable max-len */
/**
 * @typedef {Object} MatrixReportReportGetters
 * @property {(...params: Parameters<Getter<MatrixReportReportState, RootState>>) => MatrixReportReportState} matrixReport
 */
/* eslint-enable */

/** @type {MatrixReportReportGetters} */
export const matrixReportReportGetters = {
  // TODO: Replace this getter with `mapState`
  matrixReport: (state) => state.matrixReport,
};
