import apiClient from '@/services/api-client';

/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./holidays-list.state').HolidaysListState} HolidaysListState
 */

/** @type {ActionTree<HolidaysListState, RootState>} */
export const holidaysListActions = {
  getHolidays: async ({ commit }) => {
    const { data } = await apiClient.holidayApi.getHolidays();

    commit('setHolidays', data);
  },
};
