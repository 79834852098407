import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('../types/role.api.types').ProjectRole} ProjectRole
 * @typedef {import('../types/role.api.types').SystemRole} SystemRole
 */

export class RoleApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  ROLES_API_ROOT = 'roles'

  /** @returns {Promise<AxiosResponse<SystemRole[]>>} */
  getSystemRoles = () => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.ROLES_API_ROOT}/system-roles`,
  });

  /** @returns {Promise<AxiosResponse<SystemRole[]>>} */
  getSystemRolesICanSet = () => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.ROLES_API_ROOT}/system-roles/i-can-set`,
  });

  /** @returns {Promise<AxiosResponse<ProjectRole[]>>} */
  getProjectRoles = () => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.ROLES_API_ROOT}/project-roles`,
  });
}
