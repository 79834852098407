import { employeesSingleActions } from './employees-single.actions';
import { employeesSingleGetters } from './employees-single.getters';
import { employeesSingleMutations } from './employees-single.mutations';
import { getEmployeesSingleInitialState } from './employees-single.state';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees-single.state').EmployeesSingleState} EmployeesSingleState
 */

/** @type {Module<EmployeesSingleState, RootState>} */
export const single = {
  namespaced: true,
  state: getEmployeesSingleInitialState(),
  getters: employeesSingleGetters,
  actions: employeesSingleActions,
  mutations: employeesSingleMutations,
};
