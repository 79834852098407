import { sharedRolesActions } from './shared-roles.actions';
import { sharedRolesGetters } from './shared-roles.getters';
import { sharedRolesMutations } from './shared-roles.mutations';
import { getInitialSharedRolesState } from './shared-roles.state';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./shared-roles.state').SharedRolesState} SharedRolesState
 */

/** @type {Module<SharedRolesState, RootState>} */
export const roles = {
  namespaced: true,
  state: getInitialSharedRolesState(),
  getters: sharedRolesGetters,
  actions: sharedRolesActions,
  mutations: sharedRolesMutations,
};
