import { userJiraActions } from './user-jira.actions';
import { userJiraMutations } from './user-jira.mutations';
import { getUserJiraInitialState } from './user-jira.state';

export const jira = {
  namespaced: true,
  state: getUserJiraInitialState(),
  actions: userJiraActions,
  mutations: userJiraMutations,
};
