<template>
  <flash-message
    transition-name="flash"
    class="flashpool"
  />
</template>

<script>
export default {
  name: 'NotificationsPool',
};
</script>

<style lang="less">
  .flashpool {
    box-sizing: border-box;
    position: fixed;
    z-index: 1000;
    top: 20px;
    right: 20px;
    max-height: 400px;
    width: 260px;
    perspective: 400px;

    & .flash__message {
      width: 260px;
      display: flex;
      font-family: 'Oxygen', sans-serif;
      font-size: 13px;
      line-height: 130%;

      &.error {
        color: #a94442;
        background-color: #f2dede !important;
        border-color: #ebccd1 !important;
      }

      &.success {
        background-color: #def2de !important;
      }
    }
  }
</style>
