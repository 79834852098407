/**
 * @typedef {import('@/models/employee-status.interface').EmployeeStatus} EmployeeStatus
 * @typedef {import('@/models/employee.interface').Employee} Employee
 */

export class EmployeeStatusesHelper {
  /**
   * Makes new array of employees' unique statuses
   * with the same id and title
   *
   * @param {Employee[]} employees
   * @returns {EmployeeStatus[]} employee statuses
   * @example
   * // returns [
   * //  { id: 'active', title: 'active' },
   * //  { id: 'deleted', title: 'deleted' },
   * //  { id: 'some', title: 'some' },
   * // ]
   * getStatusesFromEmployees([
   *  { ..., status: 'active' },
   *  { ..., status: 'deleted' },
   *  { ..., status: 'deleted' },
   *  { ..., status: 'some' },
   *  { ..., status: 'active' },
   * ]);
   */
  static getStatusesFromEmployees(employees) {
    if (!employees.length) return [];

    /* eslint-disable no-param-reassign */
    /** @type {Record<string, EmployeeStatus>} */
    const statusesMap = employees.reduce((statusesMap, { status }) => {
      if (status in statusesMap) return statusesMap;

      statusesMap[status] = { id: status, title: status };

      return statusesMap;
    }, {});
    /* eslint-enable */

    return Object.values(statusesMap);
  }

  /**
   * @param {EmployeeStatus[]} statuses
   * @returns {EmployeeStatus[]} employee statuses
   */
  static filterDefaultStatuses(statuses) {
    return statuses.filter((status) => status.title !== 'Former employee'
      && status.title !== 'Disabled'
      && status.title !== 'Deleted');
  }
}
