import { getEmployeesSingleInitialState } from './employees-single.state';
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @typedef {import('./employees-single.state').EmployeesSingleState} EmployeesSingleState
 */

/** @type {MutationTree<EmployeesSingleState>} */
export const employeesSingleMutations = {
  setEmployee: (state, employee) => state.employee = employee,
  setSchedule: (state, schedule) => state.schedule = schedule,

  resetModuleState: (state) => Object.assign(state, getEmployeesSingleInitialState()),
};
