<template>
  <v-app-bar
    app
    dark
    color="secondary"
    clipped-left
    height="56"
  >
    <v-app-bar-nav-icon
      v-if="showMenu"
      @click="toggleSidebar"
    />
    <RaftLogo />
    <v-spacer />
    <v-row justify="end">
      <UserMenu />
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RaftLogo from './RaftLogo.vue';
import UserMenu from './UserMenu.vue';

export default {
  components: {
    RaftLogo,
    UserMenu,
  },
  computed: {
    ...mapGetters('layout/menu', ['showMenu']),
  },
  methods: {
    ...mapActions('shared/common', ['toggleSidebar']),
  },
};
</script>
