<template>
  <v-col
    class="details-column"
    :class="isCollapsible ? 'clickable' : ''"
    @click="toggle"
  >
    <span
      v-if="!isVisible"
      class="grey--text"
    >Details...</span>
    <v-row v-else>
      <template v-for="(column, index) of detailsColumns">
        <div :key="`${column.title}__col`">
          <span v-if="column.title">{{ column.title.toLocaleUpperCase() }}</span>
          <template v-for="detail of column.details">
            <v-row
              v-if="detail.value"
              :key="detail.title"
            >
              <span class="grey--text">{{ `${detail.title}:` }}</span>
              <span class="ml-1">{{ detail.value }}</span>
            </v-row>
          </template>
        </div>

        <v-divider
          v-if="index < detailsColumns.length - 1"
          :key="`${column.title}__divider`"
          vertical
          inset
          class="mx-4"
        />
      </template>
    </v-row>
  </v-col>
</template>

<script>
/**
 * @typedef {Object} Detail
 * @property {string} title
 * @property {string} value
 */
/**
 * @typedef {Object} DetailsEntry
 * @property {string} title
 * @property {Detail[]} details
 */

export default {
  props: {
    /** @type {import('vue').PropOptions<DetailsEntry[]>} */
    detailsColumns: {
      type: Array,
      required: true,
    },
    isCollapsible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    toggle() {
      if (this.isCollapsible) {
        this.isVisible = !this.isVisible;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~variables';
.full-width-column {
  width: 100%;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: @white-smoke;
  }
}
</style>
