import { LocaleFormat } from '@/helpers/date.helper';
import { LocalStorageHelper } from '@/helpers/local-storage.helper';

export const getLanguage = () => {
  const language = (navigator.language || navigator.languages[0]).split('-')[0];

  return language !== 'en' ? LocaleFormat.EU : LocaleFormat.EN;
};

/*
 * just add new property in a similar way.
 * Use propertyName to access to the getter in other system parts
 * Known types are strings: 'boolean', 'number', 'string' (default)
 */
const settingsList = [
  {
    label: 'Dense Tables',
    propertyName: 'denseTables',
    type: 'boolean',
    default: true,
  },
  {
    label: 'Action buttons on date picker',
    propertyName: 'datePickerButtons',
    type: 'boolean',
    default: true,
  },
  {
    label: 'Date Format Settings',
    propertyName: 'dateFormatSettings',
    type: 'radioGroup',
    locales: [
      {
        name: LocaleFormat.EU === getLanguage() ? 'EU (default)' : 'EU',
        value: LocaleFormat.EU,
      },
      {
        name: LocaleFormat.EN === getLanguage() ? 'US (default)' : 'US',
        value: LocaleFormat.EN,
      },
    ],
    default: getLanguage(),
  },
];

const storedSettings = LocalStorageHelper.getUserSettings() || {};

const getInitialState = () => Object.assign(...settingsList.map((s) => ({
  [s.propertyName]: storedSettings[s.propertyName] !== null
    && typeof storedSettings[s.propertyName] !== 'undefined' ? storedSettings[s.propertyName] : s.default,
})));

const state = getInitialState();

const getters = Object.assign(...settingsList.map(
  (s) => ({ [s.propertyName]: (state) => state[s.propertyName] })
));

const actions = {
  getSettings: ({ commit, state }) => settingsList.map((s) => ({
    label: s.label,
    type: s.type,
    locales: s.locales,
    value: state[s.propertyName],
    onChange: (value) => commit('setSettingsValue', { name: s.propertyName, value }),
  })),
};

const mutations = {
  setSettingsValue: (state, { name, value }) => {
    state[name] = value;
    LocalStorageHelper.setUserSettings(state);
  },
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

export const settings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
