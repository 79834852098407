import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

/**
 * @typedef {import('../types/task-type.api.types').CreateTaskType} CreateTaskType
 * @typedef {import('../types/task-type.api.types').ProjectsTaskTypes} ProjectsTaskTypes
 */

export class TaskTypeApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  TASK_TYPE_API_ROOT = 'task-types'

  /**
   * @param {Record<string, string>} taskTypeTitle
   * @returns {Promise<AxiosResponse<CreateTaskType>>}
   */
  createTaskType = (taskTypeTitle) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.TASK_TYPE_API_ROOT}/`,
    data: taskTypeTitle,
  });

  /**
   * @param {string[]} projectIds
   * @returns {Promise<AxiosResponse<ProjectsTaskTypes>>}
   */
  getProjectsTaskTypes = (projectIds) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.TASK_TYPE_API_ROOT}/`,
    data: {
      project_ids: projectIds,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} projectId
   * @param {string} taskTypeId
   * @returns {Promise<AxiosResponse<void>>}
   */
  addTaskTypeToProject = (projectId, taskTypeId) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `projects/${projectId}/task-types/${taskTypeId}`,
  });

  /**
   * @param {string} projectId
   * @param {string} taskTypeId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteProjectsTaskTypes = (projectId, taskTypeId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `projects/${projectId}/task-types/${taskTypeId}`,
  });
}
