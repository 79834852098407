import { ApiBase } from './api-base';

export class CostCentersApi extends ApiBase {
  getCostCenters = (employeeId, officeId) => this.apiClient.sendRequest({
    method: 'GET',
    url: 'projects/clients',
    params: {
      employee_id: employeeId,
      office_id: officeId,
    },
  });
}
