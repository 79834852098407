export const projectStatuses = {
  deleted: 'Deleted',
  finished: 'Finished',
  active: 'Active',
};

export const projectStatusFilterValues = [
  {
    text: 'Active',
    value: 'Active',
    isDefault: true,
  },
  {
    text: 'Finished',
    value: 'Finished',
  },
  {
    text: 'Deleted',
    value: 'Deleted',
  },
];

