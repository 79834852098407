import { matrixReportReportActions } from './matrix-report-report.actions';
import { matrixReportReportGetters } from './matrix-report-report.getters';
import { matrixReportReportMutations } from './matrix-report-report.mutations';
import { getMatrixReportReportInitialState } from './matrix-report-report.state';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./matrix-report-report.state').MatrixReportReportState} MatrixReportReportState
 */

/** @type {Module<MatrixReportReportState, RootState>} */
export const report = {
  namespaced: true,
  state: getMatrixReportReportInitialState(),
  getters: matrixReportReportGetters,
  actions: matrixReportReportActions,
  mutations: matrixReportReportMutations,
};
