/**
 * @typedef {import('@/models/holiday.interface').Holiday} Holiday
 */

/**
 * @typedef {Object} HolidaysListState
 * @property {Holiday[]} holidays
 */

/** @returns {HolidaysListState} */
export const getHolidaysListInitalState = () => ({
  holidays: [],
});
