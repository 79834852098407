<template>
  <div>
    <Sidebar />
    <Toolbar />
    <v-main>
      <v-container
        class="px-0 primary-background"
        fluid
      >
        <ProgressActivate
          v-slot="{ isLoading }"
          class="progress-linear-width"
          :action-names="['bar']"
        >
          <v-progress-linear
            class="mt-0"
            indeterminate
            height="5"
            :style="{ visibility: isLoading ? 'visible' : 'hidden' }"
          />
        </ProgressActivate>
      </v-container>
      <v-container
        class="px-0"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { ProgressActivate } from '@/components/shared';

const Sidebar = () => import(
  /* webpackChunkName: "Sidebar" */
  '@/components/layout/Sidebar.vue'
);
const Toolbar = () => import(
  /* webpackChunkName: "Toolbar" */
  '@/components/layout/Toolbar.vue'
);

export default {
  components: {
    Sidebar,
    Toolbar,
    ProgressActivate,
  },
};
</script>

<style>
  .container.container--fluid {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .progress-linear-width {
    width: auto;
  }
</style>
