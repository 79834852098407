import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('../types/project.api.types').AddProjectAssignment} AddProjectAssignment
 * @typedef {import('../types/project.api.types').CreateProjectData} CreateProjectData
 * @typedef {import('../types/project.api.types').UpdateProjectParams} UpdateProjectParams
 * @typedef {import('../types/project.api.types').CreateProjectParams} CreateProjectParams
 * @typedef {import('@/models/project.interface').ProjectFull} Project
 * @typedef {import('@/models/project.interface').SeparateProject} SeparateProject
 * @typedef {import('@/models/assignment.interface').AssignmentFull} ProjectAssignment
 * @typedef {import('@/models/assignment.interface').Settings} Settings
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 */

export class ProjectApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  PROJECTS_API_ROOT = 'projects';

  /**
   * @param {string} employeeId
   * @param {string} officeId
   * @returns {Promise<AxiosResponse<Project[]>>}
   */
  getProjects = (employeeId, officeId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.PROJECTS_API_ROOT}/`,
    params: {
      employee_id: employeeId,
      office_id: officeId,
    },
  });

  /**
   * @param {string} employeeId
   * @param {string} officeId
   * @returns {Promise<AxiosResponse<Project[]>>}
   */
  getProjectsByViewer = (employeeId, officeId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.PROJECTS_API_ROOT}/by-access/view-project-details`,
    params: {
      employee_id: employeeId,
      office_id: officeId,
    },
  });

  /**
   * @param {string} projectId
   * @returns {Promise<AxiosResponse<SeparateProject>>}
   */
  getProjectById = (projectId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.PROJECTS_API_ROOT}/${projectId}`,
  }, { loadingType: loadingTypes.syncProjectButton });

  /**
   * @param {CreateProjectParams} project
   * @returns {Promise<AxiosResponse<CreateProjectData>>}
   */
  createProject = (project) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.PROJECTS_API_ROOT}/`,
    data: project,
  });

  /**
   * @param {UpdateProjectParams} project
   * @returns {Promise<AxiosResponse<void>>}
   */
  changeProject = (projectId, project) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.PROJECTS_API_ROOT}/${projectId}`,
    data: project,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} projectId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteProject = (projectId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.PROJECTS_API_ROOT}/${projectId}`,
  });

  /**
   * @param {string} projectId
   * @param {AddProjectAssignment} assignment
   * @returns {Promise<AxiosResponse<void>>}
   */
  addProjectAssignment = (projectId, assignment) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.PROJECTS_API_ROOT}/${projectId}/employees/`,
    data: assignment,
  });

  /**
   * @param {string} projectId
   * @param {AddProjectAssignment} assignment
   * @returns {Promise<AxiosResponse<void>>}
   */
  changeProjectAssignment = (projectId, assignment) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.PROJECTS_API_ROOT}/${projectId}/employees/`,
    data: assignment,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} employeeId
   * @param {string} projectId
   * @param {string} roleId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteProjectEmployee = (projectId, employeeId, roleId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.PROJECTS_API_ROOT}/${projectId}/employees/${employeeId}/roles/${roleId}/`,
  });

  /**
   * @param {string} employeeId
   * @param {string} projectId
   * @param {string} roleId
   * @returns {Promise<AxiosResponse<void>>}
   */
  putEmployeeProjectRole = (projectId, employeeId, roleId) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.PROJECTS_API_ROOT}/${projectId}/employees/${employeeId}/roles/${roleId}/`,
  });

  /**
   * @param {string} projectId
   * @param {AdditionalRequestOptions} [options]
   * @returns {Promise<AxiosResponse<ProjectAssignment>>}
   */
  getProjectAssignments = (projectId, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.PROJECTS_API_ROOT}/${projectId}/assignments/`,
  }, options);

  /**
   * @param {string} projectId
   * @param {string} assignmentId
   * @param {Settings | Record<string, string>} settings
   * @returns {Promise<AxiosResponse<void>>}
   */
  setProjectAssignmentSettings = (projectId, assignmentId, settings) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.PROJECTS_API_ROOT}/${projectId}/assignment/${assignmentId}/settings/`,
    data: settings,
  });
}
