
/**
 * Builds user initials in case if they are available. Prints N/A otherwise.
 *
 * @param {string} fullName User's full name
 * @returns {string} User's initials
 *
 * @example
 * getInitials('John Doe') // returns 'JD'
 */
export const getInitials = (fullName) => (fullName
  ? fullName.split(' ').map(([firstLetter]) => (firstLetter ? firstLetter.toLocaleUpperCase() : null)).join('')
  : 'N/A');
