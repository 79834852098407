import apiClient from '@/services/api-client';
/**
 * @typedef {import("@/services/api-client/types/matrix-report.api.types").MatrixReport} MatrixReport
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Record<string, never>} EmptyMatrixReport
 */

/**
 * @typedef {Object} MatrixByCostCenterReportState
 * @property {MatrixReport | EmptyMatrixReport} matrixReport
 */

/** @returns {MatrixByCostCenterReportState} */
const getInitialState = () => ({
  matrixReport: {},
});

/** @type {MatrixByCostCenterReportState} */
const state = getInitialState();

/** @type {GetterTree<MatrixByCostCenterReportState, RootState>} */
const getters = {
  matrixReport: (state) => state.matrixReport,
};

/** @type {ActionTree<MatrixByCostCenterReportState, RootState>} */
const actions = {
  getMatrixReport: async ({ commit }, params) => {
    const { data } = await apiClient.matrixByCostCenterReportApi
      .getMatrixReport(params.startDate, params.endDate, params.isBillableOnlyShown);

    commit('setMatrixReport', data);
  },
};

/** @type {MutationTree<MatrixByCostCenterReportState>} */
const mutations = {
  setMatrixReport: (state, data) => (state.matrixReport = data),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<MatrixByCostCenterReportState, RootState>} */
export const report = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
