import Vue from 'vue';
import Vuetify from 'vuetify';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#003DDC',
        secondary: '#424242',
      },
    },
  },
});
