import { LocalStorageHelper } from '@/helpers/local-storage.helper';
import apiClient from '@/services/api-client';
import store from '@/store';
/**
 * @typedef {import('vue-router').NavigationGuard} NavigationGuard
 */

/** @type {NavigationGuard} */
export const routeGuard = async (to, from, next) => {
  // TODO: implement chain of responsibility pattern

  if (to.meta.requiresAuth) {
    if (!LocalStorageHelper.getRefreshToken()) {
      return next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    }

    await apiClient.silentAuthorization();

    if (!apiClient.accessToken) {
      return next('/');
    }

    const { allowedMenuItems } = store.state.layout.menu;

    if (!allowedMenuItems) {
      try {
        await store.dispatch('layout/menu/loadAllowedMenuItems');
      } catch {
        return next('/');
      }
    }

    if (to.meta.permissionType) {
      const { 'layout/menu/isMenuItemAllowedByName': isMenuItemAllowedByName } = store.getters;

      if (isMenuItemAllowedByName(to.meta.permissionType)) {
        return next();
      }

      return next('/');
    }

    return next();
  }

  return next();
};
