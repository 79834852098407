import { LocalStorageHelper } from '@/helpers/local-storage.helper';
/**
 * @typedef {import('@/models/project.interface').Project} Project
 * @typedef {import('@/models/user.interface').User} User
 */

/**
 * @typedef {Object} AuthAccountState
 * @property {'success' | 'error' | 'loading' | null} status
 * @property {User | null} user
 * @property {Project[]} userProjects
 */

/** @returns {AuthAccountState} */
export const getAuthAccountInitialState = () => ({
  status: null,
  user: LocalStorageHelper.getUser(),
  userProjects: [],
});
