import moment from 'moment';

import { timePeriod } from '@/constants/timePeriod';
import { CollectionsHelper } from '@/helpers/collections.helper';
import { DateHelper, LocaleFormat } from '@/helpers/date.helper';
import { LocalStorageHelper } from '@/helpers/local-storage.helper';
import apiClient from '@/services/api-client';

import { getLanguage } from '../user';
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} DateRangeFilter
 * @property {string} startDate
 * @property {string} endDate
 */

/**
 * @typedef {Object} ProjectFilter
 * @property {string} title
 * @property {string | null} id
 */

/**
 * @typedef {Object} EmployeeFilter
 * @property {string} name
 * @property {string | null} id
 */

/**
 * @typedef {Object} Filters
 * @property {DateRangeFilter} dateRangeFilter
 * @property {string} timeSpanFilter
 * @property {ProjectFilter} projectFilter
 * @property {EmployeeFilter} employeeFilter
 * @property {string} statusFilter
 */

/**
 * @typedef {Object} Projects
 * @property {DateRangeFilter} billable_status
 * @property {string} position
 * @property {ProjectFilter} project_id
 */

/**
 * @typedef {Object} Employee
 * @property {Projects[]} assigned_projects
 * @property {string} email
 * @property {string} employee_role
 * @property {number} hour_per_week
 * @property {string} id
 * @property {string} name
 * @property {string} office_id
 * @property {string} position
 * @property {string} status
 * @property {string} system_role_id
 */

/**
 * @typedef {Object} TimesheetFiltersState
 * @property {Filters} filters
 * @property {Employee[]} autocompleteEmployeeList
 */

const userSettings = LocalStorageHelper.getUserSettings();
const locale = userSettings ? userSettings.dateFormatSettings : getLanguage();

const initialTimeSpanValue = locale === LocaleFormat.EN ? timePeriod.week : timePeriod.isoWeek;

/** @returns {TimesheetFiltersState} */
const getInitialState = () => ({
  filters: {
    dateRangeFilter: {
      startDate: DateHelper.toIso(moment().startOf(initialTimeSpanValue)),
      endDate: DateHelper.toIso(moment().endOf(initialTimeSpanValue)),
    },
    timeSpanFilter: initialTimeSpanValue,
    projectFilter: { title: 'All', id: null },
    employeeFilter: { name: 'All', id: null },
    statusFilter: 'All',
  },
  autocompleteEmployeeList: [],
});

/** @type {TimesheetFiltersState} */
const state = getInitialState();

/** @type {GetterTree<TimesheetFiltersState, RootState>} */
const getters = {
  dateRangeFilter: (state) => state.filters.dateRangeFilter,
  projectFilter: (state) => state.filters.projectFilter,
  timeSpanFilter: (state) => state.filters.timeSpanFilter,
  employeeFilter: (state) => state.filters.employeeFilter,
  statusFilter: (state) => state.filters.statusFilter,
  autocompleteEmployeeList: (state) => state.autocompleteEmployeeList,
};

/** @type {ActionTree<TimesheetFiltersState, RootState>} */
const actions = {
  setTimeSpanFilter({ commit }, timeSpanFilter) {
    const period = timeSpanFilter;
    const dateRangeFilter = {
      startDate: moment(),
      endDate: moment(),
    };

    dateRangeFilter.startDate = DateHelper.toIso(moment().startOf(period));
    dateRangeFilter.endDate = DateHelper.toIso(moment(dateRangeFilter.startDate).endOf(period));

    commit('setTimeSpanFilter', timeSpanFilter);
    commit('setDateRangeFilter', dateRangeFilter);
  },
  setProjectFilter({ commit }, projectFilter) {
    commit('setProjectFilter', projectFilter);
  },
  moveDateRange({ commit }, { isForward, dateRange, timeSpanFilter }) {
    const period = timeSpanFilter === '' ? timePeriod.day : timeSpanFilter;
    let periodAmount = 1;
    const newDateRange = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    if (timeSpanFilter === '') {
      periodAmount = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
      periodAmount += 1;
    }

    if (isForward) {
      newDateRange.startDate = moment(dateRange.startDate).add(periodAmount, period);
      newDateRange.endDate = timeSpanFilter === '' ? moment(dateRange.endDate).add(periodAmount, period)
        : moment(newDateRange.startDate).endOf(period);
    } else {
      newDateRange.startDate = moment(dateRange.startDate).subtract(periodAmount, period);
      newDateRange.endDate = timeSpanFilter === '' ? moment(dateRange.endDate).subtract(periodAmount, period)
        : moment(newDateRange.startDate).endOf(period);
    }

    newDateRange.startDate = DateHelper.toIso(newDateRange.startDate);
    newDateRange.endDate = DateHelper.toIso(newDateRange.endDate);

    commit('setDateRangeFilter', newDateRange);
  },
  changeCustomDateRange({ commit }, dateRange) {
    const newDateRange = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };

    commit('setTimeSpanFilter', '');
    commit('setDateRangeFilter', newDateRange);
  },
  setEmployeeFilter({ commit }, employeeFilter) {
    commit('setEmployeeFilter', employeeFilter);
  },
  setStatusFilter({ commit }, statusFilter) {
    commit('setStatusFilter', statusFilter);
  },
  getAutocompleteEmployeeList: async ({ commit, rootGetters }, params = {}) => {
    let { data: employees } = await apiClient.employeeApi.getEmployeesForTimeUnits(params.projectId, params.officeId);
    const isUserEmployee = rootGetters['auth/account/isUserEmployee'];

    if (!isUserEmployee) {
      employees = employees.filter((employee) => employee.status !== 'Disabled');
    }

    commit('setAutocompleteEmployeeList', employees);
  },
};

/** @type {MutationTree<TimesheetFiltersState>} */
const mutations = {
  setDateRangeFilter: (state, data) => (state.filters.dateRangeFilter = data),
  setProjectFilter: (state, data) => (state.filters.projectFilter = data),
  setTimeSpanFilter: (state, data) => (state.filters.timeSpanFilter = data),
  setEmployeeFilter: (state, data) => (state.filters.employeeFilter = data),
  setStatusFilter: (state, data) => (state.filters.statusFilter = data),
  setAutocompleteEmployeeList: (state, employees) => {
    state.autocompleteEmployeeList = CollectionsHelper.sortObjectsByProperty(employees, 'name');
  },
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<TimesheetFiltersState, RootState>} */
export const filters = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
