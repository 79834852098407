import moment from 'moment';

/**
 * @typedef {import('moment')} moment
 */

/** @enum {string} */
export const LocaleFormat = {
  EU: 'eu',
  EN: 'en',
};

/** @enum {string} */
export const DateFormat = {
  VISUAL: 'MM/DD/Y',
  DAY_OF_WEEK: 'dd',
  ISO8601: 'YYYY-MM-DD',
  DATE_TIME: 'DD MMMM YYYY HH:mm',
  DATE_WITH_SHORT_MONTH: 'DD MMM YYYY',
  EU_DATE_FORMAT: 'DD.MM.Y',
  FORMAT_12_HOUR: 'h:mm A',
  FORMAT_24_HOUR: 'H:mm',
};

/* IMPORTANT notice about using dates in code.
 * In UI, all time should be in local. Storing date should be with time for proper timezone conversion.
 * To server we should send UTC date(-time), except date-only, where we should cut time part from local ISO,
 * because server does not know about time, we will have a bug:
 * 2019-12-10T00:00:00+03:00 -> 2019-12-09T21:00:00Z.
 * If we will store dates in UTC, we should convert for visual part:
 * 2019-12-10T00:00:00Z -> 2019-12-10T03:00:00+03:00,
 * BUT in this case, we will have a bug in visual for, ex. Pacific timezone:
 * 2019-12-10T00:00:00Z -> 2019-12-09T16:00:00-08:00.
 */
export class DateHelper {
  /**
   * @param {moment.MomentInput} date
   * @param {number} [daysCount]
   * @returns {moment.Moment}
   */
  static addDays(date, daysCount = 1) {
    return moment.utc(date).add(daysCount, 'days');
  }

  /**
   * @param {moment.MomentInput} date
   * @param {number} [yearsCount]
   * @returns {moment.Moment}
   */
  static addYears(date, yearsCount = 1) {
    return moment.utc(date).add(yearsCount, 'years');
  }

  /**
   * @param {moment.MomentInput} firstDate
   * @param {moment.MomentInput} secondDate
   * @returns {number}
   */
  static countDaysBetween(firstDate, secondDate) {
    return Math.abs(moment(secondDate).diff(firstDate, 'days'));
  }

  /**
   * @param {moment.MomentInput} firstDate
   * @param {moment.MomentInput} secondDate
   * @returns {number}
   */
  static countYearsBetween(firstDate, secondDate) {
    return Math.abs(moment(secondDate).diff(firstDate, 'years'));
  }

  /**
   * @param {moment.MomentInput} date
   * @param {DateFormat} f
   * @returns {string}
   */
  static format(date, f) {
    return date ? moment(date).format(f || DateFormat.ISO8601) : '';
  }

  /**
   * @param {moment.MomentInput} date
   * @param {DateFormat} [f]
   * @returns {boolean}
   */
  static isValid(date, f) {
    return moment(date, f || DateFormat.VISUAL, true).isValid();
  }

  /**
   * @param {moment.MomentInput} dateToCompare
   * @param {moment.MomentInput} dateToCompareWith
   * @returns {boolean}
   */
  static isDayBefore(dateToCompare, dateToCompareWith) {
    return moment(dateToCompare).isBefore(dateToCompareWith, 'day');
  }

  /**
   * @param {moment.MomentInput} date
   * @returns {string}
   */
  static toIso(date) {
    return moment(date).format(DateFormat.ISO8601);
  }

  /**
   * @param {moment.MomentInput} date
   * @returns {moment.Moment}
   */
  static utcToLocal(date) {
    return moment.utc(date).local();
  }

  /**
   * @param {moment.MomentInput} date
   * @param {LocaleFormat} locale
   * @returns {string}
   */
  static formatDateAccordingSettings(date, locale) {
    return DateHelper.format(
      date,
      locale === LocaleFormat.EU ? DateFormat.EU_DATE_FORMAT : DateFormat.VISUAL
    );
  }

  /**
   * @param {moment.MomentInput} date
   * @param {LocaleFormat} locale
   * @returns {string}
   */
  static formatTimeAccordingSettings(date, locale) {
    return DateHelper.format(
      date,
      locale === LocaleFormat.EU ? DateFormat.FORMAT_24_HOUR : DateFormat.FORMAT_12_HOUR
    );
  }

  /**
   * @param {LocaleFormat} locale
   * @returns {1 | 0}
   */
  static getFirstDayOfWeekAccordingSettings(locale) {
    return locale === LocaleFormat.EU ? 1 : 0;
  }

  /**
   * @param {moment.MomentInput} date
   * @param {LocaleFormat} locale
   * @returns {string}
   */
  static getDateTimeAccordingSettings(date, locale) {
    const dateWithShortMonth = DateHelper.format(date, DateFormat.DATE_WITH_SHORT_MONTH);
    const timeAccordingSettings = DateHelper.formatTimeAccordingSettings(date, locale);

    return `${dateWithShortMonth} ${timeAccordingSettings}`;
  }

  /**
   * @param {moment.MomentInput} date
   * @param {LocaleFormat} locale
   * @returns {string}
   */
  static formatDateWithDayOfWeekAccordingSettings(date, locale) {
    const dateAccordingSettings = DateHelper.formatDateAccordingSettings(date, locale);
    const dateWithDayOfWeek = DateHelper.format(date, DateFormat.DAY_OF_WEEK);

    return `${dateAccordingSettings} ${dateWithDayOfWeek}`;
  }
}
