<template>
  <tr>
    <td v-if="haveCheckbox">
      <v-skeleton-loader
        :class="checkboxGutter ? 'ml-8' : 'ml-2'"
        type="text"
        width="12px"
      />
    </td>
    <td
      v-for="(header,index) of headers"
      :key="`${tabName}_` + index + header.value"
      :class="{'header-border': tableHeader}"
      :height="denseTables ? '24px' : '48px'"
    >
      <v-skeleton-loader
        type="text"
        class="px-4"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    haveCheckbox: {
      type: Boolean,
      default: false,
    },
    checkboxGutter: {
      type: Boolean,
      default: false,
    },
    tableHeader: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: 'SkeletonTableRow',
    },
  },
  computed: {
    ...mapGetters('user/settings', ['denseTables']),
  },
};
</script>

<style>
  .v-skeleton-loader__text {
    margin: 7px 0;
  }
  .header-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
</style>
