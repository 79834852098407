import Vue from 'vue';
import Vuex from 'vuex';
import createCache from 'vuex-cache';

import { auth } from './auth';
import { costCenters } from './cost-centers';
import { employees } from './employees';
import { holidays } from './holidays';
import { layout } from './layout';
import { matrixByCostCenterReport } from './matrix-by-cost-center-report';
import { matrixReport } from './matrix-report';
import { projects } from './projects';
import { reports } from './reports';
import { shared } from './shared';
import { timesheet } from './timesheet';
import { user } from './user';

/**
 * @template S
 * @typedef {import('vuex').StoreOptions<S>} StoreOptions<S>
 */
/**
 * @typedef {import('./root.state').RootState} RootState
 */

Vue.use(Vuex);

/** @type {StoreOptions<RootState>} */
const storeOptions = {
  plugins: [
    createCache({ timeout: 60000 }),
  ],
  modules: {
    auth,
    employees,
    holidays,
    layout,
    matrixReport,
    matrixByCostCenterReport,
    projects,
    reports,
    shared,
    timesheet,
    user,
    costCenters,
  },
  actions: {
    resetState({ commit }) {
      commit('auth/account/resetModuleState', null, { root: true });

      commit('employees/single/resetModuleState', null, { root: true });
      commit('employees/list/resetModuleState', null, { root: true });

      commit('holidays/list/resetModuleState', null, { root: true });

      commit('layout/menu/resetModuleState', null, { root: true });

      commit('matrixReport/filters/resetModuleState', null, { root: true });
      commit('matrixReport/report/resetModuleState', null, { root: true });

      commit('matrixByCostCenterReport/filters/resetModuleState', null, { root: true });
      commit('matrixByCostCenterReport/report/resetModuleState', null, { root: true });

      commit('projects/filters/resetModuleState', null, { root: true });
      commit('projects/main/resetModuleState', null, { root: true });

      commit('costCenters/main/resetModuleState', null, { root: true });

      commit('reports/filters/resetModuleState', null, { root: true });
      commit('reports/main/resetModuleState', null, { root: true });
      commit('reports/units/resetModuleState', null, { root: true });
      commit('reports/summary/resetModuleState', null, { root: true });
      commit('reports/reportComments/resetModuleState', null, { root: true });

      commit('shared/common/resetModuleState', null, { root: true });
      commit('shared/offices/resetModuleState', null, { root: true });
      commit('shared/roles/resetModuleState', null, { root: true });
      commit('shared/taskTypes/resetModuleState', null, { root: true });

      commit('timesheet/filters/resetModuleState', null, { root: true });
      commit('timesheet/timeUnits/resetModuleState', null, { root: true });

      commit('user/settings/resetModuleState', null, { root: true });
      commit('user/jira/resetModuleState', null, { root: true });

      this.cache.clear();
    },
  },
};

const store = new Vuex.Store(storeOptions);

export default store;
