/**
 * Builds URI query string from provided object:
 * - object property key will be used as query param name without changes
 * - drops properties with `null` value
 * - in case if property value is an array, builds query like: arrayName=val1&arrayName=val2&...
 *
 * @template T
 * @param {T} params Params object WITHOUT nested objects (can accept nested arrays)
 * @param {string} [query] Query string to append to the end
 * @returns {string} Serialized params with appended query string
 *
 * @example
 * // returns key1=value1&key2=value2
 * paramsSerializer({
 *   key1: 'value1',
 *   key2: 'value2',
 * });
 *
 * @example
 * // returns 0=first&1=second
 * paramsSerializer(['first', 'second']);
 *
 * @example
 * // returns key1=value1&key1=value2&key2=value3
 * paramsSerializer({
 *   key1: ['value1', 'value2'],
 *   key2: 'value3',
 * });
 */
export function paramsSerializer(params, query = '') {
  let newQuery = query;

  for (const [key, value] of Object.entries(params)) {
    if (value == null) continue; // drop null and undefined value query params

    if (Array.isArray(value)) {
      for (const v of value) {
        newQuery = paramsSerializer({ [key]: v }, newQuery);
      }
    } else {
      newQuery += `&${key}=${encodeURIComponent(value)}`;
    }
  }

  return newQuery.replace(/^&/, '');
}
