import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

/**
 * @typedef {import('@/models/report-comments.interface').ReportComment} ReportComment
 */

export class ReportCommentsApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  REPORTS_COMMENTS_API_ROOT = 'report-comments'

  /**
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<ReportComment[]>>}
   */
  getReportComments = (reportId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_COMMENTS_API_ROOT}/`,
    params: {
      report_id: reportId,
    },
  });

  /**
   * @param {string} reportId
   * @param {string} message
   * @returns {Promise<AxiosResponse<Record<string, string>>>}
   */
  addReportComment = (reportId, message) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.REPORTS_COMMENTS_API_ROOT}/${reportId}`,
    data: { message },
  });
}
