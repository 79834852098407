export const action = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ALLOW: 'allowed',
};

export const resource = {
  employeeDetails: 'employee-details',
  employeeList: 'employee-list',
  projectList: 'project-list',
  projectEmployee: 'project-employee',
  projectTaskTypes: 'project-task-types',
  timeUnits: 'time-units',
  reportsList: 'reports-list',
  matrixReport: 'matrix-report',
  costCenterReport: 'cost-center-report',
  reportStatusChange: 'report-status-change',
  holidayList: 'holiday-list',
};

/*
* Make action for the status change permissions check.
* Returns: string 'allowed-from-{statusFrom}-to-{statusTo}'
*/
export const allowedToAction = (statusTo) => {
  const validFormat = (value) => value.toLowerCase().split(' ').join('-');

  return `change-to-${validFormat(statusTo)}`;
};
