import { CollectionsHelper } from '@/helpers/collections.helper';

import { getAuthAccountInitialState } from './auth-account.state';

/**
 * @template T, C
 * @typedef {import('@/store/types/with-context').WithContext<T, C>} WithContext<T, C>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @typedef {import('@/models/user.interface').User} User
 * @typedef {import('@/models/project.interface').Project} Project
 *
 * @typedef {import('./auth-account.state').AuthAccountState} AuthAccountState
 */

/**
 * @typedef {Object} AuthAccountMutations
 * @property {() => void} setLoadingStatus
 * @property {(userData: User) => void} loginSuccess
 * @property {() => void} loginFailure
 * @property {(projects: Project[]) => void} setUserProjects
 * @property {() => void} resetModuleState
 */

/** @type {WithContext<AuthAccountMutations, AuthAccountState>} */
export const authAccountMutations = {
  setLoadingStatus: (state) => state.status = 'loading',
  loginSuccess: (state, userData) => {
    state.status = 'success';
    state.user = userData;
  },
  loginFailure: (state) => {
    state.status = 'error';
    state.user = null;
  },
  setUserProjects: (state, projects) => state.userProjects = CollectionsHelper.sortObjectsByProperty(projects, 'title'),
  resetModuleState: (state) => Object.assign(state, getAuthAccountInitialState()),
};
