import { menu } from './menu';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./layout.state').LayoutState} LayoutState
 */

/** @type {Module<LayoutState, RootState>} */
export const layout = {
  namespaced: true,
  modules: {
    menu,
  },
};
