import { employeesListActions } from './employees-list.actions';
import { employeesListGetters } from './employees-list.getters';
import { employeesListMutations } from './employees-list.mutations';
import { getEmployeesListInitalState } from './employees-list.state';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees-list.state').EmployeesListState} EmployeesListState
 */

/** @type {Module<EmployeesListState, RootState>} */
export const list = {
  namespaced: true,
  state: getEmployeesListInitalState(),
  getters: employeesListGetters,
  actions: employeesListActions,
  mutations: employeesListMutations,
};
