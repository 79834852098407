import { projectStatuses } from '@/constants/projectStatuses';
import { reportTypes } from '@/constants/report-types';
import { reportStatuses } from '@/constants/reportStatuses';
import { tableHeaders, tableHeadersSpecificReport } from '@/constants/reportUnitEmployeeTableHeaders';
import { projectRolesNames, systemRolesNames } from '@/constants/roles';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/constants/reportUnitEmployeeTableHeaders').DataTableHeader} TableHeader
 * @typedef {import('@/models/report.interface').Report} Report
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ReportsMainState
 * @property {string} reportIds
 * @property {Report | {}} report
 * @property {Report[]} reports
 * @property {TableHeader[]} tableHeaders
 */

/** @returns {ReportsMainState} */
const getInitialState = () => ({
  reportIds: [],
  report: {},
  reports: [],
  tableHeaders,
});

/** @type {ReportsMainState} */
const state = getInitialState();

/** @type {GetterTree<ReportsMainState, RootState>} */
// TODO: Remove unnecessary getters - use mapState
const getters = {
  reportIds: (state) => state.reportIds,
  report: (state) => state.report,
  reports: (state) => state.reports,
  isDefaultReport: (state) => state.report.report_type === reportTypes.find((type) => type.isDefault).value,
  currentTableHeaders: ({ tableHeaders }, getters) => {
    if (!getters.isDefaultReport) {
      return tableHeaders;
    }

    return tableHeaders.filter((header) => !tableHeadersSpecificReport.includes(header.value));
  },
  isProjectManagerOfReport: ({ report }, getters, rootState, rootGetters) => {
    const {
      'shared/roles/projectRolesMap': projectRolesMap,
      'auth/account/userProjects': userProjects,
    } = rootGetters;

    const role = projectRolesMap[projectRolesNames.projectManager];

    if (role) {
      const managedReport = userProjects.find((el) => el.id === report.project_id
        && el.assignments.some((assignment) => assignment.role_id === role.id));

      return !!managedReport;
    }

    return false;
  },
  isDeliveryManagerOfReport: ({ report }, getters, rootState, rootGetters) => {
    const {
      'shared/roles/projectRolesMap': projectRolesMap,
      'auth/account/userProjects': userProjects,
    } = rootGetters;

    const role = projectRolesMap[projectRolesNames.deliveryManager];

    if (role) {
      const managedReport = userProjects.find((el) => el.id === report.project_id
        && el.assignments.some((assignment) => assignment.role_id === role.id));

      return !!managedReport;
    }

    return false;
  },
  isReportOfficeMatching: ({ report }, getters, rootState, rootGetters) => {
    const {
      'auth/account/user': user,
    } = rootGetters;

    return user.office === report.office_id;
  },
  isReportEditable: ({ report }, {
    isProjectManagerOfReport,
    isReportOfficeMatching,
    isDeliveryManagerOfReport,
  }, rootState, rootGetters) => {
    const {
      'auth/account/user': user,
    } = rootGetters;

    const isOfficeDirector = user.role === systemRolesNames.officeDirector;
    const isDraft = report.status === reportStatuses.DRAFT;
    const isSentToInvoicong = report.status === reportStatuses.SENT_TO_INVOICING;
    const isRejected = report.status === reportStatuses.REJECTED;
    const isReadyForReview = report.status === reportStatuses.READY_FOR_REVIEW;

    const canOfficeDirectorEdit = isOfficeDirector && !isDraft && !isSentToInvoicong && !isRejected;
    const canProjectManagerEdit = (isProjectManagerOfReport && isDraft) || (isProjectManagerOfReport && isRejected);
    const canDeliveryManagerEdit = (isDeliveryManagerOfReport && isReadyForReview);

    return ((canOfficeDirectorEdit || canProjectManagerEdit) && isReportOfficeMatching) || canDeliveryManagerEdit;
  },
  formProjectsList: (state, getters, rootState, rootGetters) => {
    const {
      'shared/roles/projectRolesMap': projectRolesMap,
      'auth/account/userProjects': userProjects,
    } = rootGetters;

    const projectManagerRole = projectRolesMap[projectRolesNames.projectManager];

    if (!projectManagerRole) return [];

    const projectsByManagerRole = userProjects.filter((userProject) => userProject.assignments.some(
      (assignment) => assignment.role_id === projectManagerRole.id
    ));

    const others = [];
    const former = [];

    projectsByManagerRole.forEach((project) => {
      switch (project.status) {
        case projectStatuses.deleted:
          former.push(project);
          break;
        case projectStatuses.finished:
          former.push(project);
          break;
        default:
          others.push(project);
          break;
      }
    });

    const divider = former.length ? [{ divider: true }, { header: 'Former projects' }] : [];

    const projects = [...others, ...divider, ...former];

    return projects;
  },
};

/** @type {ActionTree<ReportsMainState, RootState>} */
const actions = {
  getReports: async ({ commit, dispatch, rootGetters }, params) => apiClient.reportApi
    .getReports(
      params.projectId,
      params.statuses,
      params.types,
      params.startDate,
      params.endDate,
      params.officesIds,
    )
    .then(({ data: reports }) => {
      const {
        'auth/account/isUserEmployee': isUserEmployee,
        'auth/account/userProjects': userProjects,
        'shared/roles/projectRoles': projectRoles,
        'shared/roles/projectRolesMap': projectRolesMap,
      } = rootGetters;

      if (isUserEmployee) {
        return Promise.all([
          projectRoles.length || dispatch('auth/account/getUserProjects', { root: true }),
          dispatch('shared/roles/getProjectRoles', null, { root: true }),
        ])
          .then(() => {
            const projectManagerRole = projectRolesMap[projectRolesNames.projectManager];
            const projectViewerRole = projectRolesMap[projectRolesNames.projectViewer];
            const deliveryManagerRole = projectRolesMap[projectRolesNames.deliveryManager];

            /* eslint-disable no-param-reassign */
            const projectsIdsMapWhereUserPMorPVorDM = userProjects
              .reduce((projectIdsMap, userProject) => {
                const isUserProjectManagerOrViewer = userProject.assignments
                  .some((assignment) => assignment.role_id === projectManagerRole.id
                    || assignment.role_id === projectViewerRole.id
                    || assignment.role_id === deliveryManagerRole.id);

                if (!isUserProjectManagerOrViewer) return projectIdsMap;

                projectIdsMap[userProject.id] = true;

                return projectIdsMap;
              }, {});
              /* eslint-enable */

            return reports.filter((report) => projectsIdsMapWhereUserPMorPVorDM[report.project_id]);
          });
      }

      return reports;
    })
    .then((reports) => commit('setReports', reports)),
  getReportById: async ({ commit }, { reportId, options }) => {
    const { data: report } = await apiClient.reportApi.getReportById(reportId, options);

    commit('setReport', report);
  },

  getReportAssignment: async ({ commit }, { reportId, assignmentId, options }) => {
    const { data: report } = await apiClient.reportUnitApi.getReportAssignment(reportId, assignmentId, options);

    commit('setReport', report);
  },

  createReport: async ({ commit }, data) => {
    const { data: { report_ids } } = await apiClient.reportApi.createReport(data);

    commit('setReportId', report_ids);
  },
  deleteReport: (context, reportId) => apiClient.reportApi.deleteReport(reportId),
  changeReportStatus: (context, { status, reportId, options }) => apiClient.reportApi.changeReportStatus(
    status,
    reportId,
    options,
  ),
  clearReportDetails: ({ commit, dispatch }) => Promise.all([
    commit('reports/units/setReportUnits', [], { root: true }),
    dispatch('reports/summary/clearSummary', undefined, { root: true }),
    commit('setReport', {}),
  ]),
};

/** @type {MutationTree<ReportsMainState>} */
const mutations = {
  setReportId: (state, data) => (state.reportIds = data),
  setReport: (state, data) => (state.report = data),
  setReports: (state, data) => (state.reports = data),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<ReportsMainState, RootState>} */
export const main = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
