import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').MatrixReport} MatrixReport
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').GetMatrixReportParams} GetMatrixReportParams
 * @typedef {import('@/services/api-client/types/matrix-report.api.types'
 * ).ExportMatrixReportParams} ExportMatrixReportParams
 * @typedef {import('@/services/api-client/types/additional-request-options.interface'
 * ).AdditionalRequestOptions} AdditionalRequestOptions
 */

export class MatrixReportApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  MATRIX_REPORT_API_ROOT = 'matrix-report';

  /**
   * @param {GetMatrixReportParams} params
   * @returns {Promise<AxiosResponse<MatrixReport>>} matrix report
   */
  getMatrixReport = (params) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.MATRIX_REPORT_API_ROOT}/`,
    params: {
      start_date: params.startDate,
      end_date: params.endDate,
      billable_only: params.billableOnly,
      approved_only: params.approvedOnly,
      not_approved_only: params.unapprovedOnly,
    },
  });

  /**
   * @param {ExportMatrixReportParams & {includeLogo: boolean}} params
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<ArrayBuffer>>} axios response with matrix report
   */
  exportMatrixReportToExcel = (params, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.MATRIX_REPORT_API_ROOT}/toexcel`,
    params: {
      start_date: params.startDate,
      end_date: params.endDate,
      billable_only: params.billableOnly,
      approved_only: params.approvedOnly,
      not_approved_only: params.unapprovedOnly,
      apply_overtime: params.isOVRMultiplyerApplied,
      employee: params.employee,
      columns_filter: params.projects,
      employee_status: params.employeeStatus,
      office_ids: params.officeIds,
      hide_empty: params.hideEmpty,
      include_logo: params.includeLogo,
    },
    responseType: 'arraybuffer',
  }, options);
}
