<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  created() {
    this.logout();
  },
  methods: {
    ...mapActions('auth/account', ['logout']),
  },
};
</script>
