/**
 * @typedef {Object} ReportType
 * @property {string} text
 * @property {string} value
 * @property {boolean} [isDefault]
 */

/** @type {ReportType[]} */
export const reportTypes = [
  {
    text: 'Invoicing',
    value: 'invoicing',
    isDefault: true,
  },
  {
    text: 'UA Internal',
    value: 'ua_internal',
  },
];
