import Vue from 'vue';
import VueFlashMessage from 'vue-flash-message';
import VueTheMask from 'vue-the-mask';

import App from './App.vue';
import saveFile from './plugins/saveFile';
import vuetify from './plugins/vuetify';
import router from './router';
import { routeGuard } from './router/guard';
import store from './store';

import 'vuetify/dist/vuetify.min.css';
import 'vue-flash-message/dist/vue-flash-message.min.css';

router.beforeEach(routeGuard);

Vue.use(VueTheMask);
Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 6000,
  },
});
Vue.use(saveFile);

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: (h) => h(App),
});
