<template>
  <router-link to="/">
    <RaftLogoImage class="raft-logo__image" />
  </router-link>
</template>

<script>
import RaftLogoImage from '@/assets/logo.svg';

export default {
  components: {
    RaftLogoImage,
  },
};
</script>

<style>
.raft-logo__image {
  vertical-align: middle;
  /* transform: scale(0.4) translateX(-75%); */
  width: 130px;
  height: 56px;
  padding-top: 7px;
}
.raft-logo__image g{
  transform: scale(1.6);
  margin: auto;
}
</style>
