import { account } from './account';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./auth.state').AuthState} AuthState
 */

/** @type {Module<AuthState, RootState>} */
export const auth = {
  namespaced: true,
  modules: { account },
};
