import { getMatrixReportReportInitialState } from './matrix-report-report.state';

/**
 * @template T, C
 * @typedef {import("@/store/types/with-context").WithContext<T, C>} WithContext<T, C>
 */
/**
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').GetMatrixReportParams} GetMatrixReportParams
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').MatrixReport} MatrixReport
 *
 * @typedef {import("./matrix-report-report.state").MatrixReportReportState} MatrixReportReportState
 */

/**
 * @typedef {Object} MatrixReportReportMutations
 * @property {(matrixReport: MatrixReport) => void} setMatrixReport
 * @property {() => void} resetModuleState
 */

/** @type {WithContext<MatrixReportReportMutations, MatrixReportReportState>} */
export const matrixReportReportMutations = {
  setMatrixReport: (state, matrixReport) => (state.matrixReport = matrixReport),
  // TODO: come up with correct module reset, Object assign doesn't clear state
  resetModuleState: (state) => Object.assign(state, getMatrixReportReportInitialState()),
};
