import has from 'lodash/has';

import { CollectionsHelper } from '@/helpers/collections.helper';
import { EmployeeStatusesHelper } from '@/helpers/employee-statuses.helper';

/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees-list.state').EmployeesListState} EmployeesListState
 */

/** @type {GetterTree<EmployeesListState, RootState>} */
export const employeesListGetters = {
  employeesFiltered: ({ employees, filters }, { statusFilter, statusFilterMap }) => {
    let sortedEmployees = [...employees];

    sortedEmployees = CollectionsHelper.filterObjectsByStringProperty(employees, 'name', filters.name);
    sortedEmployees = CollectionsHelper.filterObjectsByStringPropertyArray(
      sortedEmployees,
      'office_id',
      filters.officeIds
    );
    sortedEmployees = CollectionsHelper.filterObjectsByStringPropertyArray(
      sortedEmployees,
      'system_role_id',
      filters.systemRoleIds
    );
    sortedEmployees = statusFilter.length
      ? sortedEmployees.filter((employee) => has(statusFilterMap, employee.status))
      : sortedEmployees;

    return sortedEmployees;
  },
  /* eslint-disable no-param-reassign */
  statusFilterMap: (state, { statusFilter }) => statusFilter.reduce((filterMap, employeeStatus) => {
    filterMap[employeeStatus.id] = employeeStatus;

    return filterMap;
  }, {}),
  /* eslint-enable */
  statusFilter: ({ filters: { statuses } }, { statusFilterOptions }) => {
    if (statuses || !statusFilterOptions.length) return statuses || [];

    return EmployeeStatusesHelper.filterDefaultStatuses(statusFilterOptions);
  },
  statusFilterOptions: ({ employees }) => EmployeeStatusesHelper.getStatusesFromEmployees(employees),
};
