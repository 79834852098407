export default class Progress {
  static subscriptions = {};

  static notify(actionName, status) {
    if (!actionName) {
      return;
    }

    if (!Progress.subscriptions[actionName]) {
      Progress.subscriptions[actionName] = { counter: 0, handlers: [] };
    }

    Progress.subscriptions[actionName].counter += status === 'pending' ? 1 : -1;

    const actualStatus = Progress.subscriptions[actionName].counter === 0 ? 'finish' : 'pending';

    Progress.subscriptions[actionName].handlers.forEach((handler) => handler({ status: actualStatus }));
  }

  static addEventListener(actionName, handler) {
    if (!Progress.subscriptions[actionName]) {
      Progress.subscriptions[actionName] = { counter: 0, handlers: [] };
    }

    if (Progress.subscriptions[actionName].handlers.includes(handler)) {
      return;
    }

    Progress.subscriptions[actionName].handlers.push(handler);
  }

  static removeEventListener(actionName, handler) {
    if (Progress.subscriptions[actionName]) {
      const index = Progress.subscriptions[actionName].handlers.findIndex(handler);

      Progress.subscriptions[actionName].handlers.splice(index, 1);
    }
  }
}
