import { jira } from './jira';
import { settings, getLanguage } from './settings-module';

export const user = {
  namespaced: true,
  modules: {
    settings,
    jira,
  },
};

export { getLanguage };
