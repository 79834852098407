/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} SharedCommonState
 * @property {number} counter
 * @property {boolean} sidebarToggled //TODO: reneme to isSidebarToggled
 * @property {boolean} isDateRangeOpen
 */

/** @returns {SharedCommonState} */
const getInitialState = () => ({
  counter: 0,
  sidebarToggled: true,
  isDateRangeOpen: false,
});

/** @type {SharedCommonState} */
const state = getInitialState();

/** @type {GetterTree<SharedCommonState, RootState>} */
const getters = {
  sidebarToggled: () => state.sidebarToggled,
  isDateRangeOpen: () => state.isDateRangeOpen,
};

/** @type {ActionTree<SharedCommonState, RootState>} */
const actions = {
  toggleSidebar: ({ commit }) => commit('toggleSidebar'),
  setIsDateRangeOpen: ({ commit }, isOpen) => commit('setIsDateRangeOpen', isOpen),
};

/** @type {MutationTree<SharedCommonState>} */
const mutations = {
  toggleSidebar: (state) => {
    state.sidebarToggled = !state.sidebarToggled;
  },
  setIsDateRangeOpen: (state, isOpen) => {
    state.isDateRangeOpen = isOpen;
  },
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<SharedCommonState, RootState>} */
export const common = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
