import Vue from 'vue';

import { DateHelper } from '@/helpers/date.helper';

/**
 * @typedef {Object} TimeLimitExceedError
 * @property {string} date
 * @property {number} logged_time
 * @property {number} day_limit
 * @property {string} message
 */

export class NotificationHelper {
  /**
   * @param {string} errorMessage
   * @returns {void}
   */
  static showError(errorMessage) {
    Vue.prototype.$flashStorage.flash(errorMessage, 'error');
  }

  /**
   * @param {string} message
   * @returns {void}
   */
  static showSuccess(message) {
    Vue.prototype.$flashStorage.flash(message, 'success');
  }

  /**
   * @param {TimeLimitExceedError} error
   * @param {string} dateFormatSettings
   * @returns {string}
   */
  static formatLoggedTimeErrorMessage(error, dateFormatSettings) {
    const formattedDate = DateHelper.formatDateAccordingSettings(error.date, dateFormatSettings);
    const dateMessage = `On ${formattedDate}`;
    const exceedMessage = `logged time ${error.logged_time}h exceeds day limit of ${error.day_limit}h`;

    return [dateMessage, exceedMessage].join(' ');
  }
}
