<template>
  <div>
    <template v-if="isWindows || isLinux">
      Shortcuts:
      <div>
        <kbd>Ctrl</kbd> +
        <kbd>Insert</kbd> -- insert new {{ unitSubject }}
      </div>
      <div>
        <kbd>Ctrl</kbd> +
        <kbd>Enter</kbd> -- save {{ unitSubject }}
      </div>
      <div>
        <kbd>Tab</kbd> -- navigate focus forward
      </div>
      <div>
        <kbd>Shift</kbd> +
        <kbd>Tab</kbd> -- navigate focus backward
      </div>
      <div>
        <kbd>Esc</kbd> -- cancel {{ unitSubject }} editing/addition
      </div>
      <div>
        <kbd>Arrow Up</kbd> -- edit last {{ unitSubject }}
      </div>
    </template>
    <template v-else-if="isMacOs">
      Shortcuts:
      <div>
        <kbd>Shift</kbd> +
        <kbd>Return</kbd> -- insert new {{ unitSubject }}
      </div>
      <div>
        <kbd>Control</kbd> +
        <kbd>Return</kbd> -- save {{ unitSubject }}
      </div>
      <div>
        <kbd>Tab</kbd> -- navigate focus forward
      </div>
      <div>
        <kbd>Shift</kbd> +
        <kbd>Tab</kbd> -- navigate focus backward
      </div>
      <div>
        <kbd>Esc</kbd> -- cancel {{ unitSubject }} editing/addition
      </div>
      <div>
        <kbd>Arrow Up</kbd> -- edit last {{ unitSubject }}
      </div>
    </template>
  </div>
</template>

<script>
import { PlatformHelper } from '@/helpers/platform.helper';

export default {
  props: {
    unitSubject: {
      type: String,
      default: '',
    },
  },
  computed: {
    isMacOs() {
      return PlatformHelper.isMacOs();
    },
    isLinux() {
      return PlatformHelper.isLinux();
    },
    isWindows() {
      return PlatformHelper.isWindows();
    },
  },
};
</script>
