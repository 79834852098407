import { cacheAction } from 'vuex-cache';

import apiClient from '@/services/api-client';

/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees-list.state').EmployeesListState} EmployeesListState
 */

/** @type {ActionTree<EmployeesListState, RootState>} */
export const employeesListActions = {
  getEmployees: cacheAction(async ({ cache, commit, rootGetters }) => {
    const { data } = await apiClient.employeeApi.getEmployees();

    await cache.dispatch('shared/offices/getOffices', null, { root: true });
    const offices = rootGetters['shared/offices/offices'];
    const employees = data;

    for (const employee of employees) {
      const office = offices.find((office) => office.id === employee.office_id);

      employee.office_name = office ? office.name : null;
    }

    commit('setEmployees', employees);
  }),
};
