<template>
  <v-select
    class="pageFilters selector"
    multiple
    item-value="id"
    :menu-props="{ offsetY: true }"
    :dense="dense"
    :outlined="outlined"
    :items="items"
    :label="label"
    :item-text="itemText"
    :return-object="returnObject"
    :value="value"
    :persistent-hint="persistentHint"
    :error="isError"
    :readonly="isReadOnly"
    :hide-details="hideDetails"
    :autofocus="focus"
    :disabled="disabled"
    @change="onChange"
  >
    <template #selection="{ item, index }">
      <v-chip
        v-if="showChip(index)"
        label
        small
      >
        <span>{{ item[itemText] }}</span>
      </v-chip>
      <span
        v-if="index === amountOfDisplayedChips"
        class="grey--text text-caption"
      >(+{{ value.length - amountOfDisplayedChips }})</span>
    </template>
    <template
      v-if="withSelectAll"
      v-slot:prepend-item
    >
      <v-list-item
        ripple
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-1" />
    </template>
  </v-select>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: 'title',
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    isError: Boolean,
    withSelectAll: Boolean,
    withSelectAllHandler: {
      type: Function,
      default: () => [],
    },
    outlined: Boolean,
    persistentHint: Boolean,
    dense: Boolean,
    isReadOnly: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
    hideDetails: Boolean,
    focus: Boolean,
    returnObject: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChipsMinimized() {
      return this.$vuetify.breakpoint.xlOnly;
    },
    amountOfDisplayedChips() {
      return this.isChipsMinimized ? 1 : 2;
    },
    isAllSelected() {
      const allItems = this.withSelectAllHandler(this.items).map((item) => item.id);

      return allItems.every((item) => this.value.includes(item));
    },
    isSomeSelected() {
      return this.value.length > 0;
    },
    icon() {
      if (this.isAllSelected) return 'mdi-checkbox-marked';

      if (this.isSomeSelected) return 'mdi-minus-box';

      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    onChange(value) {
      this.$emit('update', value, this.id);
    },
    toggle() {
      this.$nextTick(() => {
        const selected = !this.isAllSelected ? this.withSelectAllHandler(this.items).map((item) => item.id) : [];

        this.$emit('update', selected, this.id);
      });
    },
    showChip(index) {
      if (this.isChipsMinimized) return index === 0;

      return index === 0 || index === 1;
    },
  },
};
</script>

<style>
  .v-select__selection {
    font-size: 14px;
  }

  .selector {
    margin-bottom: 10px;
  }
</style>
