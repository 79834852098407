import { common } from './common-module';
import { offices } from './offices-module';
import { roles } from './roles';
import { taskTypes } from './task-type-module';
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./shared.state').SharedState} SharedState
 */

/** @type {Module<SharedState, RootState>} */
export const shared = {
  namespaced: true,
  modules: {
    common,
    offices,
    roles,
    taskTypes,
  },
};
