import axios from 'axios';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

/**
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 * @typedef {import('../types/reports.api.types').CreateReportData} CreateReportData
 * @typedef {import('../types/reports.api.types').CreateReportParams} CreateReportParams
 * @typedef {import('../types/attachments.api.types').AddAttachmentsData} AddAttachmentsData
 * @typedef {import('@/models/report.interface').Report} Report
 * @typedef {import('@/models/assignment.interface').AssignmentFull} AssignmentFull
 * @typedef {import('@/models/attachment.interface').Attachment} Attachment
 */

export class ReportApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  REPORTS_API_ROOT = 'reports'

  /**
   * @param {string} projectId
   * @param {string} statuses
   * @param {string} types
   * @param {string} startDate
   * @param {string} endDate
   * @param {string} officesIds
   * @returns {Promise<AxiosResponse<Report[]>>}
   */
  getReports = (projectId, statuses, types, startDate, endDate, officesIds) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/`,
    params: {
      project_id: projectId,
      statuses,
      report_types: types,
      start_date: startDate,
      end_date: endDate,
      office_ids: officesIds,
    },
  })

  /**
   * @param {string} reportId
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<Report>>}
   */
  getReportById = (reportId, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/`,
  }, options);

  /**
   * @param {CreateReportParams} report
   * @returns {Promise<AxiosResponse<CreateReportData>>}
   */
  createReport = (report) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.REPORTS_API_ROOT}/batch`,
    data: report,
  });

  /**
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteReport = (reportId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.REPORTS_API_ROOT}/${reportId}/`,
  });

  /**
   * @param {string} reportId
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  regenerateReport = (reportId, options) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.REPORTS_API_ROOT}/${reportId}/`,
  }, options);

  /**
   * @param {string} status
   * @param {string} reportId
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  changeReportStatus = (status, reportId, options) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.REPORTS_API_ROOT}/${reportId}/status/${status}`,
  }, options);

  /**
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<AssignmentFull[]>>}
   */
  getEmptyReportAssignments = (reportId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/empty-assignments/`,
  });

  /**
   * @param {string} reportId
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  dowonloadReportForInvoicing = (reportId, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/invoicing/`,
    responseType: 'blob',
  }, options);

  /**
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<Attachment[]>>}
   */
  getAttachments = (reportId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/attachments-as-list/`,
  });

  /**
   * @param {string} reportId
   * @param {any} files
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<AddAttachmentsData>>}
   */
  addAttachments = (reportId, files, options) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.REPORTS_API_ROOT}/${reportId}/attachments/`,
    data: files,
  }, options);

  /**
   * @param {string} reportId
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  downloadAllAttachments = (reportId, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/attachments/`,
    responseType: 'blob',
  }, options);

  /**
   * @param {string} reportId
   * @param {string[]} attachments_ids
   * @returns {Promise<AxiosResponse<void>[]>}
   */
  deleteAttachments = (reportId, attachments_ids) => {
    const requests = attachments_ids.map((id) => this.apiClient.sendRequest({
      method: 'DELETE',
      url: `${this.REPORTS_API_ROOT}/${reportId}/attachments/?attachment_id=${id}`,
    }));

    return axios.all(requests);
  };

  /**
   * @param {string} reportId
   * @param {string[]} attachments_ids
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>[]>}
   */
  downloadBulkAttachments = (reportId, attachments_ids, options) => {
    const requests = attachments_ids.map((id) => this.apiClient.sendRequest({
      method: 'GET',
      url: `${this.REPORTS_API_ROOT}/${reportId}/attachments/?attachment_id=${id}`,
    }, options));

    return axios.all(requests);
  };

  /**
   * @param {string} reportId
   * @param {string} attachment_id
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  downloadSingleAttachment = (reportId, attachment_id, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORTS_API_ROOT}/${reportId}/attachments/?attachment_id=${attachment_id}`,
    responseType: 'blob',
  }, options);
}
