<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs, value }">
      <v-chip
        label
        small
        outlined
        class="my-2"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        {{ currentStatus }}
        <v-icon
          right
          :class="{ 'arrow-turn': value }"
        >
          mdi-menu-down
        </v-icon>
      </v-chip>
    </template>
    <v-list class="status-select-items pa-0">
      <v-list-item
        v-for="item in statusesSelectItems"
        :key="item"
        class="status-select-item px-3"
        @click="selectStatus(item)"
      >
        {{ item }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
    statusesSelectItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectStatus(newStatus) {
      this.$emit('select', newStatus);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~variables";

.arrow-turn {
    transform: rotateZ(180deg);
  }

.status-select-items {
  margin-top: 1px;
  border: 1px solid @orange !important;
  border-radius: 4px !important;
}

.status-select-item {
    min-height: 1.375rem;
    font-size: 0.75rem;
    font-weight: 300;
}
</style>
