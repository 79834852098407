/**
 * @template S, R
 * @typedef {import('vuex').Getter<S, R>} Getter<S, R>
 */

/**
 * @typedef {import('./shared-roles.state').SharedRolesState} SharedRolesState
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('@/services/api-client/types/role.api.types').ProjectRole} ProjectRole
 * @typedef {import('@/services/api-client/types/role.api.types').SystemRole} SystemRole
 */
/**
 * @typedef {Object} SharedRolesGetters
 * @property {(...params: Parameters<Getter<SharedRolesState, RootState>>
 * ) => ProjectRole[]} projectRoles
 * @property {(...params: Parameters<Getter<SharedRolesState, RootState>>
 * ) => Record<string, ProjectRole>} projectRolesMap
 * @property {(...params: Parameters<Getter<SharedRolesState, RootState>>) => SystemRole[]} systemRoles
 * @property {(...params: Parameters<Getter<SharedRolesState, RootState>>) => SystemRole[]} systemRolesICanSet
 */

// TODO: remove unnecessary getters by using `mapState`
/** @type {SharedRolesGetters} */
export const sharedRolesGetters = {
  projectRoles: (state) => state.projectRoles,
  projectRolesMap: (state) => {
    /** @type {Record<string, ProjectRole>} */
    const initialRolesMap = {};

    /* eslint-disable no-param-reassign */
    const rolesMap = state.projectRoles.reduce((rolesMap, role) => {
      rolesMap[role.id] = role;
      rolesMap[role.title] = role;

      return rolesMap;
    }, initialRolesMap);
    /* eslint-enable */

    return rolesMap;
  },
  systemRoles: (state) => state.systemRoles,
  systemRolesICanSet: (state) => state.systemRolesICanSet,
};
