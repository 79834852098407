import { CollectionsHelper } from '@/helpers/collections.helper';
import { DateHelper } from '@/helpers/date.helper';
import { NotificationHelper } from '@/helpers/notification.helper';
import apiClient from '@/services/api-client';
/**
 * @template S, R
 * @typedef {import('vuex').ActionContext<S, R>} ActionContext<S, R>
 */
/**
 * @template T, C
 * @typedef {import('@/store/types/with-context').WithContext<T, C>} WithContext<T, C>
 */
/**
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraImportTimeUnitsParams} JiraImportTimeUnitsParams
 * @typedef {import('@/services/api-client/types/jira.api.types').AddJiraCredentialsParams} AddJiraCredentialsParams
 * @typedef {import('./user-jira.state').UserJiraState} UserJiraState
 * @typedef {import('@/store/root.state').RootState} RootState
 */
/**
 * @typedef {Object} UserJiraActions
 * @property {() => Promise<void>} getJiraCredentials
 * @property {(params: { credentials: AddJiraCredentialsParams }) => Promise<void>} addJiraCredentials
 * @property {(params: { credentialsId: string }) => Promise<void>} deleteJiraCredentials
 * @property {(params: JiraImportTimeUnitsParams) => Promise<void>} importTimeUnits
 * @property {(params: { jiraCredentialsId: string }) => Promise<void>} getJiraProjects
 * @property {() => void} clearImportedTimeUnits
 * @property {(payload: { credentials: AddJiraCredentialsParams }) => Promise<void>} addJiraCredentials
 * @property {(payload: { credentialsId: string }) => Promise<void>} deleteJiraCredentials
 */

/** @type {WithContext<UserJiraActions, ActionContext<UserJiraState, RootState>>} */
export const userJiraActions = {
  getJiraCredentials: async ({ commit }) => {
    const { data: newCredentials } = await apiClient.jiraApi.getCredentials();

    commit('setCredentials', newCredentials);
  },
  getJiraProjects: async ({ commit }, { jiraCredentialsId }) => {
    const { data: jiraProjects } = await apiClient.jiraApi.getJiraProjects({ jiraCredentialsId });

    CollectionsHelper.sortObjectsByProperty(jiraProjects, 'name');
    commit('setJiraProjects', jiraProjects);
  },
  addJiraCredentials: async ({ dispatch }, { credentials }) => {
    await apiClient.jiraApi.addCredentials(credentials);

    dispatch('getJiraCredentials');
  },
  deleteJiraCredentials: async ({ dispatch }, { credentialsId }) => {
    await apiClient.jiraApi.deleteCredentials({ credentialsId });

    dispatch('getJiraCredentials');
  },
  importTimeUnits: async ({ commit, rootGetters }, params) => {
    const { data: importResult } = await apiClient.jiraApi.importTimeUnits(params);

    if (!importResult.imported.length && !importResult.not_imported.length) {
      NotificationHelper.showError('No work logs found to import');
    }

    if (importResult.imported.length) {
      const plural = importResult.imported.length > 1 ? 's' : '';

      NotificationHelper.showSuccess(`Imported ${importResult.imported.length} work log${plural}`);
    }

    if (importResult.not_imported.length) {
      const dateFormatSettings = rootGetters['user/settings/dateFormatSettings'];

      importResult.not_imported.forEach((unit) => {
        const issueName = unit.work_log.issue_key;
        const unitComment = unit.work_log.comment;

        const formattedDate = DateHelper
          .formatDateAccordingSettings(unit.work_log.started_date_by_author_time_zone, dateFormatSettings);
        const errorReason = unit.reason;

        const dateMessage = `Not imported on ${formattedDate}`;
        const unitNameMessage = unitComment
          ? `unit "${issueName}: ${unitComment}"`
          : `unit "${issueName}"`;
        const errorReasonMessage = `because of this reason: ${errorReason}`;

        const notificationMessage = [dateMessage, unitNameMessage, errorReasonMessage].join(' ');

        NotificationHelper.showError(notificationMessage);
      });
    }

    commit('setImportResult', importResult);
  },
  clearImportedTimeUnits: ({ commit }) => commit('setImportResult', null),
};
