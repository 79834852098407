import { routes } from '@/router';
import apiClient from '@/services/api-client';
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @typedef {import('@/models/route-permissions.interface').RoutePermissions} RoutePermissions
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./layout-menu.state').LayoutMenuState} LayoutMenuState
 */

/** @type {ActionTree<LayoutMenuState, RootState>} */
export const layoutMenuActions = {
  loadAllowedMenuItems: async ({ commit }) => {
    const menuItemsRoutes = routes.filter((route) => !!route.isMenuItem && route.permissions);
    /** @type {RoutePermissions[]} */
    const menuItemsPermissions = menuItemsRoutes
      // Filter function already filtered out undefined permissions
      .map((route) => route.permissions);

    const permissionsRequests = menuItemsPermissions.map(
      (permissions) => apiClient.permissionsApi.allowedTo(permissions.action, permissions.resource)
    );

    const responses = await Promise.all(permissionsRequests);
    const allowedMenuItems = responses
      .map(({ data }, index) => (data.allowed ? menuItemsRoutes[index] : null))
      .filter((item) => item != null);

    commit('setAllowedMenuItems', allowedMenuItems);
  },
};
