<template>
  <ProgressActivate
    v-slot="{ isLoading }"
    :action-names="actionNames"
  >
    <v-btn
      class="turnOrange"
      :loading="isLoading"
      :disabled="disabled"
      :color="color"
      @click="clickAction"
      v-on="on"
    >
      <slot />
    </v-btn>
  </ProgressActivate>
</template>

<script>
import ProgressActivate from './ProgressActivate.vue';

export default {
  components: {
    ProgressActivate,
  },
  props: {
    on: {
      type: Object,
      default: () => {},
    },
    loading: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      default: '',
    },
    actionNames: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickAction() {
      this.$emit('clickAction');
      event.currentTarget.blur();
    },
  },
};
</script>

<style lang="less">
  .turnOrange.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--text):hover {
    background-color: #003DDC;
    color: white;
    transition: all 0.5s ease;
  }
</style>
