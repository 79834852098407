import { filters } from './filters-module';
import { report } from './report';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./matrix-report.state').MatrixReportState} MatrixReportState
 */

/** @type {Module<MatrixReportState, RootState>} */
export const matrixReport = {
  namespaced: true,
  modules: {
    filters,
    report,
  },
};
