import { tableHeaders, tableHeadersForSystemRoles } from '@/constants/timesheetTableHeaders';
import { LocalStorageHelper } from '@/helpers/local-storage.helper';

/**
 * @typedef {import('@/constants/timesheetTableHeaders').DataTableHeader} TableHeader
 */

/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} TimehseetTableState
 * @property {string[]} hiddenColumns
 * @property {TableHeader[]} tableHeaders
 */

/** @returns {TimehseetTableState} */
const getInitialState = () => ({
  tableHeaders,
  hiddenColumns: LocalStorageHelper.getHiddenColumns() || [],
});

/** @type {TimehseetTableState} */
const state = getInitialState();

/** @type {GetterTree<TimehseetTableState, RootState>} */
const getters = {
  currentTableHeaders: ({ tableHeaders, hiddenColumns }, getters, rootState, rootGetters) => {
    const { 'employees/single/isSystemRoleForProject': isSystemRoleForProject } = rootGetters;
    const tableHeadersFiltered = tableHeaders.filter((header) => !hiddenColumns.includes(header.value));

    if (isSystemRoleForProject) {
      return tableHeadersFiltered;
    }

    return tableHeadersFiltered.filter((header) => !tableHeadersForSystemRoles.includes(header.value));
  },
  hiddenColumns: (state) => state.hiddenColumns,
  isColumnShown() {
    return (value) => !state.hiddenColumns.includes(value);
  },
};

/** @type {ActionTree<TimehseetTableState, RootState>} */
const actions = {
  changeHiddenColumns({ commit, getters }, columnValue) {
    const { hiddenColumns } = getters;
    const index = hiddenColumns.indexOf(columnValue);

    if (index > -1) {
      hiddenColumns.splice(index, 1);
    } else {
      hiddenColumns.push(columnValue);
    }

    commit('setHiddenColumns', hiddenColumns);
    LocalStorageHelper.setHiddenColumns(hiddenColumns);
  },
};

/** @type {MutationTree<TimehseetTableState>} */
const mutations = {
  setHiddenColumns: (state, hiddenColumns) => {
    state.hiddenColumns = hiddenColumns;
  },
};

/** @type {Module<TimehseetTableState, RootState>} */
export const table = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
