<template>
  <v-row
    align="center"
    justify="center"
  >
    <div class="login-bg" />
    <v-col
      cols="12"
      sm="4"
      xl="3"
    >
      <v-card class="login-card">
        <v-toolbar
          color="grey darken-3 pl-2"
          flat
        >
          <v-toolbar-title class="white--text logo-container">
            <Logo class="logo" />
          </v-toolbar-title>
        </v-toolbar>
        <v-progress-linear
          id="progress-bar"
          data-cy="progress-bar"
          :style="{ visibility: isLoading ? 'visible' : 'hidden' }"
          class="my-0"
          indeterminate
          height="5"
        />
        <v-card-text class="px-4 pt-7 pb-0">
          <v-form
            ref="form"
            v-model="isValid"
          >
            <v-text-field
              id="login-input"
              v-model="username"
              data-cy="login-input"
              :rules="loginRules"
              label="Login"
              placeholder="john.doe"
              required
              outlined
              dense
              @keyup="handleEnterToSubmit"
            />
            <v-text-field
              id="password-input"
              v-model="password"
              data-cy="password-input"
              :rules="passwordRules"
              label="Password"
              placeholder="whiteRabbitNe0"
              required
              outlined
              dense
              :type="isHidden ? 'password' : 'text'"
              :append-icon="isHidden ? 'mdi-eye-off': 'mdi-eye'"
              @click:append="changeToOpposite"
              @keyup="handleEnterToSubmit"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer />
          <v-btn
            id="login-button"
            data-cy="login-button"
            class="px-4"
            color="primary"
            :disabled="isLoading || !(isValid || isInitialValid)"
            elevation="0"
            @click="loginBtnClick"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

import Logo from '@/assets/logo.svg';

export default {
  name: 'Login',
  components: { Logo },
  data() {
    return {
      isInitialValid: true,
      isValid: true,
      isHidden: true,
      username: '',
      password: '',
      loginRules: [
        (value) => !!value || 'Login is required',
      ],
      passwordRules: [
        (value) => !!value || 'Password is required',
      ],
      isLoading: false,
    };
  },
  mounted() {
    const changeInitialValid = () => {
      this.isInitialValid = false;

      window.removeEventListener('click', changeInitialValid);
      window.removeEventListener('keydown', changeInitialValid);
    };

    window.addEventListener('click', changeInitialValid);
    window.addEventListener('keydown', changeInitialValid);
  },
  methods: {
    ...mapActions('auth/account', ['login']),

    async loginBtnClick() {
      const loginForm = this.$refs.form;

      if (!loginForm.validate()) return;

      this.isLoading = true;

      try {
        await this.login({
          username: `raftds\\${this.username}`,
          password: this.password,
        });

        this.$router.push('/');
      } finally {
        this.isLoading = false;
      }
    },
    handleEnterToSubmit(event) {
      if (event.key === 'Enter') {
        this.loginBtnClick();
      }
    },
    changeToOpposite() {
      this.isHidden = !this.isHidden;
    },
  },
};
</script>

<style lang="less">
  .logo-container {
    margin-top: 5px;
  }
  .logo {
    transform: scale(0.4) translateX(-75%);
  }
  .login-bg {
    position: absolute;
    width: 100%;
    min-height: 52vmin;
    max-height: 60vmin;

    background: linear-gradient(145deg, #003DDC, #004cff);
    color: #fff;

    transform-origin: 100%;
    top: 0;
    left: 0;
    padding: 48px 48px 32px;
    transform: skewY(5deg);
  }
  // to hide chrome autoselect input highlight
  .login-card {
    input:-internal-autofill-previewed,
    input:-internal-autofill-selected {
      box-shadow: 0 0 0 30px white inset !important;
    }
  }
</style>
