import { billableStatuses } from '@/constants/billableStatuses';
import sowTypeIDs from '@/constants/sowTypeIDs';
import { defaultType } from '@/constants/sowTypeTableHeaders';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/summary-unit.interface').SummaryUnit} SummaryUnit
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ReportsSummaryState
 * @property {boolean} isSummaryEditing
 * @property {SummaryUnit[]} summaryUnits
 */

/** @return {ReportsSummaryState} */
const getInitialState = () => ({
  isSummaryEditing: false,
  summaryUnits: [],
});

/** @type {ReportsSummaryState} */
const state = getInitialState();

/** @type {GetterTree<ReportsSummaryState, RootState>} */
const getters = {
  isSummaryEditing: (state) => state.isSummaryEditing,
  summaryUnits: (state) => state.summaryUnits,
  summaryMap: (state) => {
    const summary = state.summaryUnits.reduce((summaryMap, summaryUnit) => {
      const isBillable = summaryUnit.billable_status === billableStatuses.billable;
      const sowTypeID = isBillable ? sowTypeIDs[summaryUnit.sow_type] || sowTypeIDs[defaultType] : 'invested';
      const sowTypeSummaryUnits = summaryMap.get(sowTypeID);

      if (sowTypeSummaryUnits) {
        summaryMap.set(sowTypeID, [...sowTypeSummaryUnits, summaryUnit]);
      } else {
        summaryMap.set(sowTypeID, [summaryUnit]);
      }

      return summaryMap;
    }, new Map());

    return summary;
  },
};

/** @type {ActionTree<ReportsSummaryState, RootState>} */
const actions = {
  getSummary: async ({ commit }, { reportId }) => {
    const { data } = await apiClient.reportSummaryApi.getSummary(reportId);

    commit('setSummaryUnits', data);
  },
  clearSummary: ({ commit }) => commit('setSummaryUnits', []),
  updateSummaryReportUnit: async ({ commit, dispatch, rootGetters }, summaryReportUnit) => {
    const {
      'reports/main/report': report,
    } = rootGetters;
    const editedSummaryReportUnit = {
      report_id: report.id,
      assignment_id: summaryReportUnit.assignment_id,
      non_working_time: summaryReportUnit.non_working_time,
      expected_time: summaryReportUnit.expected_time,
      comment: summaryReportUnit.comment,
    };

    if (summaryReportUnit) {
      commit('updateSummaryUnit', summaryReportUnit);

      try {
        await apiClient.reportSummaryApi.updateSummaryReportUnit(
          summaryReportUnit.summary_report_unit_id,
          editedSummaryReportUnit
        );
      } catch {
        dispatch('getSummary', { reportId: report.id });
      }
    }
  },
  deleteSummaryReportUnit: async ({ commit, dispatch, rootGetters }, summaryReportUnit) => {
    const {
      'reports/main/report': report,
    } = rootGetters;

    if (summaryReportUnit) {
      commit('deleteSummaryUnit', summaryReportUnit.summary_report_unit_id);

      try {
        await apiClient.reportSummaryApi.deleteSummaryReportUnit(summaryReportUnit.summary_report_unit_id);
      } catch {
        dispatch('getSummary', { reportId: report.id });
      }
    }
  },
  setIsSummaryEditing: ({ commit }, isSummaryEditing) => commit('setIsSummaryEditing', isSummaryEditing),
};

/** @type {MutationTree<ReportsSummaryState>} */
const mutations = {
  setIsSummaryEditing: (state, data) => (state.isSummaryEditing = data),
  setSummaryUnits: (state, data) => (state.summaryUnits = data),
  deleteSummaryUnit: (state, summaryUnitId) => {
    state.summaryUnits = state.summaryUnits
      .filter((summaryUnit) => summaryUnit.summary_report_unit_id !== summaryUnitId);
  },
  updateSummaryUnit: (state, newSummaryUnit) => {
    const newSummaryUnits = state.summaryUnits
      .filter((summaryUnit) => summaryUnit.summary_report_unit_id !== newSummaryUnit.summary_report_unit_id);

    state.summaryUnits = [...newSummaryUnits, newSummaryUnit];
  },
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<ReportsSummaryState, RootState>} */
export const summary = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
