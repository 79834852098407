<template>
  <v-skeleton-loader
    v-if="loading"
    class="details-header-skeleton"
    type="list-item-avatar-two-line"
  />
  <v-row
    v-else
    class="details-header-content mt-1"
  >
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-avatar
          class="mr-4 white--text text-h5"
          color="primary"
          size="60"
          v-on="avatarTooltip && on"
        >
          <v-icon
            v-if="avatarIcon"
            color="white"
            size="30"
          >
            {{ avatarIcon }}
          </v-icon>
          <template v-else>{{ initialsOutput }}</template>
        </v-avatar>
      </template>
      <span>{{ avatarTooltip }}</span>
    </v-tooltip>
    <v-col>
      <v-row
        align="center"
        class="title-block flex-wrap"
      >
        <span class="text-h4 mr-2">{{ title }}</span>
        <slot name="status">
          <v-chip
            v-if="chipText"
            label
            small
            outlined
            class="my-2"
            :color="chipColor"
          >
            {{ chipText }}
          </v-chip>
        </slot>
      </v-row>
      <v-row
        align="center"
        class="subtitle-block text-subtitle-1 flex-wrap grey--text"
      >
        <slot name="subtitle" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getInitials } from '@/helpers/get-initials';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    chipText: {
      type: String,
      required: false,
      default: '',
    },
    chipColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    initials: {
      type: String,
      required: false,
      default: '',
    },
    avatarIcon: {
      type: String,
      default: '',
    },
    avatarTooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    initialsOutput() {
      if (this.initials) return this.initials;

      if (!this.title) return '';

      const initials = getInitials(this.title);

      return initials.length > 3 ? initials[0] : initials;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
  .details-header-skeleton {
    .v-skeleton-loader__list-item-avatar-two-line {
      padding: 0;
      align-content: flex-start;
      height: 68px;
    }
    .v-skeleton-loader__avatar {
      width: 60px;
      height: 60px;
    }
    .v-skeleton-loader__sentences .v-skeleton-loader__text {
      &:first-child {
        max-width: 352px;
        height: 20px;
        margin: 0 0 12px;
      }
      max-width: 152px;
      height: 16px;
    }
  }
  .details-header-content {
    .title-block {
      min-height: 2.5rem;
    }
    .subtitle-block {
      min-height: 1.75rem;;
    }
  }
</style>
