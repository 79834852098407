const sowTypeIDS = {
  'T&M Actual Time': 'tnm_at',
  'Managed Service': 'ms',
  'Monthly Rate - Actual Time': 'mr_at',
  'Monthly Rate - Prorated': 'mr_p',
  'Fixed Budget Milestone': 'fb_m',
  'Team Extension Milestone': 'te_m',
};

export default sowTypeIDS;
