<template>
  <v-navigation-drawer
    v-if="showMenu"
    app
    permanent
    clipped
    dark
    color="secondary"
    :mini-variant="sidebarToggled"
  >
    <v-list class="pt-0">
      <template v-for="item in allowedMenuItems">
        <v-tooltip
          :key="item.path"
          right
        >
          <template #activator="{ on }">
            <v-list-item
              :to="item.path"
              active-class="activated-link"
              v-on="sidebarToggled ? on : null"
            >
              <v-list-item-icon>
                <v-icon :class="item.outlined ? 'material-icons-outlined' : 'material-icons'">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ item.name }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('layout/menu', ['allowedMenuItems']),
    ...mapGetters('layout/menu', ['showMenu']),
    ...mapGetters('shared/common', ['sidebarToggled']),
  },
  methods: {
    ...mapActions('shared/common', ['toggleSidebar']),
  },
};
</script>

<style scoped lang="less">
@import "~variables";

  .activated-link {
    background-color: @blue;

    &:hover {
      background-color: @blue !important;
      filter: brightness(125%);
    }

    &::before {
      // this rule removes white overlay on `active-link` item
      display: none;
    }

    >div {
      color: #fff !important;
    }
  }
</style>
