import { getLayoutMenuInitialState } from './layout-menu.state';
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @typedef {import('./layout-menu.state').LayoutMenuState} LayoutMenuState
 */

/** @type {MutationTree<LayoutMenuState>} */
export const layoutMenuMutations = {
  setAllowedMenuItems: (state, allowedMenuItems) => (state.allowedMenuItems = allowedMenuItems),
  resetModuleState: (state) => Object.assign(state, getLayoutMenuInitialState()),
};
