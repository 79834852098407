import has from 'lodash/has';

import { CollectionsHelper } from '@/helpers/collections.helper';
import { ProjectStatusesHelper } from '@/helpers/project-statuses.helper';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/office.interface').Office} Office
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ProjectsFilters
 * @property {string[]} offices
 * @property {string[]} types
 * @property {string[] | null} statuses
 * @property {string} projectName
 */

/**
 * @typedef {Object} FilteredProjectList
 * @property {string} client_id
 * @property {string} client_name
 * @property {string | null} end_date
 * @property {string} id
 * @property {Office[]} office
 * @property {string[]} office_ids
 * @property {string} office_name
 * @property {string} start_date
 * @property {string} status
 * @property {string} title
 * @property {string} type
 */

/**
 * @typedef {Object} FilterProjectsState
 * @property {ProjectsFilters} filters
 * @property {FilteredProjectList[]} projectList
 */

/** @returns {FilterProjectsState} */
const getInitialState = () => ({
  filters: {
    offices: [],
    types: [],
    statuses: null,
    projectName: '',
  },
  projectList: [],
});

/** @type {FilterProjectsState} */
const state = getInitialState();

/** @type {GetterTree<FilterProjectsState, RootState>} */
const getters = {
  projectNameFilter: (state) => state.filters.projectName,
  projectOfficeFilter: (state) => state.filters.offices,
  projectTypeFilter: (state) => state.filters.types,
  projectStatusFilter: ({ filters: { statuses } }, { projectStatusFilterOptions }) => {
    if (statuses || !projectStatusFilterOptions.length) return statuses || [];

    return ProjectStatusesHelper.filterDefaultStatuses(projectStatusFilterOptions)
      .map((projectStatus) => projectStatus.title);
  },
  projectTypeFilterOptions: (state) => {
    const projectTypes = state.projectList.map((project) => (project.type));
    const uniqueProjectTypes = Array.from(new Set(projectTypes));
    const projectTypeFilterOptions = uniqueProjectTypes.map((project) => ({ id: project, title: project }));

    return projectTypeFilterOptions;
  },
  projectStatusFilterOptions: (state) => {
    const projectStatuses = state.projectList
      .map((project) => (project.status))
      .filter((status) => status); // filter added because I get a null status
    const uniqueProjectStatuses = Array.from(new Set(projectStatuses));
    const projectStatusFilterOptions = uniqueProjectStatuses.map((project) => ({ id: project, title: project }));

    return projectStatusFilterOptions;
  },
  projectsFiltered: ({ filters }, { projectList, projectStatusFilter, statusFilterMap }) => {
    let projects = projectList;

    projects = CollectionsHelper.filterObjectsByStringProperty(projects, 'title', filters.projectName);
    projects = CollectionsHelper.filterObjectsByStringPropertyArray(projects, 'office_ids', filters.offices);
    projects = CollectionsHelper.filterObjectsByStringPropertyArray(projects, 'type', filters.types);
    projects = projectStatusFilter.length
      ? projects.filter((project) => has(statusFilterMap, project.status))
      : projects;

    return projects;
  },
  /* eslint-disable no-param-reassign */
  statusFilterMap: (state, { projectStatusFilter }) => projectStatusFilter.reduce((filterMap, projectStatus) => {
    filterMap[projectStatus] = projectStatus;

    return filterMap;
  }, {}),
  /* eslint-enable */
  projectList: (state) => state.projectList,
};

/** @type {ActionTree<FilterProjectsState, RootState>} */
const actions = {
  setNameFilter: ({ commit }, projectNameFilter) => commit('setNameFilter', projectNameFilter),
  setOfficesFilter: ({ commit }, officesFilter) => commit('setOfficesFilter', officesFilter),
  setTypesFilter: ({ commit }, typesFilter) => commit('setTypesFilter', typesFilter),
  setStatusesFilter: ({ commit }, statusesFilter) => commit('setStatusesFilter', statusesFilter),

  getProjectList: async ({ commit }) => {
    const { data: projects } = await apiClient.projectApi.getProjectsByViewer(undefined, undefined);

    for (const project of projects) {
      project.office_ids = project.office ? project.office.map((o) => o.id) : [];
      project.office_name = project.office ? project.office.map((o) => o.title).join(', ') : [];
    }
    commit('setProjectList', projects);
  },
};

/** @type {MutationTree<FilterProjectsState>} */
const mutations = {
  setNameFilter: (state, projectName) => (state.filters.projectName = projectName),
  setOfficesFilter: (state, offices) => (state.filters.offices = offices),
  setProjectList: (state, projectList) => (state.projectList = projectList),
  setTypesFilter: (state, types) => (state.filters.types = types),
  setStatusesFilter: (state, statuses) => (state.filters.statuses = statuses),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<FilterProjectsState, RootState>} */
export const filters = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
