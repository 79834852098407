import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').MatrixReport} MatrixReport
 * @typedef {import('@/services/api-client/types/matrix-report.api.types').GetMatrixReportParams} GetMatrixReportParams
 * @typedef {import('@/services/api-client/types/matrix-by-cost-center-report.api.types'
 * ).ExportMatrixByCostCenterReportParams} ExportMatrixByCostCenterReportParams
 * @typedef {import('@/services/api-client/types/additional-request-options.interface'
 * ).AdditionalRequestOptions} AdditionalRequestOptions
 */

export class MatrixByCostCenterReportApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  MATRIX_REPORT_API_ROOT = 'matrix-report/clients';
  ABSENCE_CALC_REPORT = '/absence-calc/export';

  /**
   * @param {string} startDate
   * @param {string} endDate
   * @param {boolean} billableOnly
   * @returns {Promise<AxiosResponse<MatrixReport>>}
   */
  getMatrixReport = (startDate, endDate, billableOnly) => this.apiClient.sendRequest({
    method: 'GET',
    url: this.MATRIX_REPORT_API_ROOT,
    params: {
      start_date: startDate,
      end_date: endDate,
      billable_only: billableOnly,
    },
  });

  /**
   * @param {ExportMatrixByCostCenterReportParams & {includeLogo: boolean}} params
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<MatrixReport>>}
   */
  exportMatrixReportToExcel = (params, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.MATRIX_REPORT_API_ROOT}/toexcel`,
    params: {
      start_date: params.startDate,
      end_date: params.endDate,
      billable_only: params.billableOnly,
      employee: params.employee,
      columns_filter: params.costCenters,
      employee_status: params.employeeStatus,
      office_ids: params.officeIds,
      hide_empty: params.hideEmpty,
      include_logo: params.includeLogo,
    },
    responseType: 'arraybuffer',
  }, options);

  /**
   * @returns {Promise<AxiosResponse<MatrixReport>>}
   */
    getDataForGenerateCsvFile = () => this.apiClient.sendRequest({
      method: 'GET',
      url: `${this.ABSENCE_CALC_REPORT}`,
    });
}
