const stylesRoot = 'sow-type-table__header';

const allHeaders = {
  employee: {
    text: 'Employee',
    value: 'employee.name',
    class: `${stylesRoot}-employee`,
  },
  position: {
    text: 'Position',
    value: 'position',
    class: `${stylesRoot}-position`,
  },
  billable_status: {
    text: 'Type',
    value: 'billable_status',
    class: `${stylesRoot}-billable_status`,
  },
  month_time: {
    text: 'Month Time',
    value: 'month_time',
    class: `${stylesRoot}-month_time`,
  },
  holidays: {
    text: 'Holidays',
    value: 'holidays',
    class: `${stylesRoot}-holidays`,
  },
  expected_time: {
    text: 'Expected Regular time',
    value: 'expected_time',
    class: `${stylesRoot}-expected_time`,
  },
  expected_irregular_time: {
    text: 'Expected Irregular time',
    value: 'expected_irregular_time',
    class: `${stylesRoot}-expected_irregular_time`,
  },
  regular_time: {
    text: 'Regular time',
    value: 'regular_time',
    class: `${stylesRoot}-regular_time`,
  },
  approved_overtime: {
    text: 'Approved Overtime to Invoice',
    value: 'approved_overtime',
    class: `${stylesRoot}-approved_overtime`,
  },
  ratio: {
    text: 'Monthly Ratio to Invoice',
    value: 'ratio',
    class: `${stylesRoot}-ratio`,
  },
  irregular_time: {
    text: 'Irregular time',
    value: 'irregular_time',
    class: `${stylesRoot}-irregular_time`,
  },
  total_hours: {
    text: 'Total Hours to Invoice',
    value: 'total_hours',
    class: `${stylesRoot}-total_hours`,
  },
  non_working_time: {
    text: 'Total Non-Working Time',
    value: 'non_working_time',
    class: `${stylesRoot}-non_working_time`,
  },
  comment: {
    text: 'Comment',
    value: 'comment',
    class: `${stylesRoot}-comment`,
  },
};

const sowTypeHeaders = {
  'Managed Service': [
    allHeaders.employee,
    allHeaders.position,
    allHeaders.expected_time,
    allHeaders.expected_irregular_time,
    allHeaders.regular_time,
    allHeaders.irregular_time,
    allHeaders.total_hours,
    allHeaders.non_working_time,
    allHeaders.comment,
  ],
  'T&M Actual Time': [
    allHeaders.employee,
    allHeaders.position,
    allHeaders.expected_time,
    {
      ...allHeaders.regular_time,
      text: 'Regular time to Invoice',
    },
    allHeaders.approved_overtime,
    allHeaders.total_hours,
    allHeaders.non_working_time,
    allHeaders.comment,
  ],
  'Monthly Rate - Actual Time': [
    allHeaders.employee,
    allHeaders.position,
    allHeaders.expected_time,
    {
      ...allHeaders.regular_time,
      text: 'Regular time to Invoice',
    },
    allHeaders.approved_overtime,
    allHeaders.ratio,
    allHeaders.non_working_time,
    allHeaders.comment,
  ],
  'Monthly Rate - Prorated': [
    allHeaders.employee,
    allHeaders.position,
    allHeaders.month_time,
    allHeaders.holidays,
    allHeaders.approved_overtime,
    allHeaders.ratio,
    {
      ...allHeaders.non_working_time,
      text: 'Total Paid or Unpaid Vacation',
    },
    allHeaders.comment,
  ],
  invested: [
    allHeaders.employee,
    allHeaders.position,
    allHeaders.billable_status,
    allHeaders.expected_time,
    allHeaders.regular_time,
    {
      ...allHeaders.approved_overtime,
      text: 'Overtime',
    },
    allHeaders.total_hours,
    allHeaders.non_working_time,
    allHeaders.comment,
  ],
};

const defaultType = 'T&M Actual Time';

export { sowTypeHeaders, defaultType };
