import { authAccountActions } from './auth-account.actions';
import { authAccountGetters } from './auth-account.getters';
import { authAccountMutations } from './auth-account.mutations';
import { getAuthAccountInitialState } from './auth-account.state';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./auth-account.state').AuthAccountState} AuthAccountState
 */

/** @type {Module<AuthAccountState, RootState>} */
export const account = {
  namespaced: true,
  state: getAuthAccountInitialState(),
  getters: authAccountGetters,
  actions: authAccountActions,
  mutations: authAccountMutations,
};
