import { layoutMenuActions } from './layout-menu.actions';
import { layoutMenuGetters } from './layout-menu.getters';
import { layoutMenuMutations } from './layout-menu.mutations';
import { getLayoutMenuInitialState } from './layout-menu.state';
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./layout-menu.state').LayoutMenuState} LayoutMenuState
 */

/** @type {Module<LayoutMenuState, RootState>} */
export const menu = {
  namespaced: true,
  state: getLayoutMenuInitialState(),
  getters: layoutMenuGetters,
  actions: layoutMenuActions,
  mutations: layoutMenuMutations,
};
