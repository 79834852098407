import jwt from 'jsonwebtoken';

import { LocalStorageHelper } from '@/helpers/local-storage.helper';
import router from '@/router';
import apiClient from '@/services/api-client';
/**
 * @template S, R
 * @typedef {import('vuex').ActionContext<S, R>} ActionContext<S, R>
 */
/**
 * @template T, C
 * @typedef {import('@/store/types/with-context').WithContext<T, C>} WithContext<T, C>
 */
/**
 * @typedef {import('@/services/api-client/types/decoded-refresh-token.interface'
 * ).DecodedRefreshToken} DecodedRefreshToken
 *
 * @typedef {import('@/models/user.interface').User} User
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./auth-account.state').AuthAccountState} AuthAccountState
 */

/**
 * @typedef {Object} AuthAccountActions
 * @property {(payload: { username: string; password: string }) => Promise<void>} login
 * @property {(userData: User) => void} updateUserData
 * @property {() => Promise<void>} logout
 * @property {() => Promise<void>} getUserProjects
 */

/** @type {WithContext<AuthAccountActions, ActionContext<AuthAccountState, RootState>>} */
export const authAccountActions = {
  login: async ({ commit, dispatch }, { username, password }) => {
    commit('setLoadingStatus');

    try {
      const response = await apiClient.authApi.login(username, password);
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        employee_details: employeeDetails,
      } = response.data;

      LocalStorageHelper.setRefreshToken(refreshToken);
      apiClient.accessToken = accessToken;

      dispatch('updateUserData', employeeDetails);
    } catch (error) {
      commit('loginFailure', error);

      apiClient.accessToken = null;
      LocalStorageHelper.clearUserData();

      throw error;
    }
  },

  updateUserData: ({ commit }, userData) => {
    LocalStorageHelper.setUser(userData);

    commit('loginSuccess', userData);
  },

  logout: async ({ dispatch }) => {
    const refresh = LocalStorageHelper.getRefreshToken();

    try {
      if (refresh) {
        const now = Date.now() / 1000;
        const decodedResult = jwt.decode(refresh);

        if (!decodedResult || typeof decodedResult === 'string') {
          throw new Error('Something went wrong with your refresh token');
        }

        const decodedRefresh = /** @type {DecodedRefreshToken} */(decodedResult);
        const isExpired = decodedRefresh.exp - now <= 0;

        if (!isExpired) {
          await apiClient.authApi.logout();
        }
      }
    } finally {
      apiClient.accessToken = null;
      LocalStorageHelper.clearUserData();

      dispatch('resetState', null, { root: true });

      await router.push({ name: 'login' });
    }
  },

  getUserProjects: async ({ commit }) => {
    const { data } = await apiClient.userApi.getProjects();
    const filteredProjects = data.filter((i) => i.status !== 'Deleted');

    commit('setUserProjects', filteredProjects);
  },
};
