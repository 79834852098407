/**
 * @typedef {import('@/models/employee-status.interface').EmployeeStatus} EmployeeStatus
 * @typedef {import('@/models/employee.interface').Employee} Employee
 */

/**
 * @typedef {Object} EmployeesListFilters
 * @property {string} name
 * @property {string[]} officeIds
 * @property {string[]} systemRoleIds
 * @property {EmployeeStatus | null} statuses
 */

/**
 * @typedef {Object} EmployeesListState
 * @property {Employee[]} employees
 * @property {EmployeesListFilters} filters
 */

/** @returns {EmployeesListState} */
export const getEmployeesListInitalState = () => ({
  employees: [],
  filters: {
    name: '',
    officeIds: [],
    systemRoleIds: [],
    statuses: null,
  },
});
