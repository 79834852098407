/**
 * @typedef {import('@/models/schedule-entry.interface').ScheduleEntry} ScheduleEntry
 * @typedef {import('@/models/employee.interface').Employee} Employee
 */

/**
 * @typedef {Object} EmployeesSingleState
 * @property {Employee} employee
 * @property {ScheduleEntry[]} schedule
 */

/** @returns {EmployeesSingleState} */
export const getEmployeesSingleInitialState = () => ({
  employee: {},
  schedule: [],
});
