<template>
  <v-row justify="center">
    <v-dialog
      :max-width="width"
      :persistent="isPersistent"
      :value="dialog"
      @input="toggleConfirmationAction"
    >
      <v-card>
        <v-card-title
          class="text-h5 lighten-2"
          primary-title
        >
          {{ title }}
          <v-spacer />
          <v-progress-circular
            v-if="loading"
            indeterminate
            :size="24"
            color="primary"
          />
        </v-card-title>
        <v-card-text class="pb-4 pt-2">
          <div class="text-pre">{{ text }}</div>
          <slot name="content" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            text
            :disabled="isConfirmationButtonDisabled"
            @click="toggleConfirmationAction(true)"
          >
            {{ confirmationButtonText }}
          </v-btn>
          <v-btn
            text
            @click="toggleConfirmationAction(false)"
          >
            {{ closeButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isPersistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    confirmationButtonText: {
      type: String,
      default: 'Ok',
    },
    isConfirmationButtonDisabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '400px',
    },
    closeButtonText: { // TODO: replace specific props with action buttons slot
      type: String,
      default: 'Cancel',
    },
  },
  methods: {
    /** @param {boolean} confirmed */
    toggleConfirmationAction(confirmed) {
      this.$emit('confirmationAction', confirmed);
    },
  },
};
</script>

<style lang="less" scoped>
.text-pre {
  white-space: pre-line;
}
</style>
