<template>
  <div class="progress-activate">
    <slot :isLoading="isLoading" />
  </div>
</template>

<script>
import Progress from '@/services/progress';

export default {
  props: {
    actionNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.actionNames.forEach((name) => Progress.addEventListener(name, this.handleEvent));
  },
  beforeDestroy() {
    this.actionNames.forEach((name) => Progress.removeEventListener(name, this.handleEvent));
  },
  methods: {
    handleEvent(event) {
      const { status } = event;

      this.isLoading = status === 'pending';
    },
  },
};
</script>

<style lang="less">
  .progress-activate {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
  }
</style>
