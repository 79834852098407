export const loadingTypes = {
  syncAllButton: 'sync-all-button',
  none: 'none',
  /* Timesheet */
  timesheetExcelTools: 'timesheet-excel-tools',
  /* Project details */
  syncProjectButton: 'sync-project-button',
  /* Report details */
  recompileReportButton: 're-compile-report-button',
  regener: 'report-tab-unit',
  attachFileButton: 'attach-file-button',
  reviewReportButton: 'review-report-button',
  draftReportButton: 'draft-report-button',
  invoicingReportButton: 'invoicing-report-button',
  attachmentsReportButton: 'attachments-report-button',
  reportExportToExcelButton: 'report-export-to-excel-button',
  reportSchedule: 'report-schedule',
  /* Matrix report */
  matrixReportExportToExcelButton: 'matrix-report-export-to-excel-button',
  matrixReportExportToCsvlButton: 'matrix-report-export-to-csv-button',
  /* Employee details */
  syncEmployeeButton: 'sync-employee-button',
};
