/**
 * @typedef {import('@/models/user.interface').User} User
 * @typedef {import('@/models/user-settings.interface').UserSettings} UserSettings
 */

/** @enum {string} */
export const LSKey = {
  USER: 'user',
  USER_SETTINGS: 'userSettings',
  REFRESH_TOKEN: 'refresh_token',
  TIMESHEET_HIDDEN_TABLE_COLUMNS: 'timesheetTableHiddenColumns',
};

export class LocalStorageHelper {
  /** @returns {User | null} */
  static getUser() {
    return LocalStorageHelper.get(LSKey.USER);
  }
  /** @returns {UserSettings | null} */
  static getUserSettings() {
    return LocalStorageHelper.get(LSKey.USER_SETTINGS);
  }
  /** @returns {string | null} */
  static getRefreshToken() {
    return LocalStorageHelper.get(LSKey.REFRESH_TOKEN);
  }
  /** @returns {string[] | null} */
  static getHiddenColumns() {
    return LocalStorageHelper.get(LSKey.TIMESHEET_HIDDEN_TABLE_COLUMNS);
  }

  /**
   * @param {User} user
   * @returns {void}
   */
  static setUser(user) {
    LocalStorageHelper.set(LSKey.USER, user);
  }
  /**
   * @param {UserSettings} userSettings
   * @returns {void}
   */
  static setUserSettings(userSettings) {
    LocalStorageHelper.set(LSKey.USER_SETTINGS, userSettings);
  }
  /**
   * @param {string} refreshToken
   * @returns {void}
   */
  static setRefreshToken(refreshToken) {
    LocalStorageHelper.set(LSKey.REFRESH_TOKEN, refreshToken);
  }
  /**
   * @param {string[]} hiddenColumns
   * @returns {void}
   */
  static setHiddenColumns(hiddenColumns) {
    LocalStorageHelper.set(LSKey.TIMESHEET_HIDDEN_TABLE_COLUMNS, hiddenColumns);
  }

  /** @returns {void} */
  static clearUserData() {
    LocalStorageHelper.remove([LSKey.USER, LSKey.REFRESH_TOKEN]);
  }

  /**
   * @param {LSKey} key
   * @returns {any}
   */
  static get(key) {
    const storedValue = localStorage.getItem(key);

    if (!storedValue) return null;

    try {
      // throws error if we are trying to parse simple string
      return JSON.parse(storedValue);
    } catch {
      return storedValue;
    }
  }

  /**
   * @param {LSKey} key
   * @param {any} value
   * @returns {void}
   */
  static set(key, value) {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);

      return;
    }

    const stringifiedValue = JSON.stringify(value);

    localStorage.setItem(key, stringifiedValue);
  }

  /**
   * @param {LSKey | LSKey[]} keys
   * @returns {void}
   */
  static remove(keys) {
    if (!(keys instanceof Array)) {
      localStorage.removeItem(keys);

      return;
    }

    keys.forEach((key) => localStorage.removeItem(key));
  }
}
