import { filters } from './filters-module';
import { table } from './table-module';
import { timeUnits } from './time-units-module';

/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./timesheet.state').TimesheetState} TimesheetModules
 */

/** @type {Module<TimesheetModules, RootState>} */
export const timesheet = {
  namespaced: true,
  modules: {
    filters,
    timeUnits,
    table,
  },
};
