import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

/**
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 */
export class PermissionsApi extends ApiBase {
  /**
   * @param {string} action
   * @param {string} resource
   * @param {string} [projectId]
   * @param {string} [status]
   * @param {AdditionalRequestOptions} [options]
   * @returns {Promise<AxiosResponse<{ allowed: boolean }>>}
   */
  allowedTo = (
    action,
    resource,
    projectId,
    status,
    options,
  ) => this.apiClient.sendRequest({
    method: 'GET',
    url: `permissions/to/${action}/${resource}`,
    params: {
      project_id: projectId,
      status,
    },
  }, options);

  getAbsenceCalc = () => this.apiClient.sendRequest({
    method: 'GET',
    url: 'permissions/to/read/absence-calc',
  });
}
