<template>
  <v-menu
    offset-y
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <div class="select-group" />
      <div
        class="user-menu-container"
        v-on="on"
      >
        <v-avatar
          :size="35"
          class="user-menu-avatar"
          data-cy="user-menu-avatar"
          color="primary"
        >
          <span>{{ userInitials }}</span>
        </v-avatar>
        <div class="user-menu-name">
          <span>{{ user && user.name }}</span>
        </div>
        <div class="user-menu-icon">
          <v-icon v-ripple>arrow_drop_down</v-icon>
        </div>
      </div>
    </template>
    <v-list class="mt-2">
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { getInitials } from '@/helpers/get-initials';

/**
 * @typedef {Object} MenuItem
 * @property {string} title
 * @property {(event: MouseEvent) => void} action
 */

export default {
  components: {},
  computed: {
    ...mapGetters('auth/account', ['user']),

    /** @returns {MenuItem[]} */
    menuItems() {
      return [
        { title: 'Settings', action: this.navigateToSettings },
        { title: 'Logout', action: this.logout },
      ];
    },
    /** @returns {string} */
    userInitials() {
      return getInitials(this.user.name);
    },
  },
  methods: {
    ...mapActions('auth/account', ['logout']),

    navigateToSettings() {
      if (this.$route.name === 'UserSettings') return;

      this.$router.push({ name: 'UserSettings' });
    },
  },
};
</script>

<style lang="less">
  .user-menu-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .user-menu-avatar {
    margin-right: 10px;
  }

  .user-menu-name {
    margin-right: 10px;
  }

  .select-group{
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .select-roles{
    color: white;
    text-align: center;
    background-color: #424242;
  }

</style>
