// TODO: delete hardcode of roles ids
const Roles = {
  systemRolesNames: {
    admin: 'Admin',
    officeDirector: 'Office director',
    officeViewer: 'Office viewer',
    employee: 'Employee',
  },
  projectRolesNames: {
    deliveryManager: 'Delivery manager',
    projectManager: 'Project manager',
    projectViewer: 'Project viewer',
    teamMember: 'Team member',
  },
  systemRoles: {
    admin: { id: 'c95c3f8b-34bf-4d89-b14b-c0627776fe44', name: 'Admin' },
    officeDirector: { id: 'a8e8a088-6225-494f-8836-a14de2ccf25c', name: 'Office director' },
    employee: { id: 'f4a1e9b8-b8a1-419e-9911-519b88b85783', name: 'Employee' },
    officeViewer: { id: 'c1ddc735-9d42-40ba-ae34-2ce7c92ee257', name: 'Office viewer' },
  },
  projectRoles: {
    deliveryManager: { id: 'eceac7e7-6988-4c8f-9d99-da39cf95e837', name: 'Delivery manager' },
    projectManager: { id: '4c68eb91-eba6-4855-971d-af4a8d653646', name: 'Project manager' },
    projectViewer: { id: '0944aefe-e762-4e32-8712-8042375e8fb4', name: 'Project viewer' },
    teamMember: { id: 'de118abf-2e3f-4837-9085-200328be35ce', name: 'Team member' },
  },
};

export const { systemRolesNames } = Roles;
export const { projectRolesNames } = Roles;
/* eslint-disable no-param-reassign */
export const systemRolesMapById = Object.values(Roles.systemRoles).reduce((map, element) => {
  map[element.id] = element.name;

  return map;
}, {});
/* eslint-enable */
export const { projectRoles } = Roles;
export default Roles;
