import { CollectionsHelper } from '@/helpers/collections.helper';

import { getHolidaysListInitalState } from './holidays-list.state';

/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @typedef {import('./holidays-list.state').HolidaysListState} HolidaysListState
 */

/** @type {MutationTree<HolidaysListState>} */
export const holidaysListMutations = {
  setHolidays: (state, holidays) => (state.holidays = CollectionsHelper.sortObjectsByProperty(
    holidays,
    'date'
  )),
  resetModuleState: (state) => Object.assign(state, getHolidaysListInitalState()),
};
