import apiClient from '@/services/api-client';

/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees-single.state').EmployeesSingleState} EmployeesSingleState
 */

/** @type {ActionTree<EmployeesSingleState, RootState>} */
export const employeesSingleActions = {
  getEmployee: async ({ commit }, { employeeId }) => {
    const { data } = await apiClient.employeeApi.getEmployee(employeeId);

    commit('setEmployee', data);
  },
  updateEmployee: (context, { employeeId, patch }) => apiClient.employeeApi.updateEmployee(employeeId, patch),

  getSchedule: async ({ commit }, params) => {
    const { data: schedule } = await apiClient.employeeApi.getSchedule(params);

    commit('setSchedule', schedule);
  },
};
