/**
 * @typedef {Object} TimePeriod
 * @property {'day'} day
 * @property {'week'} week
 * @property {'isoWeek'} isoWeek
 * @property {'month'} month
 */

/** @type {TimePeriod} */
export const timePeriod = {
  day: 'day',
  week: 'week',
  isoWeek: 'isoWeek',
  month: 'month',
};

export const minutes = 60;
