/** @enum {number} */
const SizeDenominator = {
  KB: 1024,
  MB: 1048576,
};

export class FileSizeConverter {
  /**
   * @param {number} size
   * @returns {string}
   */
  static convertToKB(size) {
    return `${(size / SizeDenominator.KB).toFixed(1)}KB`;
  }
  /**
   * @param {number} size
   * @returns {string}
   */
  static converToMB(size) {
    return `${(size / SizeDenominator.MB).toFixed(1)}MB`;
  }
}
