/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./employees-single.state').EmployeesSingleState} EmployeesSingleState
 */

/** @type {GetterTree<EmployeesSingleState, RootState>} */
export const employeesSingleGetters = {
  // TODO: use permissions from API, but before we need to integrate status transitions into permissions
  isSystemRoleForProject: ({ employee }, getters, rootState, rootGetters) => {
    const {
      'auth/account/isUserAdmin': isUserAdmin,
      'auth/account/isUserOfficeDirector': isUserOfficeDirector,
      'auth/account/isUserOfficeViewer': isUserOfficeViewer,
      'auth/account/isUserPV': isUserPV,
    } = rootGetters;

    const isSupervisor = employee.is_project_manager
      || isUserAdmin
      || isUserOfficeDirector
      || isUserOfficeViewer
      || isUserPV;

    return Boolean(isSupervisor);
  },
  isProjectManager: ({ employee }) => Boolean(employee && employee.is_project_manager),
};
