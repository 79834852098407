<template>
  <v-col>
    <v-menu
      ref="menu"
      v-model="menu"
      transition="scale-transition"
      min-width="290px"
      color="primary"
      offset-y
      :close-on-content-click="false"
      :nudge-right="40"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="formattedDate"
          class="secondary-background"
          append-icon="event"
          readonly
          hide-details
          dense
          :rules="rules"
          :label="label"
          @click:append="menu = true"
          v-on="on"
        />
      </template>
      <v-date-picker
        color="primary"
        no-title
        :value="value"
        :min="minDate"
        :max="maxDate"
        :first-day-of-week="firstDayOfWeek"
        @input="input"
      />
    </v-menu>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

import { DateHelper } from '@/helpers/date.helper';

export default {
  name: 'DatePicker',
  props: {
    label: {
      type: String,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      default: undefined,
    },
    maxDate: {
      type: String,
      default: undefined,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters('user/settings', ['dateFormatSettings']),

    formattedDate() {
      return DateHelper.formatDateAccordingSettings(
        this.value,
        this.dateFormatSettings
      );
    },

    firstDayOfWeek() {
      return DateHelper.getFirstDayOfWeekAccordingSettings(
        this.dateFormatSettings
      );
    },
  },
  methods: {
    /** @param {string} value - ISO string */
    input(value) {
      this.$emit('input', value);
      this.menu = false;
    },
  },
};
</script>
