import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */

export class SyncApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  SYNC_API_ROOT = 'sync'

  /** @returns {Promise<AxiosResponse<void>>} */
  syncAll = () => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.SYNC_API_ROOT}/`,
  }, { loadingType: loadingTypes.syncAllButton });

  /**
   * @param {string} id
   * @returns {Promise<AxiosResponse<void>>}
   */
  syncEmployee = (id) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.SYNC_API_ROOT}/employee/${id}`,
  }, { loadingType: loadingTypes.syncEmployeeButton });

  /**
   * @param {string} id
   * @returns {Promise<AxiosResponse<void>>}
   */
  syncProject = (id) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.SYNC_API_ROOT}/project/${id}`,
  }, { loadingType: loadingTypes.syncProjectButton });
}
