import { saveAs } from 'file-saver';

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$saveFiles = (files) => {
      files.forEach(Vue.prototype.$saveFile);
    };
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$saveFile = (file) => {
      const blob = new Blob([file.data], { type: file.headers['content-type'] });
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(file.headers['content-disposition']);

      if (matches != null && matches[1]) {
        const fileName = matches[1].replace(/['"]/g, '');

        saveAs(blob, fileName);
      }
    };
  },
};
