import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { NotificationHelper } from '@/helpers/notification.helper';
import apiClient from '@/services/api-client';
/**
 * @typedef {import('@/models/time-unit.interface').TimeUnit} TimeUnit
 * @typedef {import('@/models/report-unit.interface').ReportUnit} ReportUnit
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} ReportsUnitsState
 * @property {ReportUnit[] | []} reportUnits
 * @property {boolean} isReportUnitEditing
 */

/** @constant  {number} */
const DEFAULT_UNIT_MINUTES = 60;

/** @returns {ReportsUnitsState} */
const getInitialState = () => ({
  reportUnits: [],
  isReportUnitEditing: false,
});

/** @type {ReportsUnitsState} */
const state = getInitialState();

/** @type {GetterTree<ReportsUnitsState, RootState>} */
const getters = {
  reportUnits: (state) => state.reportUnits,
  reportUnitsMap: (state) => groupBy(state.reportUnits, 'assignment_id'),
  isReportUnitEditing: (state) => state.isReportUnitEditing,
};

/** @type {ActionTree<ReportsUnitsState, RootState>} */
const actions = {
  getReportUnits: async ({ commit }, params) => {
    const { data: reportUnits } = await apiClient.reportUnitApi.getReportUnits(
      params.reportId,
      params.employeeIds,
      params.options,
    );

    commit('setReportUnits', reportUnits.sort((a, b) => a.logged_date.localeCompare(b.logged_date)));
  },
  deleteReportUnit: async ({
    commit, dispatch, getters, rootGetters,
  }, { reportUnitId }) => {
    const { 'reports/main/reportIds': reportId } = rootGetters;
    const { reportUnits } = getters;

    commit('setReportUnits', reportUnits.filter((unit) => unit.id !== reportUnitId));

    try {
      await apiClient.reportUnitApi.deleteReportUnit(reportUnitId);
      dispatch('reports/summary/getSummary', { reportId }, { root: true });
    } catch {
      dispatch('getReportUnits', { reportId });
    }
  },

  addNewReportUnit: async ({ dispatch }, { originReportUnit, currentAssignment }) => {
    dispatch('createReportUnitPlaceholder', { originReportUnit, currentAssignment });
  },
  duplicateReportUnit: async ({ dispatch }, originReportUnit) => {
    dispatch('createReportUnitPlaceholder', { originReportUnit, isDuplicate: true });
  },
  createReportUnitPlaceholder: ({ commit, getters, rootGetters },
    { originReportUnit, isDuplicate = false, currentAssignment = null }) => {
    const { reportUnits } = getters;
    const { 'reports/main/report': report } = rootGetters;

    commit('setReportUnitEditing', true);

    let insertionIndex = 0;

    if (originReportUnit !== null) {
      insertionIndex = reportUnits.findIndex((unit) => unit.id === originReportUnit.id);
    }

    insertionIndex = insertionIndex > -1 ? insertionIndex : 0;

    if (isDuplicate) {
      const duplicate = {
        ...originReportUnit,
        id: null,
        previous_report_unit_id: originReportUnit.id,
      };

      commit('addReportUnit', { newReportUnit: duplicate, insertionIndex });

      return;
    }

    let reportUnit = {
      id: null,
      employee: currentAssignment.employee,
      task_type_id: '',
      minutes: DEFAULT_UNIT_MINUTES,
      overtime_minutes: 0,
      description: '',
      logged_date: report.start_date,
      assignment_id: currentAssignment.assignment_id,
      previous_report_unit_id: '',
      task_type_title: '',
      report_id: report.id,
    };

    if (originReportUnit) {
      reportUnit = {
        ...originReportUnit,
        id: null,
        previous_report_unit_id: originReportUnit.id,
        report_id: report.id,
        description: '',
        minutes: 60,
        overtime_minutes: 0,
      };
    }

    commit('addReportUnit', { newReportUnit: reportUnit, insertionIndex });
  },

  createReportUnit: async ({ commit, dispatch, rootGetters }, { newReportUnit }) => {
    const { 'reports/main/reportIds': reportId } = rootGetters;

    dispatch('cancelReportUnitEditing');

    const tempId = `temp_${moment().unix()}`;

    /** @type {TimeUnit} */
    const tempReportUnit = {
      ...newReportUnit,
      id: tempId,
    };

    commit('insertReportUnit', tempReportUnit);

    try {
      const { data } = await apiClient.reportUnitApi.createReportUnit(newReportUnit.report_id, newReportUnit);

      /** @type {TimeUnit} */
      const updatedReportUnit = {
        ...newReportUnit,
        id: data.report_unit_id,
      };

      dispatch('updateReportUnit', {
        newReportUnit: updatedReportUnit,
        oldReportUnit: tempReportUnit,
      });
      dispatch('reports/summary/getSummary', { reportId }, { root: true });
    } catch (error) {
      dispatch('updateStoredReportUnits', { reset: true });
      throw error;
    }
  },

  updateReportUnit: async ({ commit, dispatch, rootGetters }, { newReportUnit, oldReportUnit }) => {
    const { 'reports/main/reportIds': reportId } = rootGetters;

    dispatch('cancelReportUnitEditing');

    const duplicate = { ...newReportUnit };

    if (newReportUnit.logged_date === oldReportUnit.logged_date) {
      const replaceConfig = {
        reportUnit: duplicate,
        replaceID: oldReportUnit.id,
      };

      commit('replaceReportUnit', replaceConfig);
    } else {
      commit('deleteReportUnit', { id: oldReportUnit.id });

      commit('insertReportUnit', duplicate);
    }

    try {
      await apiClient.reportUnitApi.updateReportUnit(duplicate.id, duplicate);

      dispatch('reports/summary/getSummary', { reportId }, { root: true });
    } catch (error) {
      dispatch('updateStoredReportUnits', { reset: true });
      throw error;
    }
  },

  cancelReportUnitEditing: ({ commit }) => {
    commit('clearReportUnits');
    commit('setReportUnitEditing', false);
  },
  updateStoredReportUnits: async ({ commit, dispatch, rootGetters }, config) => {
    if (config && config.reset) {
      commit('resetModuleState');
    }

    const { 'reports/main/reportIds': reportId } = rootGetters;

    dispatch('cancelReportUnitEditing');

    dispatch('getReportUnits', { reportId });
  },
  copyReportUnits: async ({ rootGetters, dispatch }, { reportUnits, assignmentId }) => {
    const { 'projects/main/projectAssignments': projectAssignments } = rootGetters;
    const { 'reports/main/reportIds': reportId } = rootGetters;
    const employeeFromAssignment = projectAssignments.find((assignment) => assignment.id === assignmentId);

    for (const report of reportUnits) {
      report.employee = employeeFromAssignment.employee;
      report.previous_report_unit_id = null;
    }

    const reportUnitIds = reportUnits.map((reportUnit) => reportUnit.id);

    const result = await apiClient.reportUnitApi.copyReportUnits(reportId, assignmentId, reportUnitIds);

    if (result.status === 200) {
      dispatch('getReportUnits', { reportId });

      const assignmentType = employeeFromAssignment.sow_po_number || employeeFromAssignment.billable_status;
      const fullNameOfAssignment = `${employeeFromAssignment.employee.name}(${assignmentType})`;

      NotificationHelper.showSuccess(`${reportUnitIds.length} report units were copied to ${fullNameOfAssignment}`);
    }
  },
  deleteBatchReportUnits: async ({ commit, dispatch, getters }, { reportUnitsToDelete, assignmentId, reportId }) => {
    const { reportUnits } = getters;

    const reportUnitIds = reportUnitsToDelete.map((reportUnit) => reportUnit.id);

    const filteredReportUnits = reportUnits.filter((reportUnit) => !reportUnitIds.includes(reportUnit.id));

    commit('setReportUnits', filteredReportUnits);

    try {
      await apiClient.reportUnitApi.deleteBatchReportUnits(reportUnitIds, assignmentId, reportId);
      dispatch('reports/summary/getSummary', { reportId }, { root: true });
    } catch {
      dispatch('updateStoredReportUnits', { reset: true });
    }
  },
};

/** @type {MutationTree<ReportsUnitsState>} */
const mutations = {
  setReportUnits: (state, data) => (state.reportUnits = data),
  resetModuleState: (state) => Object.assign(state, getInitialState()),

  setReportUnitEditing: (state, newState) => {
    state.isReportUnitEditing = newState;
  },

  replaceReportUnit: (state, { reportUnit, replaceID }) => {
    const { reportUnits } = state;

    if (replaceID) {
      const replaceIndex = reportUnits.findIndex((unit) => unit.id === replaceID);

      if (replaceIndex > -1) {
        reportUnits.splice(replaceIndex, 1, reportUnit);
        state.reportUnits = reportUnits;
      }
    }
  },

  insertReportUnit: (state, reportUnit) => {
    const { reportUnits } = state;

    if (reportUnit.previous_report_unit_id) {
      const insertIndex = reportUnits.findIndex((unit) => unit.id === reportUnit.previous_report_unit_id);

      reportUnits.splice(insertIndex + 1, 0, reportUnit);

      const nextReportUnit = reportUnits[insertIndex + 2];

      if (nextReportUnit) {
        nextReportUnit.previous_report_unit_id = reportUnit.id;
      }

      state.reportUnits = reportUnits;

      return;
    }

    const prevIndex = reportUnits.findIndex((unit) => reportUnit.logged_date < unit.logged_date);

    if (prevIndex !== -1) {
      reportUnits.splice(prevIndex, 0, reportUnit);
    } else {
      reportUnits.push(reportUnit);
    }

    state.reportUnits = reportUnits;
  },

  addReportUnit: (state, { newReportUnit, insertionIndex }) => {
    const updatedReportUnitsList = [...state.reportUnits];

    updatedReportUnitsList.splice(insertionIndex + 1, 0, newReportUnit);
    state.reportUnits = new Array(...updatedReportUnitsList);
  },

  clearReportUnits: (state) => {
    const { reportUnits } = state;

    const reportUnitsFiltered = reportUnits.filter((unit) => unit && unit.id);

    state.reportUnits = reportUnitsFiltered;
  },

  deleteReportUnit: (state, { id }) => {
    const { reportUnits } = state;
    const filteredReportUnits = reportUnits.filter((unit) => unit.id !== id);

    state.reportUnits = filteredReportUnits;
  },
};

/** @type {Module<ReportsUnitsState, RootState>} */
export const units = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
