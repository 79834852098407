import moment from 'moment';

import { timePeriod } from '@/constants/timePeriod';
import { DateHelper } from '@/helpers/date.helper';
import { EmployeeStatusesHelper } from '@/helpers/employee-statuses.helper';
/**
 * @typedef {import('@/models/employee-status.interface').EmployeeStatus} EmployeeStatus
 */
/**
 * @template S, R
 * @typedef {import('vuex').ActionTree<S, R>} ActionTree<S, R>
 */
/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 */

/**
 * @typedef {Object} DateRange
 * @property {string} startDate
 * @property {string} endDate
 */

/**
 * @typedef {Object} MatrixReportsFilters
 * @property {DateRange} dateRangeFilter
 * @property {string} employeeFilter
 * @property {EmployeeStatus | null} employeeStatuses
 * @property {string[]} officeIds
 * @property {string} projectFilter
 */

/**
 * @typedef {Object} MatrixReportsFiltersState
 * @property {MatrixReportsFilters} filters
 * @property {boolean} isOVRMultiplyerApplied
 * @property {boolean} isBillableOnlyShown
 * @property {boolean} isApprovedOnlyShown
 * @property {boolean} isUnapprovedOnlyShown
 * @property {boolean} isEmptyHidden
 */

/** @returns {MatrixReportsFiltersState} */
const getInitialState = () => ({
  filters: {
    dateRangeFilter: {
      startDate: DateHelper.toIso(moment().startOf(timePeriod.month)),
      endDate: DateHelper.toIso(moment().endOf(timePeriod.month)),
    },
    employeeFilter: '',
    employeeStatuses: null,
    officeIds: [],
    projectFilter: '',
  },
  isOVRMultiplyerApplied: false,
  isBillableOnlyShown: false,
  isApprovedOnlyShown: false,
  isUnapprovedOnlyShown: false,
  isEmptyHidden: false,
});

/** @type {MatrixReportsFiltersState} */
const state = getInitialState();

/** @type {GetterTree<MatrixReportsFiltersState, RootState>} */
const getters = {
  employeeFilter: (state) => state.filters.employeeFilter,
  dateRangeFilter: (state) => state.filters.dateRangeFilter,
  employeeStatusFilter: (state, getters) => {
    const { employeeStatuses } = state.filters;
    const { employeeStatusFilterOptions } = getters;

    if (employeeStatuses || !employeeStatusFilterOptions.length) return employeeStatuses || [];

    const initialStatuses = EmployeeStatusesHelper.filterDefaultStatuses(employeeStatusFilterOptions);

    return initialStatuses.map((status) => status.title);
  },
  /* eslint-disable no-param-reassign */
  employeeStatusFilterMap: (state, getters) => getters.employeeStatusFilter.reduce((filterMap, employeeStatus) => {
    filterMap[employeeStatus] = employeeStatus;

    return filterMap;
  }, {}),
  /* eslint-enable */
  employeeStatusFilterOptions: (state, getters, rootState, rootGetters) => {
    const matrixReport = rootGetters['matrixReport/report/matrixReport'];

    if (!matrixReport.employees || !matrixReport.employees.length) return [];

    return EmployeeStatusesHelper.getStatusesFromEmployees(matrixReport.employees);
  },
  employeeOfficeFilter: (state) => state.filters.officeIds,
  projectFilter: (state) => state.filters.projectFilter,
  isOVRMultiplyerApplied: (state) => state.isOVRMultiplyerApplied,
  isBillableOnlyShown: (state) => state.isBillableOnlyShown,
  isApprovedOnlyShown: (state) => state.isApprovedOnlyShown,
  isUnapprovedOnlyShown: (state) => state.isUnapprovedOnlyShown,
  isEmptyHidden: (state) => state.isEmptyHidden,
};

/** @type {ActionTree<MatrixReportsFiltersState, RootState>} */
const actions = {
  setEmployeeFilter: ({ commit }, name) => commit('setEmployeeFilter', name),
  setEmployeeStatusFilter: ({ commit }, statuses) => commit('setEmployeeStatusFilter', statuses),
  setEmployeeOfficeFilter: ({ commit }, officeIds) => commit('setEmployeeOfficeFilter', officeIds),
  setProjectFilter: ({ commit }, title) => commit('setProjectFilter', title),
  changeCustomDateRange({ commit }, dateRange) {
    const newDateRange = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };

    commit('setDateRangeFilter', newDateRange);
  },
  moveDateRange({ commit }, { isForward, dateRange }) {
    /** @type {moment.DurationInputArg2} */
    let period = timePeriod.month;
    let periodAmount = 1;

    const newDateRange = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    if (newDateRange.startDate === DateHelper.toIso(moment(dateRange.startDate).startOf(period))
      && newDateRange.endDate === DateHelper.toIso(moment(dateRange.endDate).endOf(period))) {
      periodAmount = moment(dateRange.endDate).diff(moment(dateRange.startDate), period);
    } else {
      period = timePeriod.day;
      periodAmount = moment(dateRange.endDate).diff(moment(dateRange.startDate), period);
    }

    periodAmount += 1;

    if (isForward) {
      newDateRange.startDate = moment(dateRange.startDate).add(periodAmount, period);
      newDateRange.endDate = period === timePeriod.month
        ? moment(newDateRange.startDate).add(periodAmount - 1, period).endOf(period)
        : moment(dateRange.endDate).add(periodAmount, period);
    } else {
      newDateRange.startDate = moment(dateRange.startDate).subtract(periodAmount, period);
      newDateRange.endDate = period === timePeriod.month
        ? moment(dateRange.endDate).subtract(periodAmount, period).endOf(period)
        : moment(dateRange.endDate).subtract(periodAmount, period);
    }

    newDateRange.startDate = DateHelper.toIso(newDateRange.startDate);
    newDateRange.endDate = DateHelper.toIso(newDateRange.endDate);

    commit('setDateRangeFilter', newDateRange);
  },
  setIsOVRMultiplyerApplied({ commit }, isOVRMultiplyerApplied) {
    commit('setIsOVRMultiplyerApplied', isOVRMultiplyerApplied);
  },
  setIsBillableOnlyShown({ commit }, isBillableOnlyShown) {
    commit('setIsBillableOnlyShown', isBillableOnlyShown);
  },
  setIsApprovedOnlyShown({ commit }, isApprovedOnlyShown) {
    commit('setIsApprovedOnlyShown', isApprovedOnlyShown);
  },
  setIsUnapprovedOnlyShown({ commit }, isUnapprovedOnlyShown) {
    commit('setIsUnapprovedOnlyShown', isUnapprovedOnlyShown);
  },
  setIsEmptyHidden({ commit }, isEmptyHidden) {
    commit('setIsEmptyHidden', isEmptyHidden);
  },
};

/** @type {MutationTree<MatrixReportsFiltersState>} */
const mutations = {
  setEmployeeFilter: (state, data) => (state.filters.employeeFilter = data),
  setEmployeeStatusFilter: (state, statuses) => (state.filters.employeeStatuses = statuses),
  setEmployeeOfficeFilter: (state, officeIds) => (state.filters.officeIds = officeIds),
  setProjectFilter: (state, data) => (state.filters.projectFilter = data),
  setDateRangeFilter: (state, data) => (state.filters.dateRangeFilter = data),
  setIsOVRMultiplyerApplied: (state, data) => (state.isOVRMultiplyerApplied = data),
  setIsBillableOnlyShown: (state, data) => (state.isBillableOnlyShown = data),
  setIsApprovedOnlyShown: (state, data) => (state.isApprovedOnlyShown = data),
  setIsUnapprovedOnlyShown: (state, data) => (state.isUnapprovedOnlyShown = data),
  setIsEmptyHidden: (state, data) => (state.isEmptyHidden = data),
  resetModuleState: (state) => Object.assign(state, getInitialState()),
};

/** @type {Module<MatrixReportsFiltersState, RootState>} */
export const filters = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
