import { render, staticRenderFns } from "./AkvelonButton.vue?vue&type=template&id=ab463c66"
import script from "./AkvelonButton.vue?vue&type=script&lang=js"
export * from "./AkvelonButton.vue?vue&type=script&lang=js"
import style0 from "./AkvelonButton.vue?vue&type=style&index=0&id=ab463c66&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports