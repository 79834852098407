import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('@/models/project.interface').ProjectFull} Project
 * @typedef {import('@/models/report-unit.interface').ReportUnit} ReportUnit
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 * @typedef {import('@/services/api-client/types/report-unit.api.types').ReportUnitId} ReportUnitId
 * @typedef {import('@/services/api-client/types/report-unit.api.types').CreateReportUnitParams } CreateReportUnitParams
 */

export class ReportUnitApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  REPORT_UNITS_API_ROOT = 'report-units';

  /**
   * @param {string} reportId
   * @param {string[]} employeeIds
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<ReportUnit[]>>}
   */
  getReportUnits = (reportId, employeeIds, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORT_UNITS_API_ROOT}/`,
    params: {
      report_id: reportId,
      employee_ids: employeeIds,
    },
  }, options);

  /**
   * @param {string} reportId
   * @param {string[]} employeeIds
   * @param {boolean} includeLogo
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  exportReportUnitsToExcel = (reportId, employeeIds, includeLogo, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORT_UNITS_API_ROOT}/toexcel`,
    params: {
      report_id: reportId,
      employee_ids: employeeIds,
      include_logo: includeLogo,
    },
    responseType: 'arraybuffer',
  }, options);

  /**
   * @param {string} reportUnitId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteReportUnit = (reportUnitId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.REPORT_UNITS_API_ROOT}/${reportUnitId}`,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} reportId
   * @param {CreateReportUnitParams} reportUnit
   * @returns {Promise<AxiosResponse<ReportUnitId>>}
   */
  createReportUnit = (reportId, reportUnit) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.REPORT_UNITS_API_ROOT}/${reportId}`,
    data: reportUnit,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} reportUnitId
   * @param {CreateReportUnitParams} reportUnit
   * @returns {Promise<AxiosResponse<void>>}
   */
  updateReportUnit = (reportUnitId, reportUnit) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.REPORT_UNITS_API_ROOT}/${reportUnitId}`,
    data: reportUnit,
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} reportId
   * @param {string} assignmentId
   * @param {string[]} reportUnitIds
   * @returns {Promise<AxiosResponse<void>>}
   */
  copyReportUnits = (reportId, assignmentId, reportUnitIds) => this.apiClient.sendRequest({
    method: 'POST',
    url: `${this.REPORT_UNITS_API_ROOT}/${reportId}/copy`,
    data: {
      assignment_id: assignmentId,
      report_unit_ids: reportUnitIds,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string[]} reportUnitsIds
   * @param {string} assignmentId
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<void>>}
   */
  deleteBatchReportUnits = (reportUnitsIds, assignmentId, reportId) => this.apiClient.sendRequest({
    method: 'DELETE',
    url: `${this.REPORT_UNITS_API_ROOT}/batch`,
    data: {
      report_unit_ids: reportUnitsIds,
      assignment_id: assignmentId,
      report_id: reportId,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * @param {string} reportId
   * @param {string} assignmentId
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<void>>}
   */
  getReportAssignment = (reportId, assignmentId, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.REPORT_UNITS_API_ROOT}/${reportId}/${assignmentId}`,
  }, options);

  /**
   * @param {AdditionalRequestOptions} options
   * @param {string} assignmentId
   * @param {string} reportId
   * @returns {Promise<AxiosResponse<void>>}
   */
  regenerateAssignment = (reportId, assignmentId, options) => this.apiClient.sendRequest({
    method: 'PUT',
    url: `${this.REPORT_UNITS_API_ROOT}/${reportId}/${assignmentId}/regenerate`,
    data: {
      report_id: reportId,
      assignment_id: assignmentId,
    },
  }, options);
}
