import { getEmployeesListInitalState } from './employees-list.state';

/**
 * @template S
 * @typedef {import('vuex').MutationTree<S>} MutationTree<S>
 */
/**
 * @typedef {import('./employees-list.state').EmployeesListState} EmployeesListState
 */

/** @type {MutationTree<EmployeesListState>} */
export const employeesListMutations = {
  setEmployees: (state, employees) => state.employees = employees,

  setNameFilter: (state, name) => (state.filters.name = name),
  setOfficeFilter: (state, officeIds) => (state.filters.officeIds = officeIds),
  setSystemRoleFilter: (state, systemRoleIds) => (state.filters.systemRoleIds = systemRoleIds),
  setStatusFilter: (state, statuses) => (state.filters.statuses = statuses),

  resetModuleState: (state) => Object.assign(state, getEmployeesListInitalState()),
};
