import { filters } from './filters-module';
import { report } from './report-module';
/**
 * @template S, R
 * @typedef {import('vuex').Module<S, R>} Module<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./matrix-by-cost-center-report.state').MatrixByCostCenterReportState} MatrixByCostCenterReport
 */

/** @type {Module<MatrixByCostCenterReport, RootState>} */
export const matrixByCostCenterReport = {
  namespaced: true,
  modules: {
    filters,
    report,
  },
};
