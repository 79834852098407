/**
 * @template S, R
 * @typedef {import('vuex').GetterTree<S, R>} GetterTree<S, R>
 */
/**
 * @typedef {import('@/store/root.state').RootState} RootState
 * @typedef {import('./holidays-list.state').HolidaysListState} HolidaysListState
 */

/** @type {GetterTree<HolidaysListState, RootState>} */
export const holidaysListGetters = {
  holidays: (state) => state.holidays,
};
