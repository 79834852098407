import { loadingTypes } from '@/constants/loadingTypes';

import { ApiBase } from './api-base';

/**
 * @template R
 * @typedef {import('axios').AxiosResponse<R>} AxiosResponse<R>
 */
/**
 * @typedef {import('@/models/employee.interface').Employee} Employee
 * @typedef {import('@/models/schedule-entry.interface').ScheduleEntry} ScheduleEntry
 * @typedef {import('@/models/employee-assignments.interface').EmployeeAssignments} EmployeeAssignments
 * @typedef {import('../types/employee.api.types').GetEmployeesParams} GetEmployeesParams
 * @typedef {import('../types/employee.api.types').GetScheduleParams} GetScheduleParams
 * @typedef {import('../types/employee.api.types').GetEmployeeAssignmentsParams} GetEmployeeAssignmentsParams
 * @typedef {import('../types/additional-request-options.interface').AdditionalRequestOptions} AdditionalRequestOptions
 */

export class EmployeeApi extends ApiBase {
  /**
   * @private
   * @readonly
   */
  EMPLOYEES_API_ROOT = 'employees';

  /**
   * Get single employee by id
   * @param {string} id employee id
   * @returns {Promise<AxiosResponse<Employee>>}
   */
  getEmployee = (id) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.EMPLOYEES_API_ROOT}/${id}`,
  }, { loadingType: loadingTypes.syncEmployeeButton });

  /**
   * Get multiple employees by project id or office ids
   * @param {GetEmployeesParams} [params={}] project id or offices ids
   * @returns {Promise<AxiosResponse<Employee[]>>}
   */
  getEmployees = ({ projectId, officesIds } = {}) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.EMPLOYEES_API_ROOT}/`,
    params: {
      project_id: projectId,
      offices_ids: officesIds,
    },
  }, { loadingType: loadingTypes.none });

  /**
   * Get multiple employees for timesheet page filters
   * @param {string} projectId
   * @param {string} officeId
   * @returns {Promise<AxiosResponse<Employee[]>>}
   */
  getEmployeesForTimeUnits = (projectId, officeId) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.EMPLOYEES_API_ROOT}/time-units`,
    params: {
      project_id: projectId,
      office_id: officeId,
    },
  });

  /**
   * Update employee information
   * @param {string} id employee id
   * @param {Employee} employee payload with new employee data
   * @returns {Promise<AxiosResponse<null>>}
   */
  updateEmployee = (id, employee) => this.apiClient.sendRequest({
    method: 'PATCH',
    url: `${this.EMPLOYEES_API_ROOT}/${id}`,
    data: employee,
  });

  /**
   * Get employee schedule
   * @param {GetScheduleParams} params
   * @returns {Promise<AxiosResponse<ScheduleEntry[]>>}
   */
  getSchedule = (params) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.EMPLOYEES_API_ROOT}/schedule`,
    params,
  }, { loadingType: loadingTypes.none });

  /* CURRENTLY UNUSED */ getScheduleByAssignment = (params) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.EMPLOYEES_API_ROOT}/schedule-by-assignment`,
    params,
  }, { loadingType: loadingTypes.reportSchedule });

  /**
   * Get employee assignments
   * @param {GetEmployeeAssignmentsParams} params
   * @param {AdditionalRequestOptions} options
   * @returns {Promise<AxiosResponse<EmployeeAssignments[]>>}
   */
  getEmployeeAssignments = (params, options) => this.apiClient.sendRequest({
    method: 'GET',
    url: `${this.EMPLOYEES_API_ROOT}/${params.employeeId}/projects/`,
  }, options);
}
